@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.addItemButton {
    margin-left: 100%;
    transform: translateX(-100%);

    @include sm {
      margin-bottom: 20px;
    }
  
    .flexRowCenter {
        display: flex;
        align-items: center;
        gap: 5px;
      }
  
    .borderlessBtnText {
      color: $primary500;
      font-weight: 700;
    }
  }