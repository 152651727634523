@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.signUpPage {
  .mainContainer {
    @include mainCenterContainer2;
    margin-top: 0;
    flex-grow: 1;
  }

  .stepIntro {
    width: 100%;
    max-width: 580px;
    align-self: center;
    padding: 0 20px;
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .flexSpaceBetween {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }

  .flexCol {
    display: flex;
    flex-direction: column;
  }

  .avatarContainer {
    display: flex;
    justify-content: center;
    margin: 15px;
  }

  .avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    border: 1.5px solid $white;

    @include sm {
      width: 90px;
      height: 90px;
    }
  }
  .avatar2 {
    z-index: -1;
    margin-left: -20px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    color: $black700;
  }

  .errorMessage {
    padding-top: 1px;
    font-size: 12px;
    height: 20px;
    color: $red500;
    width: 100%;
    margin-top: -30px;

    max-width: 350px;
    @include sm {
      max-width: 335px;
    }
  }

  .marginBottom {
    margin-bottom: 25px;
  }

  .marginBottom20 {
    margin-bottom: 20px;
  }

  .marginBottom40 {
    margin-bottom: 40px;
  }

  .marginTop10 {
    margin-top: 10px;
  }

  .ruleTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    color: $black500;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: $gray700;
  }

  .note {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: $gray700;
    margin-bottom: 20px;
  }

  .bottomContainer {
    align-items: center;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .button {
    margin: 10px 0 20px;
    width: 100%;
    max-width: 335px;
  }

  .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .more {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $primary500;
    text-decoration: underline;
    text-align: end;
    margin-top: 5px;
    cursor: pointer;
  }

  .stepDetail {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    flex-grow: 1;

    .inputsContainer {
      display: flex;
      column-gap: 20px;
      justify-content: center;

      .inputsColumn {
        flex-basis: 350px;

        @include sm {
          flex-basis: 335px;
        }
      }
    }

    .input {
      width: 100%;
      max-width: 350px;

      @include sm {
        max-width: 335px;
      }
    }

    .bottomContainer {
      margin-top: 30px;
    }
  }

  .phoneArea {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
    max-width: 350px;
    @include sm {
      max-width: 335px;
    }
    .countryCode {
      min-width: 125px;
      margin-right: 0px;
    }

    .phoneInput {
      flex-grow: 1;
      margin-left: 8px !important;
    }
  }

  .link {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    text-decoration-line: underline;
    color: $gray700;
  }
}

.red {
  color: $red500;
}

.hint {
  margin-top: -30px;
}