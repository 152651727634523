@import '../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 30px;

  .item {
    display: flex;
    gap: 12px;
    font-size: 14px;
    line-height: 130%;
    color: $black500;
  }

  .title {
    font-weight: 500;
  }

  .bold {
    font-weight: 600;
  }

  .content {
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: $gray500;
  }

  .picContainer {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: $primary50;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .avatar {
      width: 100%;
      height: 100%;
      background-size: cover;
      border-radius: 100%;
      border: 1px solid #D0D0D0;
      background-position: center;
      background-repeat: no-repeat;
      background-color: #fff;
    }
  }

  .eventLearnMore {
    background: $primary500;
    border-radius: 40px;
    padding: 4px 15px;
    width: fit-content;
    color: $white;
    font-weight: 700;
    font-size: 12px;
    margin: 6px 0;
    cursor: pointer;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}

.wrapper {
  max-height: calc(80vh - 120px);
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.emptyMessage {
  width: 90%;
  margin: 0 auto;
  color: $black700;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spin {
  text-align: center;
}

