@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.menuSelectContainer {
    width: 185px;
    border-radius: 20px;
    background-color: $primary50;

    @include md {
        width: 162px;
    }
}

.flexRow {
    display: flex;
    align-items: center;
}

.rotate {
    transform: rotate(180deg) translateY(5px);
}

.currentStep {
    pointer-events: none;
    padding: 7px;
    gap: 10px;

    div {
        font-weight: 500 !important;
    }
}

.itemInSelect {
    gap: 12px;
}

.editingMark {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $secondary;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.selectItem {
    position: relative;
}