@import '../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.customDrawer {
  border-radius: 24px 24px 0 0;
  max-height: 90vh;
  overflow: auto;

  &:after {
    content: '';
    background-color: $white;
    border-radius: 20px;
    width: 60px;
    height: 8px;
    display: block;
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }
}