@import '../../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  .title {
    font-size: 20px;
    text-align: center;
    word-wrap: break-word;
    line-height: 1.3;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.5;
    max-width: 400px;
    text-align: center;
    color: $gray700;
  }

  .marginBottom {
    margin-bottom: 15px;
  }

  .illustration {
    width: 150px;
  }

  .button {
    width: 180px;
    margin-top: 20px;
  }

  .name {
    display: inline-block;
    margin-left: 3px;
    color: $primary500;
  }
}

.primary {
  color: $primary500;
}