@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.checkoutResultPage {
  @include page;
  position: relative;

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;
    padding: 0 20px;
    padding-bottom: 40px;
    gap: 20px;

    @include tn {
      align-items: flex-start;
    }

    margin-bottom: 20px;
  }

}

.title {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: $black300;
  align-self: flex-start;
}


.row {
  display: flex;
  align-items: center;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    align-items: center;
  }
}

.button {
  width: 335px;
  align-self: center;
  margin: 0 auto;

  @include tn {
    width: 180px;
  }
}

.defaultBtn {
  color: $gray700;
}

.icon {
  align-self: center;
  margin: 20px 0;
}

.description {
  //styleName: Noto Sans TC/16px/Regular;
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: $black900;
}

.orderCard {
  max-width: 500px;
}