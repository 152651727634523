@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


.container {

  .title {
    font-weight: 600;
    font-size: 24px;
    color: $black500;
    text-align: center;
    margin-bottom: 16px;
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: $gray700;
    text-align: center;
  }

  .illustration {
    width: 45px;
    height: 45px;
    margin: 5px auto;
    display: block;
  }

  .button {
    font-size: 14px;
    margin: 20px auto 0;
    padding: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .selectBox {
    position: relative;
    margin-top: 25px;

    .selectInfo {
        position: absolute;
        top: -10px;
        left: 25px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 8px;
    
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 160%;
      }
    
  }
}