@import '../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/animation.scss';


.bubble-option {
  position: absolute;
  font-weight: 700;
  font-size: 16px;
  color: $white;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 120%;
  text-align: center;
  cursor: pointer;
  transition: .8s;
  // animation: enlarge .7s;
  user-select: none;
  box-shadow: 0px 0px 20px 1px rgba(182, 197, 205, 0.3);

}

$sizeLG: 168px;
$sizeMD: 132px;
$sizeSM: 108px;
$sizeXS: 90px;

$sizeLG-xs: 140px;
$sizeMD-xs: 110px;
$sizeSM-xs: 90px;

$fontSizeLG: 20px;
$fontSizeMD: 18px;
$fontSizeSM: 16px;
$fontSizeLG-xs: 20px;
$fontSizeMD-xs: 18px;
$fontSizeSM-xs: 16px;


$sdgColors: (
    "sdg1": $black700,
    "sdg2": $secondary,
    "sdg3": $primary500,
    "sdg1HP": $black700,
    "sdg2HP": $secondary,
    "sdg3HP": $primary500,
);

@each $type, $color in $sdgColors {
  .bubble-option.#{$type} {
    background-color: $color;
  }
}

.bubble-option.sdgInactive {
  background-color: $white;
}

@each $type, $color in $sdgColors {
  .bubble-option.sdgInactive.#{$type} {
    color: $color;
  }
}

$sdgAttr: (
  "sdg1-1": (
    top: 75%,
    left: 43.3%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 81.4%,
    leftXS: 7.2%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 90.5%,
    leftXS2: 45.2%
  ),
  "sdg1-2": (
    top: 26%,
    left: 9%,
    width: $sizeLG,
    height: $sizeLG,
    widthXS: $sizeLG-xs,
    heightXS: $sizeLG-xs,
    topXS: 13.2%,
    leftXS: 24%,
    font-size: $fontSizeLG,
    font-sizeXS: $fontSizeLG-xs,
    topXS2: 15.2%,
    leftXS2: 16%
  ),
  "sdg1-3": (
    top: 70%,
    left: 29.7%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 37.4%,
    leftXS: 73.8%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 71.7%,
    leftXS2: 73.5%,
  ),
  "sdg1-4": (
    top: 0,
    left: 62.45%,
    width: $sizeMD,
    height: $sizeMD,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    topXS: 45.8%,
    leftXS: 46.2%,
    font-size: $fontSizeMD,
    font-sizeXS: $fontSizeMD-xs,
    topXS2: 43.8%,
    leftXS2: 31.2%
  ),
  "sdg2-1": (
    top: 30%,
    left: 88.3%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 87.5%,
    leftXS: 57.2%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 82.4%,
    leftXS2: 1.2%
  ),
  "sdg2-2": (
    top: 3%,
    left: 26.5%,
    width: $sizeLG,
    height: $sizeLG,
    widthXS: $sizeLG-xs,
    heightXS: $sizeLG-xs,
    topXS: 1.5%,
    leftXS: 58.4%,
    font-size: $fontSizeLG,
    font-sizeXS: $fontSizeLG-xs,
    topXS2: 1.5%,
    leftXS2: 60.4%
  ),
  "sdg2-3": (
    top: 31.2%,
    left: 59.7%,
    width: $sizeMD,
    height: $sizeMD,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    topXS: 61.2%,
    leftXS: 41.2%,
    font-size: $fontSizeMD,
    font-sizeXS: $fontSizeMD-xs,
    topXS2: 61.2%,
    leftXS2: 47.2%
  ),
  "sdg2-4": (
    top: 59.8%,
    left: 0,
    width: $sizeMD,
    height: $sizeMD,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    topXS: 29%,
    leftXS: 1.4%,
    font-size: $fontSizeMD,
    font-sizeXS: $fontSizeMD-xs,
    topXS2: 35%,
    leftXS2: -2.8%,
  ),
  "sdg2-5": (
    top: 41%,
    left: 73.7%,
    width: $sizeMD,
    height: $sizeMD,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    topXS: 71.7%,
    leftXS: 66.5%,
    font-size: $fontSizeMD,
    font-sizeXS: $fontSizeMD-xs,
    topXS2: 37.4%,
    leftXS2: 74.8%
  ),
  "sdg3-1": (
    top: 67.2%,
    left: 16.2%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 32.55%,
    leftXS: 45.9%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 29.55%,
    leftXS2: 51.9%
  ),
  "sdg3-2": (
    top: 7.6%,
    left: 0,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 7%,
    leftXS: 3%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 5%,
    leftXS2: 1%
  ),
  "sdg3-3": (
    top: 1%,
    left: 12.4%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 0,
    leftXS: 31%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 0%,
    leftXS2: 31%
  ),
  "sdg3-4": (
    top: 44.7%,
    left: 27.2%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 22%,
    leftXS: 64.8%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 21%,
    leftXS2: 79%,
  ),
  "sdg3-5": (
    top: 33.9%,
    left: 40%,
    width: $sizeLG,
    height: $sizeLG,
    widthXS: $sizeLG-xs,
    heightXS: $sizeLG-xs,
    topXS: 60%,
    leftXS: 0,
    font-size: $fontSizeLG,
    font-sizeXS: $fontSizeLG-xs,
    topXS2: 57%,
    leftXS2: -2%,
  ),
  "sdg3-6": (
    top: 15.5%,
    left: 76.7%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 56.4%,
    leftXS: 73.8%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 54.4%,
    leftXS2: 80%
  ),
  "sdg3-7": (
    top: 2%,
    left: 46%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 44%,
    leftXS: 11%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 88%,
    leftXS2: 77%,
  ),
  "sdg3-8": (
    top: 62.5%,
    left: 55.9%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 76.9%,
    leftXS: 36.3%,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs,
    topXS2: 76.9%,
    leftXS2: 30.3%
  ),
  // for HP
  "sdgHP1-1": (
    top: 7%,
    left: 39%,
    topXS: 0%,
    leftXS: 28%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs  
  ),
  "sdgHP1-2": (
    top: 38%,
    left: 50%,
    topXS: 64%,
    leftXS: 72%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs  
  ),
  "sdgHP1-3": (
    top: 55%,
    left: 64%,

    topXS: 70%,
    leftXS: 0%,
    width: $sizeLG,
    height: $sizeLG,
    widthXS: $sizeLG-xs,
    heightXS: $sizeLG-xs,
    font-size: $fontSizeLG,
    font-sizeXS: $fontSizeLG-xs
  ),
  "sdgHP2-1": (
    top: 67%,
    left: 42%,

    topXS: 80%,
    leftXS: 52%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs  
  ),
  "sdgHP2-2": (
    top: 1%,
    left: 59%,

    topXS: 2%,
    leftXS: 57%,
    width: $sizeLG,
    height: $sizeLG,
    widthXS: $sizeLG-xs,
    heightXS: $sizeLG-xs,
    font-size: $fontSizeLG,
    font-sizeXS: $fontSizeLG-xs
  ),
  "sdgHP2-3": (
    top: 25%,
    left: 0,

    topXS: 54%,
    leftXS: 38%,
    width: $sizeMD,
    height: $sizeMD,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    font-size: $fontSizeMD,
    font-sizeXS: $fontSizeMD-xs  
  ),
  "sdgHP3-2": (
    top: 29%,
    left: 22%,

    topXS: 23%,
    leftXS: 20%,
    width: $sizeLG,
    height: $sizeLG,
    widthXS: $sizeLG-xs,
    heightXS: $sizeLG-xs,
    font-size: $fontSizeLG,
    font-sizeXS: $fontSizeLG-xs
  ),
  "sdgHP3-3": (
    top: 68%,
    left: 10%,

    topXS: 36%,
    leftXS: 65%,
    width: $sizeMD,
    height: $sizeMD,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    font-size: $fontSizeMD,
    font-sizeXS: $fontSizeMD-xs  
  ),
  "sdgHP3-4": (
    top: 33%,
    left: 83%,

    topXS: 46%,
    leftXS: 0%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    font-size: $fontSizeSM,
    font-sizeXS: $fontSizeSM-xs  
  ),
  "sdgHP3-6": (
    top: 0,
    left: 18%,

    topXS: 9%,
    leftXS: 2%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    font-size: $fontSizeSM,  
    font-sizeXS: $fontSizeSM-xs
  )
);

@each $name, $attr in $sdgAttr {
  .#{$name} {
    top: map-get($attr, top);
    left: map-get($attr, left);
    width: map-get($attr, width);
    height: map-get($attr, height);
    font-size: map-get($attr, font-size);

    @include xs {
      top: map-get($attr, topXS);
      left: map-get($attr, leftXS);
      width: map-get($attr, widthXS);
      height: map-get($attr, heightXS);
      font-size: map-get($attr, font-sizeXS);
    }

    .subtitle {
      font-size: map-get($attr, font-size) * 0.7;
    }
  }
}

.sdgsBubblesXSContainer {
  @each $name, $attr in $sdgAttr {
    .#{$name} {
      top: map-get($attr, topXS2);
      left: map-get($attr, leftXS2);
      width: map-get($attr, widthXS);
      height: map-get($attr, heightXS);
      font-size: map-get($attr, font-sizeXS);
    }
  }
}

// ////////////////////////////////////////

$cityColors: (
  "city1": $primary500, // 北
  "city2": $primary50, // 中
  "city3": $primary400, // 南
  "city4": $primary700, // 東
  "city5": $black700, // 離島
);

$cityFontColor: (
  "city1": $primary500, // 北
  "city2": $primary700, // 中
  "city3": $primary400, // 南
  "city4": $primary700, // 東
  "city5": $black700 // 離島
);

@each $type, $color in $cityColors {
  .bubble-option.#{$type} {
    background-color: $color;

    @if $type == 'city2' {
      color: $primary700;
    }
  }
}

$areaColors: (
  "area1-1": $primary500, // 北
  "area1-2": $primary50, // 中
  "area1-3": $primary400, // 南
  "area1-4": $primary700, // 東
  "area1-5": $black700, // 離島
  "area2-1": $black900, // 通用
  "areaHP1-1": $primary500, // 北
  "areaHP1-2": $primary50, // 中
  "areaHP1-3": $primary400, // 南
  "areaHP1-4": $primary700, // 東
  "areaHP1-5": $black700, // 離島
  "areaHP2-1": $black900, // 通用
);

.bubble-option.cityInactive {
  background-color: $white;
}

@each $type, $color in $areaColors {
  .bubble-option.#{$type} {
    position: absolute;
    background-color: $color;

    @if $type == 'area1-2' {
      color: $primary700;
    }
  }
}

@each $type, $color in $cityFontColor {
  .bubble-option.cityInactive.#{$type} {
    color: $color;
  }
}

$areaAttr: (
  "area1-1": (
    width: $sizeLG,
    height: $sizeLG,
    top: 4.7%,
    left: 32.2%,
    widthXS: $sizeLG-xs,
    heightXS: $sizeLG-xs,
    leftXS: 28.1%,
    topXS: 16.8%,
    font-size: $fontSizeLG,  
    font-sizeXS: $fontSizeLG-xs
  ),
  "area1-2": (
    width: $sizeMD,
    height: $sizeMD,
    top: 34%,
    left: 13.7%,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    topXS: 43.1%,
    leftXS: 7.4%,
    font-size: $fontSizeMD,  
    font-sizeXS: $fontSizeMD-xs
  ),
  "area1-3": (
    width: $sizeMD,
    height: $sizeMD,
    top: 54.25%,
    left: 34.4%,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    topXS: 60%,
    leftXS: 32.1%,
    font-size: $fontSizeMD,  
    font-sizeXS: $fontSizeMD-xs
  ),
  "area1-4": (
    width: $sizeMD,
    height: $sizeMD,
    top: 38.5%,
    left: 55.2%,
    widthXS: $sizeMD-xs,
    heightXS: $sizeMD-xs,
    topXS: 44.6%,
    leftXS: 59.3%,
    font-size: $fontSizeMD,  
    font-sizeXS: $fontSizeMD-xs
  ),
  "area1-5": (
    width: $sizeSM,
    height: $sizeSM,
    top: 9.25%,
    left: 61.3%,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 20.8%,
    leftXS: 72.2%,
    font-size: $fontSizeSM,  
    font-sizeXS: $fontSizeSM-xs
  ),
);

@each $name, $attr in $areaAttr {
  .bubble-option.#{$name} {
    top: map-get($attr, top);
    left: map-get($attr, left);
    width: map-get($attr, width);
    height: map-get($attr, height);
    font-size: map-get($attr, font-size);
    @include xs {
      top: map-get($attr, topXS);
      left: map-get($attr, leftXS);
      width: map-get($attr, widthXS);
      height: map-get($attr, heightXS);
      font-size: map-get($attr, font-sizeXS);
    }
  }
}

.bubble-option.sdgActive {
  animation: breath 3s ease infinite;
}

@keyframes breath {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}




$city1Attr: (
  "city1-1-1": ( // Taipei
    top: 7%,
    left: 32%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 2%,
    leftXS: 63.56%
  ),
  "city1-1-2": ( // New Taipei
    top: 36%,
    left: 22.6%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 18.2%,
    leftXS: 46.3%
  ),
  "city1-1-3": (
    top: 49.8%,
    left: 2%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 30.2%,
    leftXS: 3%
  ),
  "city1-1-4": (
    top: 68.2%,
    left: 18%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 34.9%,
    leftXS: 31%
  ),
  "city1-1-5": (
    top: 58.5%,
    left: 36.6%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 52.1%,
    leftXS: 10.8%
  ),
  "city1-1-6": (
    top: 30.5%,
    left: 43.3%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 41%,
    leftXS: 58.1%
  ),
  "city1-1-7": (
    top: 37.5%,
    left: 62.7%,
    width: $sizeXS,
    height: $sizeXS,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 56.6%,
    leftXS: 38.3%
  ),
);

.active1-1 {
  .area1-1 {
    top: 2%;
    left: 0;
    @include xs {
      top: 0;
      left: 10%;
      width: $sizeLG-xs;
      height: $sizeLG-xs;
    }
  }
  .area1-2 {
    top: 0;
    left: 54.5%;
    @include xs {
      top: 72.9%;
      left: 10%;
      width: $sizeMD-xs;
      height: $sizeMD-xs;
    }
  }
  .area1-3 {
    top: 62%;
    left: 55.5%;
    @include xs {
      top: 77%;
      left: 43.5%;
      width: $sizeMD-xs;
      height: $sizeMD-xs;
    }
  }
  .area1-4 {
    top: 39%;
    left: 79.2%;
    @include xs {
      top: 60.6%;
      left: 67.8%;
      width: $sizeMD-xs;
      height: $sizeMD-xs;
    }
  }
  .area1-5 {
    top: 10.5%;
    left: 76.8%;
    @include xs {
      top: 23.6%;
      left: 73.7%;
      width: $sizeSM-xs;
      height: $sizeSM-xs;
    }
  }
  .city1 {
    top: 30px;
    left: 55px;
  }
  @each $name, $attr in $city1Attr {
    .city1.#{$name} {
      top: map-get($attr, top);
      left: map-get($attr, left);
      width: map-get($attr, width);
      height: map-get($attr, height);
      @include xs {
        top: map-get($attr, topXS);
        left: map-get($attr, leftXS);
        width: map-get($attr, widthXS);
        height: map-get($attr, heightXS);
      }
    }
  }
}

$city2Attr: (
  "city1-2-1": (
    top: 64%,
    left: 31.7%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 57.6%,
    leftXS: 8.5%
  ),
  "city1-2-2": (
    top: 3%,
    left: 23.2%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 4%,
    leftXS: 38.8%
  ),
  "city1-2-3": (
    top: 50.75%,
    left: 11.7%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 36.3%,
    leftXS: 5%
  ),
  "city1-2-4": (
    top: 45.25%,
    left: 45%,
    width: $sizeXS,
    height: $sizeXS,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 46.5%,
    leftXS: 32.1%
  ),
  "city1-2-5": (
    top: 32.25%,
    left: 27.1%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 25.3%,
    leftXS: 31%
  ),
);

.active1-2 {
  .area1-1 {
    top: 0;
    left: 42.8%;
    width: $sizeLG;
    height: $sizeLG;
    @include xs {
      width: $sizeLG-xs;
      height: $sizeLG-xs;
      top: 28%;
      left: 57.9%;
    }
  }
  .area1-2 {
    top: 15.75%;
    left: 2.6%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 7.5%;
      left: 7%;
    }
  }
  .area1-3 {
    top: 62.3%;
    left: 56%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 72.9%;
      left: 26.3%;
    }
  }
  .area1-4 {
    top: 32.3%;
    left: 71%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 60.4%;
      left: 56%;
    }
  }
  .area1-5 {
    top: 3%;
    left: 71%;
    @include xs {
      width: $sizeSM-xs;
      height: $sizeSM-xs;
      top: 8%;
      left: 64.8%;
    }
  }
  @each $name, $attr in $city2Attr {
    .city2.#{$name} {
      top: map-get($attr, top);
      left: map-get($attr, left);
      width: map-get($attr, width);
      height: map-get($attr, height);
      @include xs {
        top: map-get($attr, topXS);
        left: map-get($attr, leftXS);
        width: map-get($attr, widthXS);
        height: map-get($attr, heightXS);
      }
    }
  }
}

$city3Attr: (
  "city1-3-1": (
    top: 33.75%,
    left: 44%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 25.3%,
    leftXS: 31.1%
  ),
  "city1-3-2": (
    top: 55.3%,
    left: 58.4%,
    width: $sizeXS,
    height: $sizeXS,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 36.3%,
    leftXS: 5%
  ),
  "city1-3-3": (
    top: 66.75%,
    left: 40.5%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 4%,
    leftXS: 38.8%
  ),
  "city1-3-4": (
    top: 66.8%,
    left: 71.7%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 46.5%,
    leftXS: 32.1%
  ),
  "city1-3-5": (
    top: 42.75%,
    left: 83%,
    width: $sizeSM,
    height: $sizeSM,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 57.6%,
    leftXS: 8.5%
  )
);

.active1-3 {
  .area1-1 {
    top: 0;
    left: 20.75%;
    width: $sizeLG;
    height: $sizeLG;
    @include xs {
      width: $sizeLG-xs;
      height: $sizeLG-xs;
      top: 28%;
      left: 57.9%;
    }
  }
  .area1-2 {
    top: 23.5%;
    left: 0;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 72.9%;
      left: 26.3%;
    }
  }
  .area1-3 {
    top: 44.75%;
    left: 20.7%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 7.4%;
      left: 7%;
    }
  }
  .area1-4 {
    top: 21%;
    left: 64.15%;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 60.4%;
      left: 56.1%;
    }
  }
  .area1-5 {
    top: 3%;
    left: 50%;
    @include xs {
      width: $sizeSM-xs;
      height: $sizeSM-xs;
      top: 7.4%;
      left: 64.9%;
    }
  }
  
  @each $name, $attr in $city3Attr {
    .city3.#{$name} {
      top: map-get($attr, top);
      left: map-get($attr, left);
      width: map-get($attr, width);
      height: map-get($attr, height);
      @include xs {
        top: map-get($attr, topXS);
        left: map-get($attr, leftXS);
        width: map-get($attr, widthXS);
        height: map-get($attr, heightXS);
      }
    }
  }
}

$city4Attr: (
  "city1-4-1": (
    top: 60%,
    left: 66.6%,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 63.4%,
    leftXS: 69.6%
  ),
  "city1-4-2": (
    top: 30%,
    left: 72.7%,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 78.9%,
    leftXS: 50%
  ),
);

.active1-4 {
  .area1-1 {
    top: 4.7%;
    left: 22.9%;
    width: $sizeLG;
    height: $sizeLG;
    @include xs {
      width: $sizeLG-xs;
      height: $sizeLG-xs;
      left: 28.45%;
      top: 12.76%;
    }
  }
  .area1-2 {
    top: 34%;
    left: 4.5%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      left: 7.7%;
      top: 39.14%;
    }
  }
  .area1-3 {
    top: 54.25%;
    left: 25.1%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      left: 32.44%;
      top: 55.96%;
    }
  }
  .area1-4 {
    top: 38.5%;
    left: 46%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      left: 58.2%;
      top: 39.14%;
    }
  }
  .area1-5 {
    top: 9.2%;
    left: 52%;
    width: $sizeSM;
    height: $sizeSM;
    @include xs {
      width: $sizeSM-xs;
      height: $sizeSM-xs;
      left: 68.6%;
      top: 20%;
    }
  }
  @each $name, $attr in $city4Attr {
    .city4.#{$name} {
      top: map-get($attr, top);
      left: map-get($attr, left);
      width: map-get($attr, width);
      height: map-get($attr, height);
      @include xs {
        top: map-get($attr, topXS);
        left: map-get($attr, leftXS);
        width: map-get($attr, widthXS);
        height: map-get($attr, heightXS);
      }
    }
  }
}

$city5Attr: (
  "city1-5-1": (
    top: 12.5%,
    left: 71.6%,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 26.3%,
    leftXS: 70.7%
  ),
  "city1-5-2": (
    top: 42.5%,
    left: 75.3%,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 50.4%,
    leftXS: 70.7%
  ),
  "city1-5-3": (
    top: 67%,
    left: 61.9%,
    widthXS: $sizeSM-xs,
    heightXS: $sizeSM-xs,
    topXS: 70.2%,
    leftXS: 62.7%
  ),
);

.active1-5 {
  .area1-1 {
    top: 4.7%;
    left: 22.9%;
    width: $sizeLG;
    height: $sizeLG;
    @include xs {
      width: $sizeLG-xs;
      height: $sizeLG-xs;
      top: 12%;
      left: 7.4%;
    }
  }
  .area1-2 {
    top: 34%;
    left: 4.4%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 44.6%;
      left: 7.4%;
    }
  }
  .area1-3 {
    top:54.25%;
    left: 25%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 65.3%;
      left: 30.3%;
    }
  }
  .area1-4 {
    top: 38.5%;
    left: 46%;
    width: $sizeMD;
    height: $sizeMD;
    @include xs {
      width: $sizeMD-xs;
      height: $sizeMD-xs;
      top: 37.6%;
      left: 39.6%;
    }
  }
  .area1-5 {
    top: 9.2%;
    left: 52%;
    width: $sizeSM;
    height: $sizeSM;
    @include xs {
      width: $sizeSM-xs;
      height: $sizeSM-xs;
      top: 13.6%;
      left: 48.1%;
    }
  }
  @each $name, $attr in $city5Attr {
    .city5.#{$name} {
      top: map-get($attr, top);
      left: map-get($attr, left);
      width: map-get($attr, width);
      height: map-get($attr, height);
      @include xs {
        top: map-get($attr, topXS);
        left: map-get($attr, leftXS);
        width: map-get($attr, widthXS);
        height: map-get($attr, heightXS);
      }
    }
  }
}