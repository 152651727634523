@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


@mixin flex-column {
    display: flex;
    flex-direction: column;
}

.card {
    @include flex-column;
    align-items: center;
    width: 100%;
    background-color: $white;
    box-shadow: 0px 4px 50px 0px #B6C5CD4D;
    border-radius: 16px;
    padding: 20px 30px;

    .mainContent {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;

        .days {
            display: flex;
            gap: 3px;
        }
    
        .button {
            width: 100%;
            flex-grow: 1;
        }
    }

}

.text {
    //styleName: Noto Sans TC/16px/Bold;
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: $black900;
}

.icon {
    margin-right: 5px;
}

.clickable {
    cursor: pointer;
}