@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.TermsAndPolicyPage {
  align-items: center;
  position: relative;

  .top {
    width: 100%;
    background-color: $primary500;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 200px;
    z-index: 2;
    overflow: hidden;
    transform: translateY(-10px);
    // margin-bottom: 20px;

    .center {
      margin: 0 auto;
      z-index: 6;
    }

    .logo {
      width: 150px;
    }

    .title {
      color: $white;
      font-weight: 700;
      font-size: 22px;
      line-height: 130%;
      text-align: center;
      margin-top: 10px;
    }

    @include sm {
      min-height: 180px;
      // margin-bottom: 30px;
    }
  }

  .container {
    position: relative;
    z-index: 5;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    // box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
    border-radius: 24px 24px 0 0;
    background-color: $white;
    margin-top: -30px;
  }

  .main {
    display: flex;
    flex-direction: column;
    padding: 20px;
    // border: 1px solid black;
    width: 80%;
    // max-width: 1100px;
    max-width: 728px;
    margin-bottom: 80px;
    position: relative;

    @include sm {
      width: 100%;
    }

    .illustration1 {
      position: absolute;
      width: 80px;
      left: 50px;
      top: -90px;
      z-index: 1;

      @include sm {
        width: 55px;
        top: -63px;
        left: 10%;
      }

      @include tn {
        left: 10px;
      }
    }

    .illustration2 {
      position: absolute;
      width: 120px;
      top: -90px;
      right: 50px;
      z-index: 1;

      @include sm {
        width: 82px;
        top: -63px;
        right: 10%;
      }

      @include tn {
        right: 10px;
      }
    }
  }

  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: $black700;
  }

  .updatedAt {
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: $gray500;
    text-align: center;
    margin-bottom: 10px;
  }

  .button {
    position: fixed;
    bottom: 15px;
    z-index: 20;
    min-width: 180px;
  }

  .tab {
    align-self: center;
    margin-left: 0;
  }

  .sectionTitle {
    color: $primary500;
    font-weight: 700;
    line-height: 160%;
    font-size: 18px;
    position: relative;
    text-align: center;
    margin: 40px 0 20px;

    @include sm {
      font-size: 16px;
    }

    &::before {
      content: '';
      display: block;
      width: 25px;
      height: 1px;
      background-color: $primary500;
      margin: 0 auto 10px;
    }
  }

  .text {
    font-weight: 400;
    line-height: 160%;
    color: $black700;
    font-size: 16px;
    // margin-bottom: 20px;

    @include sm {
      font-size: 14px;
    }
  }

  .writer {
    display: block;
    margin: 10px auto 20px;
    width: 200px;
  }
}


  