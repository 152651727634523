@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.resetPasswordPage {
  width: 100%;
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    width: 60%;

    @include lg {
      width: 70%;
    }

    @include md {
      width: 80%;
    }

    @include sm {
      width: 100%;
      max-width: 335px;
    }

    .titleGroup {
      width: 80vw;
      align-self: flex-start;

      @include sm {
        width: 400px;
        align-self: center;
      }

      @include xxs {
        width: 100%;
      }
    }
  
    .title {
      margin-bottom: 15px;
      line-height: 1.7;
    }

    .subtitle {
      margin-bottom: 30px;
      line-height: 1.7;
      font-size: 18px;

      @include sm {
        font-size: 16px;
      }
    }
    
    .text {
      margin-bottom: 10px;
    }

    .bottomText {
      margin: 20px 0;
      text-align: center;
      line-height: 1.5;

      .linkText {
        color: $primary500;
        font-weight: 700;
      }
    }
  }

  .errorMessage {
    padding-top: 2px;
    font-size: 12px;
    height: 22px;
    color: $red500;
    width: 100%;
  }

  .marginBottom {
    margin-bottom: 22px;
  }

  .nextButton {
    margin-top: 40px;
    width: 100%;
    max-width: 335px;
  }

  .onePageContainer {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .decImg {
    width: 280px;
    margin-top: 30px;

    @include sm {
      width: 240px;
      margin-top: 0px;
    }

    @include xxs {
      width: 200px;
    }
  }

  .inputContainer {
    width: 100%;
    max-width: 335px;
  }

  .resetContainer {
    width: 100%;
    max-width: 335px;
    display: flex;
    flex-direction: column;
    min-height: 70vh;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-top: 20px;
    width: 100%;
    max-width: 335px;

    @include sm {
      align-self: center;
      margin-top: 0px;
    }
  }

  .timer {
    display: inline-block;
    width: 30px;
    color: $gray700;
    margin-left: 3px;
  }

}

.purple {
  color: $primary500;
}


  