@keyframes enlarge {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes breath1 {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes breath2 {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.9);
  }
  60% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes breathSM1 {
  0% {
    transform: scale(1.15);
  }
  30% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.15);
  }
}

@keyframes breathSM2 {
  0% {
    transform: scale(1.15);
  }
  60% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.15);
  }
}

@keyframes breathXS1 {
  0% {
    transform: scale(1.32);
  }
  30% {
    transform: scale(1.42);
  }
  100% {
    transform: scale(1.32);
  }
}

@keyframes breathXS2 {
  0% {
    transform: scale(1.32);
  }
  60% {
    transform: scale(1.42);
  }
  100% {
    transform: scale(1.32);
  }
}