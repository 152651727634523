@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.container {

    .title {
      font-weight: 600;
      font-size: 24px;
      color: $black500;
      text-align: center;
      margin-bottom: 16px;
    }
  
    .desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      color: $gray700;
      text-align: center;
    }
  
    .illustration {
      width: 45px;
      height: 45px;
      margin: 5px auto;
      display: block;
    }
  
    .button {
      font-size: 14px;
      margin: 20px auto 0;
      padding: 20px 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  
    .identityContainer {
      display: flex;
      justify-content: space-evenly;
      margin: 30px auto;
      max-width: 400px;
    
    
      .identityCard {
        background-color: $white;
        box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
        border-radius: 16px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 88px;
        width: 92px;
      }
    
      .identityCardSelected {
        border: 1.5px solid $primary500;
        position: relative;
        &:after {
          content: '';
          background-image: url("../../../assets/checkbox.svg");
          width: 25px;
          height: 25px;
          display: block;
          position: absolute;
          top: -10px;
          right: 0;
          z-index: 3;
        }
      }
    
      .identityImg {
        z-index: 2;  
      }
    
      .identityText {
        color: $black700;
        font-size: 16px;
        font-weight: 500;
      }
    }
    
  
  }
  