@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.overviewPage {
  @include page;

  @include sm {
    padding-bottom: 40px;
  }

  .title {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: $black700;
    display: flex;
    gap: 5px;

    @include sm {
      font-size: 20px;
      line-height: 32px;
      flex-direction: column;
      gap: 0px;
    }
  }
}

.wrapper {
  @include mainCenterContainer2;
  max-width: 920px;

  @include md {
    width: 100%;
    padding: 0 20px;
    padding-bottom: 100px;
  }

  @include sm {
    padding: 0 30px;
    padding-bottom: 40px;
  }

  @include xs {
    padding: 0 20px;
    padding-bottom: 60px;
  }
}

.panels {
  margin: 40px 0;
  display: flex;
  gap: 30px;

  @media(max-width: 830px) {
    flex-direction: column;
    margin: 30px 0;
    gap: 50px;
  }

  .panel {
    flex: 1;
    flex-basis: 350px;
    display: flex;
    flex-direction: column;

    @include md {
      flex-basis: 0px;
    }

    .content {
      flex-grow: 1;
      position: relative;
      min-height: 200px;

      @include sm {
        min-height: 20px;
      }
    }
  }
}

.text {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.bold {
  font-weight: 700;
}

.row {
  display: flex;
  gap: 5px;
  align-items: center;
}

.spaceBtw {
  justify-content: space-between;
}

.seeAll {
  color: $primary500;
  text-decoration: underline;
  cursor: pointer;
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.emptyMsg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: $gray700;
  width: 100%;
  text-align: center;
}

.carouselWrapper {
  width: 400px;
  // border: 1px solid black;

  width: 350px;
  margin: 0 auto;

  @include md {
    width: 300px;
    height: 230px;
  }

  @media(max-width: 830px) {
    width: 100%;
  }

  @media(max-width: 500px) {
    width: 335px;
    height: 242px;
  }

  @include tn {
    width: 300px;
    height: 220px;
  }

  @media(max-width: 365px) {
    width: 280px;
    height: 200px;
  }
}

.sampleCard {
  width: 350px;
  height: 260px;

  @include md {
    width: 300px;
    height: 230px;
  }

  @media(max-width: 830px) {
    width: 350px;
    height: 260px;
  }

  @include sm {
    width: 335px;
    height: 242px;
  }

  @include tn {
    width: 300px;
    height: 220px;
  }

  @media(max-width: 365px) {
    width: 280px;
    height: 200px;
  }
}

.buttonWrapper {
  margin: 30px 0;
  align-self: flex-end;

  @include md {
    align-self: center;
    margin: 10px 0;
    position: fixed;
    bottom: 10px;
    z-index: 100;
  }
}

.createButton {
  width: 290px;

  @include tn {
    width: 250px;
    height: 50px;
  }
}