@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.menuItem {
    display: flex;
    align-items: center;
    border-radius: 20px;
    gap: 7px;
    cursor: pointer;
    transition: .3s;
    position: relative;

    @include md {
        gap: 5px;
    }

    .label {
        font-family: Noto Sans TC;
        font-size: 18px;
        font-weight: 400;
        line-height: 29px;
        color: $black;

        @include md {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .icon {
        @include md {
            width: 30px;
            height: 30px;
        }
    }

    .editingMark {
        position: absolute;
        right: 0;
        background-color: $secondary;
        border-radius: 100%;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
}

.menuItem.active {
    background-color: $primary50;
}

.menuItem.done {
    background-color: transparent;
}

.menuItem.pending {
    background-color: none;
}


.menuItem.disable {
    background-color: transparent;
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;

    .icon {
        filter: grayscale(1);
        opacity: 0.7;
    }
}