@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@mixin mq {
  @media(max-width: 1024px) {
    @content;
  }
}

.eventApplicationsPage {
  align-items: center;
  background-color: $primary50;

  position: relative;

  .top {
    width: 100vw;
    margin-bottom: 30px;
    background-color: $primary500;
    border-radius: 0 0 24px 24px;
  }

  .demandContainer {
    padding: 0 20px 20px;
    background-color: $white;
    border-radius: 16px 16px 0 0;
    padding-bottom: 20px;
    position: relative;
    z-index: 5;
    max-width: 730px;
    // min-height: 335px;
    margin: 60px auto 0;
  }

  .demandIcon {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .demandIcon.service {
    width: 90px;

    @include sm {
      width: 70px;
    }
  }

  .demandIcon.other {
    background-color: $primary50;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tag {
    margin: 0px auto 10px;
    border-radius: 40px;
    background-color: $primary500;
    width: fit-content;
    padding: 0 10px;
    color: $white;
    height: 28px;
    line-height: 28px;
    font-weight: 700;
    font-size: 12px;

    .mark {
      display: inline-block;
      margin-right: 3px;
    }
  }
  .space {
    height: 40px;
  }

  .mainContainer {
    @include mainCenterContainer3;
    margin-top: 0;
    max-width: 1100px;
    width: 90%;
    min-height: auto;
    margin-bottom: 100px;

    @include sm {
      width: 90%;
    }

    .list {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-self: center;
      gap: 30px;
  
      @media(max-width: 1310px) {
        grid-template-columns: repeat(2, 1fr);
      }
  
      @include md {
        grid-template-columns: 1fr;
      }
  
      @include sm {
        margin-top: 0px;
        grid-template-columns: repeat(2, 1fr);
      }
  
      @media(max-width: 735px) {
        grid-template-columns: 1fr;
      } 
    }

    .noContentMessage {
      text-align: center;
      min-height: calc(100vh - 450px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .oops {
        @include font-subtitle;
        color: $black500;
        margin-bottom: 5px;
      }
  
      .text {
        @include font-text;
        color: $black700;
        line-height: 2;
      }

      .day {
        color: $primary500;
        display: inline-block;
        margin: 0 3px;
      }

      .box {
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        color: $black700;
        text-align: start;
        background-color: $secondary;
        box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
        border-radius: 16px;
        padding: 15px 20px;
        max-width: 335px;
        width: 100%;
        margin: 20px auto 0;
        cursor: pointer;

        .content {
          display: flex;
          align-items: center;
          gap: 15px;
          justify-content: space-between;
        }

        .count {
          display: inline-block;
          margin-right: 3px;
        }

        .eye {
          width: 18px;
        }
      }
    }
  }

  .title {
    @include font-title;
    font-size: 22px;
    color: $black500;
    text-align: center;
  }

  .desc {
    @include font-text;
    font-size: 16px;
    text-align: center;
  }

  .subtitle {
    @include font-normal;
    color: $gray700;
    margin-top: 20px;
  }

  .textNormal {
    @include font-normal;
  }

  .textBold {
    @include font-bold;
  }

  .purple {
    color: $primary500;
  }

  .textCenter {
    text-align: center;
  }

  .illustration {
    width: 45px;
    height: 45px;
    display: block;
    margin: 40px auto 0;
  }

  .slogan {
    margin: 15px 0 25px;
    font-family: 'Poppins';
    font-weight: 600;
    color: $primary500;
    text-align: center;
  }

  .awardContainer {
    border-radius: 24px;
    background-color: $primary50;
    padding: 20px;
  }

  .awards {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    column-gap: 50px;
    row-gap: 10px;
    flex-wrap: wrap;

    .award {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .decLine {
    width: 25px;
    height: 1px;
    background-color: $primary500;
    margin: 40px auto 10px;
    text-align: center;
  }

  .signupButton {
    min-width: 180px;
    position: fixed;
    left: 50%;
    bottom: 15px;
    transform: translateX(-50%);
    z-index: 100;

    .signupBtn {
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }

  .dot {
    display: inline-block;
    margin: 0 5px;
  }

  .marginBottom10 {
    margin-bottom: 10px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    // align-items: center;

    .date {
      font-size: 12px;
      font-weight: 500;
      color: $black900;

      .marginRight {
        display: inline-block;
        margin-right: 3px;
      }
    }

    .status {
      font-size: 12px;
      font-weight: 500;
      color: $gray700;
    }

    .buttonContainer {
      cursor: pointer;
      position: relative;
    }

    .greenDot {
      position: absolute;
      right: 1px;
      bottom: 2px;
      width: 6px;
      height: 6px;
      background-color: $secondary;
      border-radius: 100%;
    }
  }
}

.flexRow {
  display: flex;
  align-items: center;
}

.center {
  margin: 0 auto;
}

.purple {
  color: $primary500 !important;
}

.viewDemandBtn {
  font-weight: 700;
  font-size: 16px;
  color: $white;
  cursor: pointer;
}

.separator {
  font-size: 12px;
  font-weight: 500;
  color: $gray700;
  display: inline-block;
  margin: 0 5px;
}

.number {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: $gray700;
  text-align: center;
}

.check {
  margin-right: 3px;
}

.grayFilter {
  filter: grayscale(1);
}

.numberActive {
  color: $primary500;
  font-weight: 700;
}

.slash {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: $gray700;
  margin: 0 3px;
}

.demandBottom {
  justify-content: space-evenly;
}

.flexbasis60 {
  flex-basis: 60px;
}

.ctaButton {
  position: fixed;
  bottom: 30px;
  width: 217px;
}

.filterBox {
  background-color: $white;
  position: fixed;
  bottom: 30px;
  box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
  border-radius: 30px;
  padding: 10px 20px;
  display: flex;
  gap: 25px;
  z-index: 100;

  .icon {
    filter: invert(1);
    opacity: 0.6;
  }

  .download {
    color: $gray700;
  }

  .filterIconContainer {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .filterCount {
    position: absolute;
    font-size: 12px;
    color: $primary500;
    font-weight: 700;
    bottom: 0px;
    left: 80%;
  }
}

.filterModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  @include sm {
    width: 100%;
  }

  .top {
    margin-bottom: 20px;
    justify-content: center;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: $black500;
    text-align: center;
    margin-bottom: 2px;
    margin-left: 10px;
  }
  .reset {
    color: $primary500;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @include sm {
      font-size: 14px;
    }
  }

  .content {
    gap: 10px;
    margin-bottom: 20px;
  }

  .filterRow {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    color: $gray700;
    min-width: fit-content;
  }

  .optContainer {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .opt {
    border-radius: 40px;
    background-color: $white;
    border: 1px solid $gray600;
    border-radius: 40px;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    min-width: 60px;
    flex-shrink: 0;

    @include sm {
      padding: 12px 16px;
    }
  }

  .opt.selected {
    background-color: $primary500;
    border-color: $primary500;
    color: $white;
  }

  .buttons {
    justify-content: space-between;

    .resetBtn {
      width: 124px;
      height: 60px;
      font-weight: 500;
      font-size: 18px;
      color: $gray700;

      @include sm {
        width: fit-content;
        padding: 0 15px;
      }
    }

    .applyBtn {
      width: 117px;
      height: 60px;

      @include sm {
        width: fit-content;
        padding: 0 20px;
      }
    }
  }
}
