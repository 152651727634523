@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

@mixin h-center-content {
  display: flex;
  justify-content: center;
}

@mixin v-center-content {
  display: flex;
  align-items: center;
}

@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

$border_radius_type_0: 16px 16px 16px 16px;
$border_radius_type_1: 1px 1px 1px 1px;

$font_type_2: 700 14px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_4: 700 12px/1.6 "Noto Sans TC", Helvetica, Arial, serif;
$font_type_5: 500 12px/1.29 "Noto Sans TC", Helvetica, Arial, serif;

.eventCard {
  @include flex-column;
  width: 394px;
  height: 293px;

  @include sm {
    width: 335px;
    height: 242px;
  }

  @include tn {
    width: 300px;
    height: 230px;
  }

  h5 {
    font-size: 14px;
    color: $black700;
    @include sm {
      font-size: 12px;
    }
  }
}
.flexRow {
  display: flex;
  position: relative;
  min-height: 0px;
  flex-grow: 1;
}
.flexCol {
  @include flex-column;
  position: relative;
  flex: 0 1 39px;
  min-height: 0px;
  margin: 10px 0px 15px;
  justify-content: space-between;
  align-items: flex-end;
  text-align: end;

  @include sm {
    flex: 0 1 36px;
  }
}
.time {
  @include flex-column;
  align-items: flex-end;
  color: $black700;
  font-size: 14px;
  @include sm {
    font-size: 12px;
  }

  .countdown {
    @include v-center-content;
    justify-content: center;
    text-align: center;
    width: max-content;
  }

  .number {
    @include v-center-content;
    justify-content: flex-end;
    font-weight: 700;
    text-align: center;
    margin: 5px 0px;
    font-size: 22px;
    @include sm {
      font-size: 20px;
    }
  }

  .day {
    @include v-center-content;
    justify-content: flex-end;
    text-align: right;
  }

  .eventEnd {
    line-height: 1.5;
    margin-top: -3px;
    min-width: 24px;
  }
}

.flexCol2 {
  @include flex-column;
}
.flexCol2__item {
  @include flex-column;
  position: relative;
}
.image6 {
  width: 26px;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  margin-top: 8px;

  @include sm {
    width: 24px;
  }
}
.flexRow__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 16px;
}
.box3 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  box-shadow: 0px 4px 50px 0px rgba(182, 197, 205, 0.298);
  position: relative;
  overflow: hidden;
  flex: 1 1 288px;
}
.content_box {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  min-height: 0px;
}
.img {
  width: 100%;
  height: min-content;
  aspect-ratio: 1.92;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 0px;
  min-height: 0px;
  left: 0px;
  right: 0px;
  min-height: 180px;

  @include sm {
    min-height: 150px;
  }
}
.mask {
  width: 100%;
  height: 100%;
  background: var(--src) center center / cover no-repeat;
  position: absolute;
  top: 0px;
  left: 0px;
  min-width: 288px;
}
.flexCol3 {
  @include flex-column;
  z-index: 10;
  height: 100%;
  justify-content: flex-end;
  padding: 13px 20px;
}
.title {
  font-size: 18px;
  color: $white;
  font-weight: 700;
  line-height: 1.3;
  position: relative;
  min-height: 26px;
  
  @include sm {
    font-size: 16px;
  }
}
.infoList {
  align-items: center;
  margin: 5px 0px 5px;
  text-overflow: ellipsis;
  max-height: 20px;
  overflow: hidden;
}
.cities {
  color: $white;
  @include h-center-content;
  color: $white;
  font-weight: 700;
  line-height: 1.5;
  font-size: 16px;
  display: inline-block;
  text-overflow: ellipsis;
  flex-shrink: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @include sm {
    font-size: 14px;
  }
}
.text4 {
  @include h-center-content;
  font: $font_type_2;
  color: $white;
  text-align: center;
  letter-spacing: 0px;
  position: relative;
  flex: 0 0 auto;
  min-width: 28px;
  min-height: 0px;
}
.info {
  @include h-center-content;
  color: $white;
  font-weight: 700;
  text-align: center;
  flex: 0 0 auto;
  line-height: 1.5;
  font-size: 16px;
  display: inline-block;
  text-overflow: ellipsis;
  flex-shrink: 0;
  @include sm {
    font-size: 14px;
  }
}
.dot {
  width: 20px;
}
.x {
  color: $secondary;
  margin-bottom: 2px;
  display: inline-block;
  width: 20px;
  text-align: center;
}

.check {
  border-radius: $border_radius_type_1;
  width: 24px;
  height: min-content;
  aspect-ratio: 1;
  vertical-align: top;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 15px;
  min-height: 0px;
  right: 15px;
}

.serviceTypesTags {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  color: $white;
  font-size: 14px;
  font-weight: 700;
}

.hashtags {
  flex: 1 1;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  color: $black900;
  font-size: 14px;
  line-height: 15px;
  margin-left: 14.93%;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;

  @include sm  {
    font-size: 12px;
  }

  .ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .hashtag {
    margin-right: 10px;
  }
}
.tag {
  display: flex;
  margin-right: 10px;
  margin-bottom: 5px;
}

.purple {
  color: $primary500;
}

.sampleLabel {
  @include flex-column;
  align-items: flex-end;
  color: $black700;
  font-size: 14px;
  line-height: 1.2;
  @include sm {
    font-size: 12px;
  }
}

.clickable {
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 9.5px 0;
  gap: 10px;

  .cornerButton {
    flex-basis: 86px;
    height: 36px;
    font-size: 14px;
  }

  @include sm {
    .cornerButton {
      flex-basis: 70px;
      height: 30px;
    }
  }
}