@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


@mixin flex-column {
    display: flex;
    flex-direction: column;
}

.resourceCard {
    @include flex-column;
    justify-content: space-between;
    width: 394px;
    // height: 293px;

    @include sm {
        width: 335px;
        // height: 242px;
    }

    @include tn {
        width: 300px;
        // height: 230px;
    }

    h5 {
        font-size: 14px;
        color: $black700;

        @include sm {
            font-size: 12px;
        }
    }
}

.card {
    background-color: $white;
    box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
    border-radius: 16px;
    padding: 15px 20px;
    cursor: pointer;

    .mainContent {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .topArea {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 8px;

        .tag {
            border-radius: 40px;
            background-color: $primary500;
            width: fit-content;
            padding: 0 10px;
            color: $white;
            height: 28px;
            line-height: 28px;
            font-weight: 700;
            font-size: 12px;
            flex-shrink: 0;

            .mark {
                display: inline-block;
                margin-right: 3px;
            }
        }

    }

    .topArea.space {
        margin-bottom: 0px;
    }

    .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
        color: $black700;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .title.space {
        height: 52px;
    }

    .subtitle {
        font-weight: 500;
        font-size: 16px;
        line-height: 160%;
        color: $black700;
    }

    .desc {
        margin: 10px 0px;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: $gray700;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 45px;
    }

    .infoContainer {
        .info {
            display: flex;
            align-items: center;
        }

        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: $gray700;
            margin: 0;
            margin-right: 15px;
            flex-shrink: 0;
        }

        .content {
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: $black700;
        }
    }

    .photos {
        display: flex;
        width: 100%;
        height: 150px;
        border-radius: 16px;
        overflow: hidden;
    }

    .spaceInfo {
        margin: 10px 0px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .info {
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            align-items: center;
            justify-content: center;
        }

        .separator {
            width: 1px;
            height: 12px;
            background-color: #696B74;
        }

        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: $gray700;
            margin: 0;
            margin-right: 15px;
            flex-shrink: 0;
        }

        .content {
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: $black700;
        }

    }

}

.footer {
    display: flex;
    justify-content: space-between;
    // align-items: center;

    .date {
      font-size: 12px;
      font-weight: 500;
      color: $black900;

      .marginRight {
        display: inline-block;
        margin-right: 3px;
      }
    }

    .separator {
      font-size: 12px;
      font-weight: 500;
      color: $gray700;
      display: inline-block;
      margin: 0 5px;
    }

    .status {
      font-size: 12px;
      font-weight: 500;
      color: $gray700;
    }

    .buttonContainer {
      cursor: pointer;
      position: relative;
    }
  }

  .flexRow {
    display: flex;
    align-items: center;
  }
  
  .center {
    margin: 0 auto;
  }
  
  .purple {
    color: $primary500 !important;
  }

.clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}

.clamp2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.greenDot {
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 6px;
    height: 6px;
    background-color: $secondary;
    border-radius: 100%;
}

.tagContainer {
    position: relative;
}
