@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.RegisterPage {
  width: 100%;
  min-height: calc(100vh - map-get($headerH, 'global') - 40px - 74px);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;

  padding: 20px;

  overflow: hidden;

  @include sm {
    min-height: calc(100vh - map-get($headerH, 'sm') - 40px - 74px);
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
    width: 60%;
    flex-grow: 1;

    @include lg {
      width: 70%;
    }

    @include md {
      width: 90%;
    }

    @include sm {
      width: 100%;
      min-height: 450px;
    }

    .titleGroup {
      width: fit-content;
      align-self: flex-start;
    }
  
    .title {
      margin-bottom: 15px;
      line-height: 1.7;
    }

    .subtitle {
      margin-bottom: 30px;
      line-height: 1.7;
      font-size: 18px;

      @include sm {
        font-size: 16px;
      }
    }

    .inputTitle {
      margin-bottom: 15px;
      line-height: 1.7;
      font-size: 18px;
      width: max-content;
      max-width: 340px;

      @include sm {
        font-size: 16px;
        width: fit-content;
      }
    }
    
    .text {
      margin-bottom: 10px;
    }

    .bottomText {
      margin: 20px 0;
      text-align: center;
      line-height: 1.5;
      font-size: 16px;
      @include sm {
        font-size: 14px;
      }

      h5 {
        font-size: 16px;
        @include sm {
          font-size: 14px;
        }
      }
    }
  }

  .step0Container {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .mainContent {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .identityContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    @include sm {
      justify-content: space-evenly;
    }

    .identityCard {
      background-color: $white;
      border: 1.5px solid $white;
      box-shadow: 0px 4px 8px rgba(65, 78, 225, 0.3);
      border-radius: 16px;
      cursor: pointer;
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      padding-top: 8px;
    }

    .identityCardSelected {
      border-color: $primary500;
      position: relative;
      &:after {
        content: '';
        background-image: url("../../assets/checkbox.svg");
        width: 25px;
        height: 25px;
        display: block;
        position: absolute;
        top: -10px;
        right: 0;
        z-index: 3;
      }
    }

    .identityImg {
      height: 240px;

      @include sm {
        height: 140px;
      }
    }

    .identityText {
      color: $black700;
      font-size: 18px;
      font-weight: 700;
      margin: 20px 0;
    }
  }

  .notice {
    margin-top: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    max-width: 370px;
    align-items: center;

    @include sm {
      align-self: center;
      margin-top: 0px;
    }
  }

  .link {
    color: $gray700;
    font-weight: 500;
    text-decoration: underline;
  }

  .errorMessage {
    padding-top: 1px;
    font-size: 12px;
    height: 30px;
    color: $red500;
    width: 100%;
    max-width: 335px;
  }

  .mottoErrorMessage {
    padding-top: 1px;
    font-size: 12px;
    height: 22px;
    color: $red500;
    width: 100%;
    max-width: 500px;
  }

  .modifyEmailErrorMessage {
    padding-top: 1px;
    font-size: 12px;
    height: 22px;
    color: $red500;
    width: 100%;
    margin-left: 5px;
  }

  .marginBottom {
    margin-bottom: 30px;
  }

  .bubbleOptionsContainer {
    display: flex;
    flex-wrap: wrap;
    height: 400px;
    position: relative;
    width: 636px;
    align-self: center;
    @include xs {
      width: 376px;
      height: 470px;
    }
    @media(max-width: 390px) {
      transform: scale(0.9);
      margin-top: -10px;
      margin-bottom: -10px;
    }
    @media(max-width: 340px) {
      transform: scale(0.8);
      margin-top: -20px;
      margin-bottom: -20px;
    }
    @media(max-width: 300px) {
      transform: scale(0.7);
      margin-top: -40px;
      margin-bottom: -40px;
    }
  }

  .sdgBubbleOptionsContainer {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-self: center;
    width: 924px;
    height: 445px;
    margin-bottom: 80px;
    @media(max-width: 1000px) {      
      transform: scale(0.9);
      margin-bottom: 60px;
    }
    @media(max-width: 900px) {      
      transform: scale(0.8);
      margin-bottom: 40px;
    }
    @media(max-width: 800px) {      
      transform: scale(0.7);
      margin-bottom: 20px;
    }
    @media(max-width: 700px) {      
      transform: scale(0.65);
      margin-bottom: 0px;
    }
    @media(max-width: 640px) {      
      transform: scale(0.6);
      margin-bottom: -20px;
    }
    @media(max-width: 580px) {      
      transform: scale(0.55);
      margin-bottom: -20px;
    }
    @include xs {
      transform: scale(1);
      width: 344px;
      height: 725px;
      margin-bottom: 0;
    }
    @media(max-width: 480px) {      
      transform: scale(0.9);
      margin-bottom: -20px;
    }
    @media(max-width: 340px) {      
      transform: scale(0.8);
      margin-top: -20px;
      margin-bottom: -40px;
    }
    @media(max-width: 300px) {      
      transform: scale(0.7);
      margin-top: -80px;
      margin-bottom: -100px;
    }
    
  }


  .tags {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    margin-bottom: 40px;
    min-height: 50px;
    // justify-content: center;

    @include tn {
      padding: 0 10px;
    }
  }

  .sdg {
    font-weight: 700;
    font-size: 16px;
    color: $white;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }

  .phoneArea {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .countryCode {
    min-width: 130px;
    margin-right: 10px;
    

    // @include xs {
    //   // min-width: 100px;
    //   margin-right: 0px;
    // }
  }

  .validatePhoneBottomText {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;

    @include tn {
      flex-direction: column;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .validateContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include sm {
      padding-bottom: 30px;
    }
  }

  .emailToPhoneTitle {
    max-width: 370px;
    line-height: 1.5;

    @include xxs {
      width: 100%;
    }
  }

  .phoneInput {
    width: 60px;
    height: 66px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    outline: none;
    text-align: center;
    margin-right: 15px;
    font-size: 20px;

    &:focus {
      border-color: $primary500;
    }

    @include sm {
      width: 50px;
      height: 56px;
      margin-right: 7px;
      font-size: 18px;
    }

    @include tn {
      margin-right: 2px;
      width: 45px;
    }

  }

  .valid {
    border-color: $primary500;
  }

  .onePageContainer {
    min-height: 77vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mainImg {
      width: 280px;
      flex-grow: 1;

      @include sm {
        width: 240px;
      }
    }
  }

  .nextButton {
    margin-top: 40px;
    align-self: center;
    width: 100%;
    max-width: 335px;
  }

  .row {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 335px;
  }

  .select {
    position: relative;
    width: 100%;
    max-width: 335px;
  }

  .timer {
    display: inline-block;
    width: 30px;
    color: $gray700;
    margin-left: 3px;
  }

  .disable {
    color: $gray500;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    cursor: not-allowed !important;
    pointer-events: none;
  }

  .clickable {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: $primary500;
  }

  .flexCol {
    display: flex;
    flex-direction: column;
  }

  .inputGroup {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    @include sm {
      flex-direction: column;
      margin-bottom: 0px;
      align-items: center;
    }

    @include tn {
      width: 100%;
    }
  }

  .inputGroupAuto {
    width: auto;
  }

  .inputs {
    width: 335px;

    @include xxs {
      width: 100%;
    }
  }

  .inputs1 {
    margin-right: 30px;

    @include sm {
      margin-right: 0px;
    }
  }

  .inputs50 {
    margin-right: 50px;

    @include sm {
      margin-right: 0px;
    }
  }
  
}

.purple {
  color: $primary500;
}

.interestsTitles {
  @include tn {
    padding: 0 10px;
  }
}

.marginRight3 {
  display: inline-block;
  margin-right: 3px;
}

.grow1 {
  flex-grow: 1;
}

.bottomContainer.buttonsRow {
  display: flex;
  flex-direction: row;
  max-width: none;
  justify-content: space-between;
  gap: 20px;

  .nextButton {
    @include tn {
      width: 130px !important;
    }
  }

  @include sm {
    margin-bottom: 40px;
  }
}

.bottomContainer2 {
  display: flex;
  flex-direction: column;
  max-width: none;

  .buttonsRow2 {
    display: flex;
    flex-direction: row;
    max-width: none;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
}

.twoButtons {
  .nextButton {
    width: 180px !important;

    @include xxs {
      width: 130px !important;
    }
  }
}

.bottomContainer2.withBottomText {
  width: 100%;
  .nextButton {
    margin-top: -20px;
  }
}