@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.flexRow {
  display: flex;
  align-items: center;
}

.wrapper {
  margin-bottom: 20px;

  @include sm {
    align-self: flex-start;
  }
}

.centered {
  justify-content: center;
  align-self: center;
}

.alignStart {
  align-self: flex-start;
}

.marginLeft {
  @include sm {
    margin-left: 20px;
  }

  margin-left: 0px;
}

.tabContainer {
  margin: 0px 20px 10px 20px;
  cursor: pointer;

  @include tn {
    margin: 0px 10px 0px;
  }
}

.tab {
  color: $white;
  font-weight: 700;
  opacity: 0.3;
  cursor: pointer;
  transition: .3s;
  font-size: 18px;
  line-height: 1.6;

  @include sm {
    font-size: 14px;
  }
}

.tab.colorMode1 {
  color: $white;
}

.tab.colorMode2 {
  color: $black700;
}

.tabActive {
  opacity: 1;
  position: relative;

  &::after {
    content: '';
    background-color: $secondary;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.badge {
  transition: .3s;
  opacity: 0.3;
  margin-left: 4px;
  padding: 1px 3px;
  width: 21px;
  height: 14px;

  background: #FFFFFF;
  border-radius: 30px;
  color: $black700;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
}

.badge.colorMode1 {
  background-color: #FFFFFF;
  color: $black700;
}

.badge.colorMode2 {
  background-color: $black700;
  color: $white;
}

.tabActive + .badge {
  opacity: 1;
}