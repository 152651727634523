@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

$paddingTop: 30px;
$paddingBottom: 30px;
$bottomBarH: 90px;

$top: map-get($headerH, 'global') + map-get($breadCrumbH, 'global') + $paddingTop;

.previewContainer {
    position: fixed;
    width: 320px;
    height: calc(100vh - $bottomBarH - $paddingTop - $paddingBottom - map-get($breadCrumbH, 'global'));
    border-radius: 16px;
    top: calc($top - map-get($headerH, 'global'));
    right: calc((100vw - 1110px) / 2);

    box-shadow: 0px 4px 20px 0px #B6C5CD4D;

    background-color: $white;
    overflow: hidden;
    transition: .3s;

    iframe {
        pointer-events: none;
    }

    @media(max-width: 1280px) {
        right: 5%;
    }

    @include md {
        right: 10px;
    }

    @include sm {
        display: none;
    }
}

.previewContainer.lower {
    top: $top;
    height: calc(100vh - $bottomBarH - $paddingTop - $paddingBottom - map-get($headerH, 'global') - map-get($breadCrumbH, 'global'));
}

.previewLoadingMask {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba($color: #fff, $alpha: 0.7);
    z-index: 1000;
    width: 100%;
    height: 100%;

    .loadingImg {
        border-radius: 100%;
        box-shadow: 0px 0px 20px gray;
    }
}