@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.profilePage {
  height: 100%;
  overflow: hidden;
  align-items: center;
  position: relative;

  h5 {
    font-size: 18px;
    line-height: 160%;

    @include sm {
      font-size: 14px;
    }
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-bottom: 10px;
    padding: 0px 20px;

    @include lg {
      width: 70%;
    }

    @include md {
      width: 90%;
    }

    @include tn {
      width: 100%;
    }
  }

  .topArea {
    transition: .3s;
    margin-top: 25px;
    justify-content: space-between;
    position: relative;
    align-items: flex-start;

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      gap: 5px;
    }

    .buttons {
      display: flex;
      margin-top: 10px;
      height: fit-content;
      align-items: center;
    }
  }

  .avatar {
    transition: .3s;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;

    @include sm {
      width: 90px;
      height: 90px;
    }
  }

  .motto {
    position: absolute;
    left: 100px;
    top: 60px;
    padding-left: 3px;
    margin-top: 10px;
    align-items: flex-start;
    line-height: 1.4;

    @include sm {
      top: 45px;
      left: 70px;
    }
  }

  .mottoText {
    margin-left: 3px;
    margin-top: 10px;
    font-weight: 500;
    color: $black700;

    @include sm {
      font-size: 14px;
    }
  }

  .authButton {
    font-size: 14px;
    color: $gray700;
    height: 40px;
    margin-left: 10px;

    @include sm {
      font-size: 12px;
      height: 35px;
    }
  }

  .moreButton {
    font-size: 14px;
    color: $gray700;
    width: 100px;
    height: 40px;
    margin-left: 10px;

    @include sm {
      font-size: 12px;
      width: 70px;
      height: 35px;
      margin-right: 5px;
    }

    @include xxs {
      margin: 0px 3px;
    }
  }

  .belongButton {
    @include sm {
      width: 80px;
    }
  }

  .slash {
    margin: 0 4px;
  }

  .nameArea {
    margin-top: 10px;
  }

  .displayName {
    color: $black500;
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;

    @include sm {
      font-size: 22px;
    }
  }

  .subtitle {
    color: $black700;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;

    @include sm {
      font-size: 16px;
    }
  }

  .jobs {
    > div {
      display: inline-block;
    }

    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    color: $black700;

    @include sm {
      font-size: 16px;
    }
  }

  .interestTitle {
    font-weight: 500;
    color: $gray500;
  }

  .blackBold {
    color: $black700;
    font-weight: 700;
  }

  .marginBottom {
    margin-bottom: 10px;
  }

  .iconNotice {
    margin-left: 3px;
    opacity: 0.5;
  }

  .ogProfile h5 {
    font-weight: 500;
    color: $black700;
  }

}

.lists {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
}

.listContainer {
  width: 100%;
  border-radius: 24px 24px 0 0;
  transition: .6s;
  height: 50px;

  .barTitle {
    color: $white;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Poppins';

    @include sm {
      font-size: 16px;
    }
  }

  .bar {
    justify-content: space-between;
    cursor: pointer;
    height: 50px;
    padding: 0 20px;
    position: relative;
    z-index: 1;

    .iconButton {
      display: block;
      border-radius: 50%;
      width: 30px;
      padding: 10px;
      transition: .3s;
      margin-left: 5px;

      &:hover {
        background-color: $primary400;
      }
    }
  }

  .number {
    font-size: 14px;
    color: $white;
  }

  .content {
    height: calc( 100% - 158px);
    color: $white;
  }
}

.listContainer.active {
  height: 500px;

  @include sm {
    height: calc(100vh - 56px - map-get($headerH, 'sm'));
    height: calc((var(--vh, 1vh) * 100) - 56px - map-get($headerH, 'sm')); // fix Safari 100% !== 100vh
    flex-grow: 1;
  }

  .emptyContainer {
    opacity: 1;
  }
}

.listContainer.resourceList.active {
  height: 460px;

  @include sm {
    height: calc(100vh - 56px - map-get($headerH, 'sm') - 50px); // all - top - header - bar
    height: calc((var(--vh, 1vh) * 100) - 56px - map-get($headerH, 'sm') - 50px);
    flex-grow: 1;
  }
}

.innerContent {
  display: flex;
  margin-top: 30px;
  max-height: 100%;

  @include tn {
    margin-top: 0px;
  }

  .card {
    margin-right: 40px;
    flex-shrink: 0;
    cursor: pointer;
  }

  @include sm {
    flex-direction: column;
    align-items: center;
    // padding-bottom: 30px;
    margin-bottom: 30px;

    .card {
      // margin-right: 0px;
      // margin-bottom: 30px;
      margin: 0 auto;
    }
  }
}

.eventList {
  background-color: $primary500;
  height: 100px;
}

.resourceList {
  background-color: $black700;
  border-radius: 24px 24px 0px 0px;
  z-index: 10;
  position: relative;
  margin-top: -50px;
  bottom: 0;

  .content {
    height: calc(100% - 120px);
  }
}

.whenScrollDown.resourceList {
  .content {
    height: calc(100% - 168px);
  }
}

.mainContentWrapper {
  padding: 0 20px;
  height: 100%;

  @include tn {
    padding: 0 10px;
  }
}

// modal //////////////

.moreInfoModal {
  .title {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;

    @include sm {
      margin-top: 16px;
      margin-bottom: 30px;
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .link {
    color: $primary500;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s;
    filter: grayscale(1);
    margin-bottom: 20px;
    width: 150px;
    display: flex;
    gap: 20px;

    &:hover {
      filter: grayscale(0);
    }
  }

  .logo {
    width: 30px;

    @include sm {
      width: 24px;
    }

  }
}

.purple {
  color: $primary500 !important;
}

.serviceCard {
  border: 1px solid $white;
  margin: 10px;
  width: 335px;
  height: 250px;
  flex-shrink: 0;
}

.emptyContainer {
  height: 100%;
  // max-height: 36vh;
  max-height: calc((var(--vh, 1vh) * 36));
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  opacity: 0;
  transition: .2s;

  @include sm {
    // max-height: 60vh;
    max-height: calc((var(--vh, 1vh) * 60));
  }

  .emptyMessage {
    text-align: center;
    color: $white;
  
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 10px;
  
      @include sm {
        font-size: 16px;
      }
    }
  
    .text {
      font-size: 18px;
      line-height: 160%;
  
      @include sm {
        font-size: 14px;
      }
    }
  }
}

.validateOrgModal {
  padding: 10px 20px;

  @include sm {
    padding: 0px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: $black500;
    text-align: center;
    margin-bottom: 20px;

    @include sm {
      font-size: 16px;
    }
  }

  .desc {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    color: $gray700;
    margin-bottom: 30px;

    @include sm {
      font-size: 14px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    .btn {
      width: 123px;

      @include tn {
        width: 100px;
      }
    }
  }
}

.wrap {
  flex-wrap: wrap;
}

.editProfileBtn {
  margin-left: 10px;

  @include xxs {
    margin-left: 0px;
  }
}

.marginTop10 {
  margin-top: 10px;
}

.topArea.smallTop {
  margin-top: 10px;
  height: fit-content;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  .top {
    margin-bottom: 0px;
  }

  .nameArea {
    margin-top: 0px;
  }

  .avatar {
    width: 36px;
    height: 36px;
  }

  .displayName {
    font-size: 16px;
    margin-left: 6px;
  }

  .hide {
    display: none;
  }
}

.orgAuthContent {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: $black900;
  min-width: 68%;
  margin-bottom: 20px;
}

.wordBreak {
  word-break: break-all;
}

.belongsList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 10px 30px;

  @include sm {
    margin: 0 0 20px;
  }

  .belongCard {
    border: 1.5px solid transparent;
    transition: .5s;

    &:hover {
      border: 1.5px solid $primary500;
    }
  }
}

.moreInfoTabContent {
  @include sm {
    margin-top: 35px;
  }
}

.memberCountText {
  font-family: Noto Sans TC;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: $gray500;

  @include xxs {
    margin-right: 2px;
  }
}

.moreInfoEmptyMsg {
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  @include sm {
    min-height: 200px;
  }
}