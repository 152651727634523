@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.LoginPage {
  align-items: center;

  h3 {
    font-size: 18px;
  }

  h5 {
    font-size: 14px;
  }

  .container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 335px;
    
    @include xxs {
      width: 100%;
      padding-left: 22px;
      padding-right: 22px;
    }
  
    .greet {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 15px;
      color: $black700;

      @include sm {
        font-size: 24px;
      }
    }

    .greetSub {
      color: $black500;
      font-size: 16px;
      margin-bottom: 30px;
      line-height: 150%;
    }
  
    .text {
      margin-bottom: 10px;
    }
  
    .highlightText {
      color: $primary500;
      font-weight: 700;
    }

    .bottomText {
      margin: 20px 0;
      text-align: center;

      @include sm {
        font-size: 14px;
      }
    }

    .errorMessage {
      padding-top: 2px;
      height: 30px;
      color: $red500;
      font-size: 12px;
    }
  }

  .space {
    flex-basis: 80px;
    
    @include sm {
      flex-basis: 60px;
    }
  }
}


  