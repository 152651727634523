@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.introPage {
  @include page;

  .wrapper {
    display: flex;
    flex-grow: 1;
    // border: 1px solid black;

    @include sm {
      flex-direction: column;
    }

    .purpleBg {
      width: 45%;
      background-color: $primary500;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      @include lg {
        width: 35%;
      }

      @include sm {
        width: 100%;
        height: 150px;
        margin-bottom: 90px;
      }
    }

    .imgContainer {
      // border: 1px solid red;
      position: absolute;
      right: -8%;

      @include xxl {
        right: -13%;
      }

      @include xl {
        right: -8%;
      }

      @include lg {
        transform: scale(0.8);
        right: -30%;
      }

      @include md {
        transform: scale(0.55);
        right: -52%;
      }

      @include sm {
        transform: scale(0.5);
        position: static;
        top: 10%;
        left: 0;
        margin: 115px auto 0;
      }

      .img {
        width: 600px;
      }
    }

    .mainWrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 800px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 580px;
    padding: 20px;
    width: 100%;

    @include sm {
      margin-bottom: 100px;
    }

    .title {
      //styleName: Noto Sans TC/22px/Bold;
      font-family: Noto Sans TC;
      font-size: 22px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
      color: $primary500;

      @include sm {
        font-size: 18px;
      }
    }

    .grayBlock {
      background-color: $gray550;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px;
      border-radius: 16px;
    }

    .stepName {
      //styleName: Noto Sans TC/16px/Bold;
      font-family: Noto Sans TC;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: $black900;
      flex-shrink: 0;
    }
  }

  .nextButton {
    width: 290px;
    margin-top: 20px;
    margin-bottom: 20px;
    align-self: flex-end;

    @include sm {
      align-self: center;
      position: fixed;
      margin-bottom: 0px;
      bottom: 10px;
    }
  }
}

.text {
  //styleName: Noto Sans TC/16px/Regular;
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: $black;
}

.textSm {
  //styleName: Noto Sans TC/16px/Regular;
  font-family: Noto Sans TC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: $black;
}

.time {
  //styleName: Noto Sans TC/18px/Bold;
  font-family: Noto Sans TC;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: $black;
}

.row {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.stepRow {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.marginBottom30 {
  margin-bottom: 30px;

  @include sm {
    margin-bottom: 10px;
  }
}