.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(78, 72, 60);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.img {
  background-image: url(../../assets/404.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  aspect-ratio: 4600 / 2400;
  cursor: pointer;
}

.mobile {
  width: 80%;
}
