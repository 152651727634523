@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.eventJoinedPage {
  align-items: center;

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;
    max-width: 920px;

    @include tn {
      align-items: flex-start;
    }

    margin-bottom: 30px;
  }

  .topArea {
    @include managePageTopArea($primary500);
  }

  @include stickyFilterArea($primary500, true) {
    .filterArea {
      .container {
        width: 90%;
        max-width: 920px;
      }
    }
  };

  .list {
    @include gridList(50px, 0px) {
      margin-top: 45px;
      margin-bottom: 40px;

      @include lg {
        column-gap: 50px;
      }

      @include sm {
        margin-top: 30px;
        column-gap: 30px;
      }

      @include tn {
        row-gap: 40px;
        column-gap: 10px;
      }

      @media(min-width: 1024px) {
        width: 100%;
        grid-template-columns: 1fr;
      }
    }
  }
}

.green {
  color: $secondary;
}

.filterModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  @include sm {
    width: 100%;
  }

  .top {
    margin-bottom: 20px;
    justify-content: center;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: $black500;
    text-align: center;
    margin-bottom: 2px;
    margin-left: 10px;
  }
  .reset {
    color: $primary500;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @include sm {
      font-size: 14px;
    }
  }

  .demandFilter {
    gap: 10px;
    margin-bottom: 20px;

    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .select {
    margin-bottom: 20px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    color: $gray700;
    width: 70px;
  }

  .resourceTypes {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .resourceType {
    border-radius: 40px;
    background-color: $white;
    border: 1px solid $gray600;
    border-radius: 40px;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  .resourceType.selected {
    background-color: $primary500;
    border-color: $primary500;
    color: $white;
  }

  .buttons {
    justify-content: space-between;
    font-size: 16px;
    margin-top: 20px;

    .resetBtn {
      width: 124px;
      height: 60px;
      font-weight: 500;
      color: $gray700;

      @include sm {
        width: fit-content;
        padding: 0 15px;
      }
    }

    .applyBtn {
      width: 117px;
      height: 60px;

      @include sm {
        width: fit-content;
        padding: 0 20px;
      }
    }
  }
}

.flexRow {
  display: flex;
  align-items: center;
}

.center {
  margin: 0 auto;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 79.47%;
  position: relative;
  min-height: 0px;
  margin: 10.72px 5.6% 0px 14.93%;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  color: $black900;
  font-size: 14px;
  @include sm  {
    font-size: 12px;
  }

  .moreButton {
    border: 1px solid $primary500;
    border-radius: 30px;
    padding: 0px 8px;
    width: 40px;
    height: 24px;
    color: $primary500;
    letter-spacing: 2px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .marginRight3 {
    display: inline-block;
    margin-right: 3px;
  }
}

.emptyContainer {
  height: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;

  @include sm {
    max-height: 60vh;
  }

  .emptyMessage {
    text-align: center;
  
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 10px;
  
      @include sm {
        font-size: 16px;
      }
    }
  
    .text {
      font-size: 18px;
      line-height: 160%;
  
      @include sm {
        font-size: 14px;
      }
    }
  }
}

.viewEventsBox {
  background: #0C0E17;
  box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
  border-radius: 16px;
  padding: 15px 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  margin-top: 30px;
  cursor: pointer;

  .eye {
    width: 18px;
  }
}

.eventTabs {
  @include listTabs;
  margin-bottom: 0px;
}
