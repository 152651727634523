@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.listPage {
  @include page;

  .title {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: $black700;

    @include sm {
      font-size: 20px;
      line-height: 32px;
    }
  }
}

.wrapper {
  @include mainCenterContainer2;
  max-width: 920px;
  flex-grow: 1;
  margin-bottom: 30px;

  @include md {
    width: 100%;
    padding: 0 20px;
  }

  @include sm {
    padding: 0 30px;
    padding-bottom: 40px;
  }

  @include xs {
    padding: 0 20px;
  }


  .topBar {
    display: flex;
    justify-content: space-between;
    // border: 1px solid black;
    margin-bottom: 40px;

    @include sm {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 30px;
      align-items: center;
    }
  }
}

.card {
  max-width: 256px;

  @include xs {
    max-width: 100%;
  }
}

.list {
  margin: 0 auto;
  width: 100%;
  padding: 25px 0px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  row-gap: 25px;
  align-items: center;
  justify-items: center;

  @include sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include xs {
    grid-template-columns: 1fr;
  }

  @include tn {
    padding: 25px 0px;
  }
}

.eventList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-self: center;
  min-height: 50vh;

  .sampleCard {
    margin: 25px 15px;
    cursor: pointer;
  }

  @media(max-width: 1310px) {
    grid-template-columns: repeat(2, 1fr);

    .sampleCard {
      margin: 25px 25px;
    }
  }

  @include md {
    grid-template-columns: 1fr;
  }

  @include sm {
    margin-top: 0px;
    grid-template-columns: repeat(2, 1fr);

    .sampleCard {
      margin: 25px 15px;

      @include tn {
        margin: 20px 5px;
      }
    }
  }

  @media(max-width: 735px) {
    grid-template-columns: 1fr;
  }
}

.center {
  margin: 0 auto;
}

.filterArea {
  @include tn {
    width: 100%;
    flex-grow: 1;
  }
}

.emptyMsg {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;

  @include sm {
    padding-bottom: 30px;
  }
}