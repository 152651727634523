@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';
@import 'src/utils/animation.scss';

.homePage {
  .mainContainer {
   width: 100%;
   display: flex;
   flex-direction: column;
   margin: auto;
   overflow-x: hidden;
  }
  .CTAButton {
    width: 270px;
  }
  .widthConstraintContainer {
    max-width: 1440px;
    width: 100%;
  }
}

.homeSection1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 78px;
  padding-bottom: 34px;
  @media(max-width: 1100px) {
    transform: scale(0.9);
  }
  @media(max-width: 1000px) {
    transform: scale(0.8);
  }
  @media(max-width: 900px) {
    transform: scale(0.7);
  }
  @media(max-width: 700px) {
    transform: scale(0.6);
  }
  @media(max-width: 650px) {
    transform: scale(0.6);
  }
  @media(max-width: 600px) {
    width: 100%;
    height: auto;
    transform: scale(0.9);
    transform-origin: center center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media(max-width: 575px) {
    transform: scale(0.85);
    padding-top: 0px;
    padding-bottom: 30px;
  }
  @media(max-width: 520px) {
    transform: scale(0.8);
    padding-bottom: 20px;
  }
  @media(max-width: 485px) {
    transform: scale(0.75);
    margin-top: -20px;
    margin-bottom: -40px;
  }
  @media(max-width: 450px) {
    transform: scale(0.7);
    margin-top: -50px;
    margin-bottom: -70px;
  }
  @media(max-width: 420px) {
    transform: scale(0.65);
    margin-top: -100px;
    margin-bottom: -120px;
  }
  @media(max-width: 390px) {
    transform: scale(0.6);
    margin-top: -120px;
    margin-bottom: -140px;
  }
  @media(max-width: 365px) {
    transform: scale(0.55);
    margin-top: -140px;
    margin-bottom: -170px;
  }
  @media(max-width: 335px) {
    transform: scale(0.5);
    margin-top: -170px;
    margin-bottom: -210px;
  }
  @media(max-width: 300px) {
    transform: scale(0.45);
    margin-top: -200px;
    margin-bottom: -240px;
  }

  .bigHeroImage {
    @media(max-width: 600px) {
      display: none;
    }
  }

  .smallHeroImage {
    display: none;
    @media(max-width: 600px) {
      display: block;
    }
  }

  .heroImgContainer {
    width: 1110px;
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media(max-width: 600px) {
      width: 584px;
      height: 984px;
    }
  }
  .heroImage {
    cursor: pointer;
  }
  .heroImagePopover {
    position: absolute;
    z-index: 1;
    width: 128px;
    height: 69px;
    border-radius: 16px;
    padding: 10px;
    cursor: pointer;
    &::after {
      content: '';
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 10px solid $black700;;
      display: block;
      position: absolute;
      bottom: 0;
      left: 64px;
      transform: translate(-50%, 100%);
    }
    &.popover1 {
      top: -5%;
      left: 46.5%;
      transition: opacity 0.5s;
      animation: breath1 6s infinite;
      &::after {
        border-top: 10px solid $black700;
      }
    }
    &.popover2 {
      top: 12%;
      left: 70.7%;
      transition: opacity 0.5s;
      animation: breath2 5s infinite;
      &::after {
        border-top: 10px solid $black700;
      }
    }
    &.popover3 {
      top: 31%;
      left: 10.5%;
      transition: opacity 0.5s;
      animation: breath2 6s infinite;
      &::after {
        border-top: 10px solid $primary500;
      }
    }
    &.popover4 {
      top: 54%;
      left: 28%;
      transition: opacity 0.5s;
      animation: breath1 7s infinite;
      &::after {
        border-top: 10px solid $primary500;
      }
    }
    .popOverTitle {
      font-weight: 700;
      color: $white;
      font-size: 18px;
      line-height: 29px;
    }

    .popOverContent {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @media(max-width: 600px) {
      &.popover1 {
        top: auto;
        bottom: 10%;
        left: 8%;
        &::after {
          border-top: 10px solid $black700;
          bottom: auto;
          top: 0px;
          transform: translate(-50%, -100%) scale(-1);
        }
      }

      &.popover2 {
        top: 49%;
        left: 70.7%;
      }

      &.popover3 {
        top: 7%;
        left: auto;
        right: 7%;
      }

      &.popover4 {
        top: 30%;
        left: 10%;
      }
    }

    @media(max-width: 520px) {
      transform: scale(1.15);

      &.popover1 {
        top: auto;
        bottom: 10%;
        left: 8%;
        animation: breathSM1 6s infinite;
      }

      &.popover2 {
        top: 49%;
        left: 70.7%;
        animation: breathSM2 5s infinite;
      }

      &.popover3 {
        top: 7%;
        left: auto;
        right: 7%;
        animation: breathSM1 6s infinite;
      }

      &.popover4 {
        top: 30%;
        left: 10%;
        animation: breathSM2 7s infinite;
      }
    }

    @media(max-width: 390px) {
      transform: scale(1.32);

      &.popover1 {
        top: auto;
        bottom: 10%;
        left: 8%;
        animation: breathXS1 6s infinite;
      }

      &.popover2 {
        top: 49%;
        left: 70.7%;
        animation: breathXS1 5s infinite;
      }

      &.popover3 {
        top: 7%;
        left: auto;
        right: 7%;
        animation: breathXS2 6s infinite;
      }

      &.popover4 {
        top: 30%;
        left: 10%;
        animation: breathXS2 7s infinite;
      }
    }
  }
}

.homeSection2 {
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding-top: 20px;
  align-items: center;
  @media(max-width: 600px) {
    gap: 43px;
  }
  .homeLogoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      user-select: none;
      width: 156px;
      height: 60px;
    }
    .catchCopy {
      user-select: none;
      color: #414EE1;
      font-size: 28px;
      font-weight: 700;
      @media(max-width: 480px) {
        font-size: 22px;
      }
    }
  }
  .latestNewsBackground {
    width: 100%;
    background-color: #414EE1;
    height: 230px;
    border-bottom-left-radius: 24px;
    @media(max-width: 600px) {
      border-bottom-left-radius: 0px;
    }
    @media(max-width: 340px) {
      height: 180px;
    }
  }
  .widthConstraintContainer {
    display: flex;
    flex-direction: column;
    margin-top: -250px;
    @media(max-width: 340px) {
      margin-top: -200px;
    }
    .latestNewsContainer {
      width: 80%;
      max-width: 1110px;
      align-self: center;
      margin-top: 30px;
      @media(max-width: 1200px) {
        width: 85%;
      }

      @media(max-width: 1024px) {
        width: calc(100% - 40px);
      }

    }
    .latestNewsTxt {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      padding-left: 8px;
    }
    .carouselContainer {
      margin-top: 16px;
      position: relative;
      width: 100%;
      max-width: 1110px;
      .cardCarousel {
        width: 100%;
      }
      .carouselImageContainer {
        cursor: pointer;
        width: 350px;
        height: 235px;
        display: block;
        @media(max-width: 420px) {
          width: 100%;
          height: auto;
        }
        .imgCarousel {
          height: 100%;
          width: 100%;
          aspect-ratio: 350 / 235;
          border-radius: 10px;
          object-fit: cover;
        }
        .homeCarouselMask {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.21%, rgba(0, 0, 0, 0.7) 94.68%);
          position: absolute;
          top:0;
          height: 100%;
          width: 100%;
          aspect-ratio: 350 / 235;
          border-radius: 10px;
        }
      }
    }
  }
}

.homeSection3, .homeSection4 {
  display: flex;
  min-height: 680px;
  justify-content: center;
  gap: 50px;
  @media(max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
 .introContainer {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 45%;
  max-width: 380px;
  color: #0C0E17;
  .homeIntroImage {
    background-color: lightgray;
    height: 225px;
    width: 100%;
    aspect-ratio:  445 / 225;
  }
  .introTxtContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 650px;
    align-self: center;
    @media(max-width: 1024px) {
      text-align: justify;
    }
    .homeSubTitleContainer {
      display: flex;
      flex-direction: column;
      @media(max-width: 1024px) {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
      }
      @media(max-width: 363px) {
        flex-direction: column;
        justify-content: center;
        gap: 0px;
      }
      .homeSubTitle {
        font-weight: 700;
        font-size: 28px;
        line-height: 36.5px;
        @media(max-width: 900px) {
          text-align: center;
        }
        @media(max-width: 480px) {
          font-size: 22px;
        }
      }
    }
    .homeSubContent {
      font-size: 18px;
      line-height: 28.8px;
      @media(max-width: 480px) {
        font-size: 14px;
        line-height: 22.4px;
      }
    }
  }
 }

  .columnBtn {
    display: none;
    @media(max-width: 1024px) {
      display: flex;
    }
  }

  .rowBtn {
    @media(max-width: 1024px) {
      display: none;
    }
  }
}

.homeSection3 {
  padding: 100px 0px;
  display: flex;
  @media(max-width: 1400px) {
    padding: 100px 40px;
  }
  @media(max-width: 1280px) {
    gap: 30px
  }
  @media(max-width: 1024px) {
    flex-direction: column;
    padding: 40px 20px;
    align-items: center;
  }
  @media(max-width: 480px) {
    padding: 40px 0px;
  }
  .tabsContainer {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 636px;
    @media(max-width: 1300px) {
      max-width: 600px;
    }
    @media(max-width: 1200px) {
      max-width: 500px;
    }
    @media(max-width: 1100px) {
      max-width: 450px;
    }
    @media(max-width: 1024px) {
      max-width: 636px;
      gap: 100px;
      align-items: center;
    }
    @media(max-width: 700px) {
      max-width: 600px;
    }
    @media(max-width: 575px) {
      max-width: 100%;
    }
    @media(max-width: 480px) {
      gap: 18px;
    }

    .labelContainer {
      width: 100%;
      @media(max-width: 1024px) {
        justify-content: center;
      }
      @media(max-width: 767px) {
        margin-left: 0;
      }
    }
    
    .tabContent {
      width: 636px;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media(max-width: 1300px) {
        transform-origin: left center;
        transform: scale(0.9);
      }
      @media(max-width: 1200px) {
        transform: scale(0.8);
      }
      @media(max-width: 1100px) {
        transform: scale(0.75);
      }
      @media(max-width: 1024px) {
        transform: unset;
        margin-top: -50px;
      }
      @media(max-width: 700px) {
        transform: scale(0.8);
        margin-top: -100px;
        margin-bottom: -50px;
        transform-origin: center center;
      }
      @media(max-width: 600px) {
        transform: scale(0.8);
      }
      @media(max-width: 575px) {
        align-self: center;
        transform: unset;
        width: 376px;
        height: 470px;
        margin-top: -70px;
        margin-bottom: 0;
      }
      @media(max-width: 480px) {
        margin-top: 0px;
        margin-bottom: 80px;
      }
      @media(max-width: 390px) {
        transform: scale(0.9);
      }
      @media(max-width: 340px) {
        transform: scale(0.8);
      }
      @media(max-width: 300px) {
        transform: scale(0.7);
      }
    }

    .sdgsBubbleContainer {
      width: 636px;
      height: 400px;
      position: relative;
      @media(max-width: 575px) {
        width: 329px;
        height: 445px;
      }
    }
    .homeActionBtn {
      align-self: flex-end;
      @media(max-width: 1024px) {
        align-self: center;
      }
    }
  }
  .introContainer {
    @media(max-width: 1024px) {
      align-self: center;
      width: 100%;
      max-width: 100%; // to override previous setting
    }
    @media(max-width: 900px) {
      flex-direction: column;
      padding: 40px;
    }
    @media(max-width: 768px) {
      padding: 20px;
    }
    @media(max-width: 575px) {
      padding: 10px;
    }
    @media(max-width: 480px) {
      padding: 18px;
    }
  }
}

.homeSection4 {
  padding: 100px 0px;
  @media(max-width: 1024px) {
    padding: 0;
  }
  .widthConstraintContainer {
    display: flex;
    justify-content: center;
    gap: 30px;
    @media(max-width: 1024px) {
      flex-direction: column-reverse;
      padding: 40px 20px;
      gap: 40px;
    }
    @media(max-width: 480px) {
      flex-direction: column-reverse;
      padding: 40px 0px;
    }
  }
  .homeResourceCarouselContainer {
    display: flex;
    flex-direction: column;
    margin-left: -5%;
    position: relative;
    @media(max-width: 1190px) {
      margin-left: 0;
    }
    @media(max-width: 1024px) { // centered btn
      margin-left: 0;
      gap: 80px;
      align-items: center;
    }
    .homeResourceCarouselTitle {
      font-weight: 600;
      font-size: 28px;
      text-align: center;
      line-height: 31px;
      @media(max-width: 480px) {
        font-size: 20px;
        line-height: 22px;
      }
    }
    .homeResourceCarousel {
      max-width: 750px;
      width: 100%;
      height: 100%;
      @media(max-width: 1280px) {
        max-width: 650px;
      }
      @media(max-width: 1190px) {
        max-width: 500px;
      }
      @media(max-width: 1100px) {
        max-width: 450px;
      }
      @media(max-width: 1024px) {
        max-width: 650px;
      }
      @media(max-width: 480px) {
        max-width: 100%;
      }
      .homeResourceSwiperSlide {
        height: 100%;
        .homeResourceSlide {
          display: flex;
          flex-direction: column;
          text-align: center;
          height: 100%;
          align-items: center;
          .homeResourceSlideImg {
            flex: 1;
            max-height: 90%;
            width: 300px;
            height: 400px;
            object-fit: contain;
            @media(max-width: 659px) {
              width: 240px;
              height: 320px;
            }
          }
        }
      }
    }
    .homeResourceSlideTxt {
      display: flex;
      align-items: center;
      align-self: center;
      font-size: 18px;
      color: $primary500;
      line-height: 160%;
      font-weight: 700;
      user-select: none;
      cursor: pointer;
      position: absolute;
      bottom: 20px;
      @media(max-width: 1024px) {
        bottom: 140px;
      }

      @media(max-width: 768px) {
        bottom: 120px;
      }

      .homeResourceSlideCTA {
        color: $white;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        align-items: center;
      }
    }
  }

  .introContainer {
    flex: 1;
    padding-top: 25px;
    @media(max-width: 1024px) {
      align-self: center;
      width: 100%;
      max-width: 100%;
      padding-top: 0;
    }
    @media(max-width: 900px) {
      flex-direction: column-reverse;
      padding: 40px;
    }
    @media(max-width: 768px) {
      padding: 20px;
    }
    @media(max-width: 575px) {
      padding: 10px;
    }
    @media(max-width: 480px) {
      padding: 18px;
    }
  }

  
}

  