@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.checkoutPage {
  @include page;
  position: relative;

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;
    // border: 1px solid black;
    padding: 0 20px;
    padding-bottom: 40px;
    max-width: 600px;

    @include tn {
      align-items: flex-start;
    }

    margin-bottom: 20px;
  }

}

.content {
  width: 100%;
  margin-top: 30px;
  border-top: 1px solid $primary50;
}

.formSection {
  
    .title {
      //styleName: Noto Sans TC/16px/Bold;
      font-family: Noto Sans TC;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: $gray700;

      margin: 40px 0px;
    }
}

.totalAmount {
  display: flex;
  justify-content: space-between;
  width: 100%;
  //styleName: Noto Sans TC/20px/Bold;
  font-family: Noto Sans TC;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: $black900;
  margin: 30px 0;
}



.row {
  display: flex;
  align-items: center;
}

.spaceBtw {
  justify-content: space-between;
}

.button {
  width: 335px;

  @include tn {
    width: 180px;
    align-self: center;
  }
}

.productCardContainer {
  width: 100%;
  padding: 0 20px;
}