@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.stepSection {
    display: flex;
    align-items: flex-start;
    gap: 60px;
    margin-top: 20px;
    margin-bottom: 40px;
    position: relative;

    @include lg {
        gap: 30px;
    }

    @include sm {
      flex-direction: column;
      gap: 0px;
    }
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  @include sm {
    overflow: hidden;
  }
}

.stepMainContent {
    flex: 1;
    position: relative;
    @include sm {
      width: 100%;
    }
}

.uploadPhoto {
    margin-bottom: 40px;
}

.customIdDesc {
  margin-bottom: 20px;
  line-height: 1.5;
}

.eventUrlContainer {

  .eventUrl {
    display: inline-block;
    vertical-align: top;
    margin-top: 23px;
    margin-right: 3px;
    margin-bottom: 20px;
  }
  
  .customIdInputContainer {
    display: inline-block;
    width: 206px;
  }
}

.demandNameTipContainer {
  position: absolute;
  right: 0;

  @include sm {
    position: relative;
  }
}

.demandNameTipContainerMobile {
  margin-top: 40px;
  margin-bottom: 40px;
}

.featureItem {
  margin-top: 20px;
  margin-bottom: 40px;
  @include sm {
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.itemTitle {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  //styleName: Noto Sans TC/18px/Bold;
  font-family: Noto Sans TC;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: $black900;
}

.flexRowCenter {
  display: flex;
  align-items: center;
}

.demandTabWrapper {
  margin-bottom: 0;
}

.demandTabsSection {
  margin-bottom: 0;
  margin-top: 0;
}

.borderlessBtnText {
  color: $primary500;
}

.pointsText {
  color: $primary500;
}

.releaseTimeOption {
  display: flex;
  align-items: center;
}

.releaseTimeOption.auto {
  @include sm {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}

.row {
  display: flex;
}

.col {
  flex-direction: column;
  align-items: flex-start;
}

.fieldDesc {
  //styleName: Noto Sans TC/16px/Bold;
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: $black900;
  margin-bottom: 20px;
}

.addItemButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: none;
}

.flexCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

// registration

.registrationRequiresCheckboxes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.checkboxItem {
  display: flex;
  align-items: center;
  gap: 5px;

  .label {
    //styleName: Noto Sans TC/16px/Regular;
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: $black900;
  }
}

.fieldContainer {
  background-color: $gray550;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 40px;
  gap: 20px;

  .field {
    display: flex;
    flex-direction: column;
    gap: 10px;

    h5 {
      //styleName: Noto Sans TC/16px/Medium;
      font-family: Noto Sans TC;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
      color: $black900;
    }
  }
}

.draggableOpt {
  display: flex;
  align-items: center;

  .iconDrag {
    margin-right: 10px;
    margin-bottom: 30px;
  }

  .iconClose {
    margin-left: 10px;
    margin-bottom: 30px;
  }
}

.addOptionButton {
  align-self: flex-end;
}

.itemCloseIcon {
  margin-left: 10px;
  margin-bottom: 62px;
}

.upper {
  margin-top: -20px;
}

.fieldButtons {
  display: flex;
  margin-top: 20px;
}

.error {
  color: $red500 !important;
}

.deleteButton {
  width: 100%;
  max-width: none;
}

.datesOptions {
  display: flex;
  gap: 7px;
  margin-top: 10px;
}

.releaseMethods {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.noMargin {
  margin: 0;
}

.tipPlaceholder {
  width: 290px;
  flex-shrink: 0;

  @include sm {
    display: none;
  }
}

.demandTopArea {
  display: flex;
  gap: 60px;

  @include lg {
    gap: 30px;
  }
}

.flexGrow {
  flex-grow: 1;
}

.fullWidth {
  width: 100%;
}

// sdgs bubbles
.sdgsOuterWrapper {
  @include sm {
    width: 100vw;
    margin-left: -30px;
    overflow: hidden;
  }

  @include xs {
    margin-left: -15px;
  }
}

.sdgsBubbleWrapper {
  width: 100%;
  max-width: 553px;
  margin: 0 auto;
  height: 740px;
  margin-bottom: 20px;

  @include xs {
    transform: scale(0.9);
    margin-bottom: 0px;
  }
}

.sdgBubblesContainer {
  width: 100%;
  height: 100%;

  transform: scale(1) !important;
}

.uploadedItemsContainer {
  border: 1px solid $primary500;
  width: 100%;
  border-radius: 22px;
  padding: 15px;
  position: relative;

  .title {
    font-size: 12px;
    color: $primary500;
    background-color: #fff;
    width: fit-content;
    padding: 2px;
    position: absolute;
    top: -8px;
    left: 12px;
  }

  .requiredMark {
    &::before {
      color: $red500;
      font-weight: 700;
      font-size: 12px;
      line-height: 160%;
      margin-right: 2px;
      content: '*';
      display: inline-block;
    }
  }
}

.bannerContainer {
  margin-bottom: 30px;
}