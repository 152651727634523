/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Poppins:wght@400;500;600;700&display=swap');
@import 'src/utils/constants.scss';
@import 'src/utils/common.scss';

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Noto Sans TC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

input, textarea {
  font-size: initial;
}

a {
  text-decoration: none;
}

// prevent eye icon show at password input when using Edge
::-ms-reveal {
  display: none
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-break: normal; /* anywhere */
}

.nowrap {
  word-wrap: nowrap;
  white-space: nowrap;
}

.uploadArea .ant-upload {
  width: 100%;
  max-width: 335px;
}

.uploadFullWidth.uploadArea {
  .ant-upload {
    width: 100%;
    max-width: none;
  }
}

.step_upload_photo .ant-upload {
  width: 100%;
  max-width: none;
}

.uploadedItemsContainer {

  .ant-upload {
    width: 100%;
    max-width: 303px;
    margin-top: 10px;
  }
}

.custom-input-required .MuiInputLabel-root[data-shrink = 'true'] {
  &::before {
    content: '*';
    display: inline-block;
    width: 8px;
    height: 5px;
    color: $red500;
  }
}

.MuiInputLabel-root[data-shrink = 'false'] {
  top: 3px !important;
}

.MuiInputLabel-asterisk {
  display: none;
}

.rotateSelectArrow {
  transform: rotate(180deg) translateY(6px);
}

.rotateSelect360 {
  transform: rotate(360deg);
}

hr.dashed {
  height: 1.5px;
  background-image: linear-gradient(90deg, #fff, #fff 45%, transparent 45%, transparent 100%);
  background-size: 6px 1px;
  border: none;
}

hr.dashedPurple {
  height: 1.5px;
  background-image: linear-gradient(90deg, $primary500, $primary500 45%, transparent 45%, transparent 100%);
  background-size: 6px 1px;
  border: none;
}

hr.dashedGray {
  height: 1.5px;
  background-image: linear-gradient(90deg, $gray700, $gray700 45%, transparent 45%, transparent 100%);
  background-size: 6px 1px;
  border: none;
}

.sdgHeart1 {
  display: block;
  margin: 0 2px;
  color: $black500;
}

.sdgHeart2 {
  display: block;
  margin: 0 2px;
  color: $secondary;
}

.sdgHeart3 {
  display: block;
  margin: 0 2px;
  color: $primary500;
}

.page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.filterSearchInput {
  border-radius: 26px;
  height: 40px;
  border: 1px solid #EAEAED;

  &:hover {
    border-color: $primary500 !important;
  }

  &:focus {
    border-color: $primary500 !important;
  }

  input::placeholder {
    color: #707070;
    font-size: 16px;
  }
}

.eventSearchInput {
  .MuiOutlinedInput-root {
    height: 49px;
    @include sm {
      height: 44px;
    }
  }
}

.filterSelect .ant-select-selector{
  background-color: #F9F9F9 !important;
  border: none !important;
}

.customPicker .ant-picker-input{
  input::placeholder {
    color: #707070;
    font-size: 16px;
  }
}

.eventTabs {
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 20;

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-wrap .ant-tabs-nav-list{
    width: 80% !important;

    @include sm {
      width: 100% !important;
    }

    .ant-tabs-tab {
      width: 100% !important;
      justify-content: center;
      font-weight: 500;
    }
  }
}

.faqCollapse {
  .ant-collapse-item {
    padding: 0 10px;
    border-top: 1px solid $gray600;
  }
}

.customSwiper {
  --swiper-theme-color: #414EE1;
  padding-bottom: 45px !important;

  .swiper-pagination {
    width: fit-content !important;
    left: 15px !important;
  }

  .swiper-pagination-bullet {
    transition: .3s;
    width: 6px;
    height: 6px;
  }

  .swiper-pagination-bullet-active {
    width: 24px !important;
    border-radius: 6px;
  }
}

.customSwiper2 {
  --swiper-theme-color: #414EE1;
  padding-bottom: 45px !important;

  .swiper-pagination {
    width: fit-content !important;
    left: 0px !important;
  }

  .swiper-pagination-bullet {
    transition: .3s;
    width: 6px;
    height: 6px;
  }

  .swiper-pagination-bullet-active {
    width: 24px !important;
    border-radius: 6px;
  }
}

.partnersDemandsSwiper {
  .swiper-slide {
    width: 320px;
    height: calc(100% - 40px);
    margin-left: 20px;

    @include sm {
      width: 288px;
    }
  }
}

.targetsSwiper {
  .swiper-slide {
    width: 360px;
    height: calc(100% - 35px);

    @include sm {
      width: 330px;
    }

    @include xxs {
      width: 280px;
    }
  }
}

.eventSamplesSwiper {
  .swiper-slide {
    width: 360px;
    height: calc(100% - 35px);

    @include sm {
      width: 335px;
    }

    @include xxs {
      width: 280px;
    }
  }
}

.keyItemsSwiper {
  --swiper-theme-color: #fff !important;

  .swiper-pagination-bullet {
    background: #D0D0D0;
    opacity: 0.9;
  }

  .swiper-pagination-bullet-active {
    background: #fff;
  }
}

.profileCardSwiper {
  width: 100%;
  .swiper-wrapper {
    width: 100%;
  }
  .swiper-slide {
    width: 394px;

    @include xxs {
      width: 100%;
      padding-bottom: 20px;
    }
  }
}

.similarEventSwiper {
  width: 100%;
  .swiper-wrapper {
    width: 100%;
  }
  .swiper-slide {
    width: 394px;

    @include xxs {
      width: 100%;
      padding-bottom: 20px;
    }
  }
}

.recommendResourceSwiper {
  width: 100%;
  .swiper-wrapper {
    width: 100%;
  }
  .swiper-slide {
    width: 335px;

    @include tn {
      width: 280px;
    }
  }
}

.topicsPageDemandsSwiper {
  padding-top: 45px !important;
  padding-left: 3px !important;
  padding-right: 25px !important;

  @media(max-width: 321px) {
    padding-left: 0px !important;
    padding-right: 15px !important;
  }

  .swiper-slide {
    @media(max-width: 321px) {
      margin-left: 15px;
    }
  }
}

.searchInput {
  .ant-input-affix-wrapper {
    border-radius: 30px !important;

    &:hover {
      border-color: $primary500;
    }
  }
  .ant-input-group-addon {
    display: none;
  }
}

.ant-pagination {
  .ant-pagination-item-active {
    border: none;

    a {
      color: $primary500;
      font-weight: 600;
    }
  }
}

.sortDropdown {
  min-width: 70px !important;

  .ant-dropdown-menu-item {
    line-height: 2.5 !important;

    &:hover {
      background-color: $primary50 !important;
    }
  }

  .ant-dropdown-menu-title-content {
    text-align: center;
  }
}

.moreDropdown {
  min-width: 100px !important;
  z-index: 900;

  .ant-dropdown-menu-item {
    line-height: 2.5 !important;

    &:hover {
      background-color: $primary50 !important;
    }
  }

  .ant-dropdown-menu-title-content {
    text-align: center;
  }
}

.moreDropdownSm {
  // min-width: 100px !important;
  z-index: 900;

  .ant-dropdown-menu-item {
    line-height: 2.5 !important;

    &:hover {
      background-color: $primary50 !important;
    }
  }

  .ant-dropdown-menu-title-content {
    text-align: center;
  }
}

.myEventsSwiper {
  .swiper-slide {
    width: 400px;
    opacity: 0.1;
    transition: 0.3s;
  }
  .swiper-slide-active {
    opacity: 1;
  }
  .swiper-slide-prev {
    display: flex;
    justify-content: flex-end;
    .card {
      width: 400px;
      transform: scale(0.8) translateX(10px);
      @media(max-width: 1024px) {
        width: auto;
        transform: none;
      }
    }
    @media(max-width: 1024px) {
      margin-top: 10px;
    }
    
  }
  .swiper-slide-next {
    .card {
      width: 400px;
      transform: scale(0.8) translateX(-15px);
      @media(max-width: 1024px) {
        width: auto;
        transform: none;
      }
    }
    @media(max-width: 1024px) {
      margin-top: 10px;
    }
  }
}

.myResourceSwiper {
  width: 100%;
  padding-top: 12px !important;
  padding-bottom: 8px !important;

  .swiper-wrapper {
    width: 100%;
  }
  .swiper-slide {
    width: map-get($resourceCardForSwiperWidth, 'global');
    transition: margin-top .3s;

    @include sm {
      width: map-get($resourceCardForSwiperWidth, 'sm');
    }
    @include xxs {
      width: map-get($resourceCardForSwiperWidth, 'xxs');
    }
  }

  .swiper-slide-prev {
    margin-top: 10px;
  }

  .swiper-slide-next {
    margin-top: 10px;
  }
}

.myResourcesModalContainer {
  .ant-drawer-body {
    @include xxs {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.homeResourceSwiper {
  .swiper-wrapper {
    position: relative;
    .swiper-slide {
      opacity: 0;
    }
    .swiper-slide-active {
      opacity: 1;
    }
    .swiper-slide-prev {
      opacity: 0.6;
    }
  
    .swiper-slide-next {
      opacity: 0.6;
    }
  }
  .swiper-button-prev {
    position: absolute;
    bottom: 13%;
    left: 26%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1;
    @media(max-width: 1280px) {
      bottom: 8%;
      left: 23%
    }
    @media(max-width: 1190px) {
      bottom: 8%;
      left: 15%
    }
    @media(max-width: 1024px) {
      bottom: 5%;
      left: 20%
    }
    // @media(max-width: 640px) {
    //   bottom: 8%;
    //   left: 23%
    // }
    @media(max-width: 570px) {
      bottom: 5%;
      left: 20%;
    }
    @media(max-width: 480px) {
      bottom: 3%;
      left: 20%;
    }
    @media(max-width: 420px) {
      left: 16%;
    }
    @media(max-width: 380px) {
      left: 12%;
    }
  }
  .swiper-button-next {
    position: absolute;
    bottom: 13%;
    right: 26%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10;
    @media(max-width: 1280px) {
      bottom: 8%;
      right: 23%
    }
    @media(max-width: 1190px) {
      bottom: 8%;
      right: 15%
    }
    @media(max-width: 1024px) {
      bottom: 5%;
      right: 20%
    }
    // @media(max-width: 640px) {
    //   bottom: 8%;
    //   right: 23%
    // }
    @media(max-width: 570px) {
      bottom: 5%;
      right: 20%;
    }
    @media(max-width: 480px) {
      bottom: 3%;
      right: 20%;
    }
    @media(max-width: 420px) {
      right: 16%;
    }
    @media(max-width: 380px) {
      right: 12%;
    }
  }
}

.ant-modal {
  .ant-modal-content {
    border-radius: 24px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 60px white inset !important;
    background-color: white !important;
    background-clip: content-box !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.redBorder div{
  border-color: $red500;
}

.orgContactPhone {
  .MuiFormControl-root {
    max-width: 208px;
    margin-left: 5px;
  }
}

.custom-input-required {
  .MuiInputLabel-root {
    &::before {
      content: '*';
      color: $red500;
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
      margin-right: 2px;
    }
  }
}

.mainMenuMobile {
  .ant-drawer-header-title {
    justify-content: flex-end;

    button {
      margin: 0;
    }
  }
}

.MuiFormControl-root[readonly] {
  .MuiInputLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.6);
  }

  .MuiInputBase-readOnly.MuiOutlinedInput-root:hover {
    fieldset {
      border-color: $gray600;
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    fieldset {
      border-color: $gray600;
    }
  }
}

.customSelect.readOnly:hover {
  .ant-select-selector {
    border-color: $gray600 !important;
  }
}

.customSelect.ant-select-focused.readOnly {
  .ant-select-selector {
    border-color: $gray600 !important;
  }
}


.loadingMask {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba($color: #fff, $alpha: 0.5);
  z-index: 10000;
}

.customCheckbox .ant-checkbox-checked .ant-checkbox-inner:after{
  transform: rotate(45deg) scale(0.7) translate(-70%,-80%);
}

.customInput {
  background-color: $white;
  border-radius: 16px;
}

.bold {
  font-weight: 700;
}