@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.subtitle {
    font-family: Noto Sans TC;
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    color: $primary500;

    @include sm {
        font-size: 18px;
    }
}