@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.cardContainer {
    box-shadow: 0px 4px 50px 0px #B6C5CD4D;
    background-color: $white;
    padding: 12px 15px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    // text
    font-family: Noto Sans TC;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: $gray500;

}

.icon {
    margin-right: 12px;
}

.title {
    //styleName: Noto Sans TC/16px/Bold;
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: $black500;
}

.days {
    display: flex;
    gap: 3px;
    //styleName: Noto Sans TC/16px/Bold;
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}

.green {
    color: $green;
}

.row {
    display: flex;
    align-items: center;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.infoRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.infoText {
    font-size: 12px;
    line-height: 19.2px;
    flex-shrink: 0;
}

.orderId {
    flex-shrink: 1;
    word-break: break-all;
}

.alignRight {
    margin-left: auto;
}

.iconCopy {
    margin-left: 7px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.orderTag {
    padding: 7px 12px 7px 12px;
    border-radius: 40px;
    font-family: Noto Sans TC;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.orderTag.success {
    background-color: $primary50;
    color: $primary500;
}

.orderTag.pending {
    background-color: $red50;
    color: $red500;
}

.orderTag.fail {
    background-color: $red50;
    color: $red500;
}

