@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.titleContainer {
    display: flex;
    align-items: center;
    gap: 8px;

    .title {
        //styleName: Noto Sans TC/18px/Medium;
        font-family: Noto Sans TC;
        font-size: 18px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: $black;
    }
}