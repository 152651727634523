@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.createEventPage {
  @include page;

  .flexRow {
    display: flex;
    align-items: center;
  }

  .contentWrapper {
    @include mainCenterContainer2;
    width: 1110px;
    min-height: 750px;
    display: flex;
    flex-direction: row;
    padding-bottom: 80px;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;

    @include md {
      width: 100%;
    }
  }

  .mainContent {
    position: relative;
    // border: 1px solid gray;
    flex: 1;
    padding-left: 17px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;

    @include md {
      padding-right: 10px;
    }

    @include sm {
      padding: 30px 30px;
    }

    @include xs {
      padding: 30px 15px;
    }
  }

  .stepWrapper {
    position: relative;
  }

  .rightAreaContainer {
    display: flex;
    align-items: center;

    .button {
      cursor: pointer;
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 6px;
      color: $gray700;
      font-weight: 400;
    }

    .togglePreviewButton {
      @include sm {
        display: none;
      }
    }
  }

  .autoSaveHintRow {
    display: flex;
    align-items: center;
    gap: 60px;

    @include lg {
        gap: 30px;
    }
  }

  .autoSaveHint {
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: $primary300;
    flex-grow: 1;
  }

  .tipPlaceholder {
    width: 320px;
    flex-shrink: 0;

    @include sm {
        display: none;
    }
  }

  .stickyTitleAndSubtitle {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: sticky;
    top: 90px;
    z-index: 100;
    transition: .3s;
    background-color: $white;
    width: calc(100% - 330px);

    padding-bottom: 5px;
    margin-bottom: 35px;

    @include sm {
      width: 100%;
      top: 65px;
      padding-bottom: 2px;
      margin-bottom: 15px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      background-color: white;
      width: calc(100% + 18px);
      height: 40px;
      transform: translateY(-100%) translateX(-18px);

      @include md {
        width: calc(100% + 16px);
        transform: translateY(-100%) translateX(-16px);
      }

      @include sm {
        height: 30px;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: white;
      width: 18px;
      height: 100%;
      transform: translateX(-100%);
      z-index: -1;

      @include md {
        width: 16px;
      }

      @include sm {
        width: calc(100% + 60px);
        height: 100%;
        transform: translateX(-30px);
      }
  
      @include xs {
        width: calc(100% + 30px);
        transform: translateX(-15px);
      }
    }
  }

  .stickyTitleAndSubtitle.lower {
    top: calc(90px + map-get($headerH, 'global'));

    @include sm {
      top: calc(65px + map-get($headerH, 'sm'));
    }
  }

  .otherEditing {
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: $black900;

    .underline {
      font-weight: 700;
      text-decoration: underline;
    }

  }
}


  