@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

html {
  scroll-behavior: smooth;
}

.navContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: map-get($breadCrumbH, 'global');
  z-index: 50;
  background-color: $white;
  overflow-x: scroll;
  padding-bottom: 2px;
  margin-top: 20px;
  // margin-bottom: 50px;
  padding: 0 20px;
  cursor: pointer;
  transition: .3s;
  overflow: hidden;
  width: 100%;
  background-color: $white;

  @include sm {
    top: map-get($breadCrumbH, 'sm');
  }

  @include tn {
    gap: 0px;
  }
}
.navlink {
  padding: 10px 70px;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: $black300;
  border-bottom: 1px solid $gray50;
  flex-grow: 1;

  &::after {
    content: '';
    display: block;
    width: 0px;
    height: 3px;
    background-color: $primary500;
    transform: translateX(-50%);
    transform-origin: center;
    top: 95%;
    left: 50%;
    position: absolute;
    transition: .3s;
    opacity: 0;
  }

  @include sm {
    font-size: 14px;
    padding: 10px 50px;
  }

  @include xs {
    padding: 10px 30px;
  }

  @include tn {
    padding: 10px;
  }
}

.navlink.active {
  color: $primary500;
  position: relative;

  &::after {
    animation: fadein 0.3s;
    width: 100%;
    transform: translateX(-50%);
    opacity: 1;
  }
}

.section {
  min-height: 300px;
}

.navContainer.lower {
  top: calc(#{map-get($headerH, 'global')} + #{map-get($breadCrumbH, 'global')});

  @include sm {
    top: calc(#{map-get($headerH, 'sm')} + #{map-get($breadCrumbH, 'sm')});
  }
}


.nav {
  display: flex;
  width: 728px;
}