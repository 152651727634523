@import '../../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;


  .title {
    font-size: 24px;
    text-align: center;
    line-height: 130%;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.5;
    max-width: 400px;
    text-align: center;
    color: #696B74;

  }

  .marginBottom {
    margin-bottom: 15px;
  }

  .illustration {
    width: 150px;
  }

  .button {
    width: 180px;
    margin-top: 20px;
  }

  .name {
    display: inline-block;
    margin-left: 3px;
    color: $primary500;
  }

  .orgAuthContent {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: $black900;
    min-width: 68%;
    margin-bottom: 20px;
  }
  
  .wordBreak {
    word-break: break-all;
  }
}