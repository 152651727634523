@import '../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

$basis: 148px;

.footerWrapper {
  width: 100%;
}

.footerContainer {
  width: 100%;
  max-width: 1100px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 30px;
  @media(max-width: 575px) {
    flex-direction: column;
  }

  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: $black900;
  }


  @include lg {
    width: 80%;
  }

  .logoContainer {
    flex-basis: $basis;
    @media(max-width: 680px) {
      flex-basis: 128px;
    }
    @media(max-width: 575px) {
      flex-basis: auto;
    }
  }

  .logo {
    width: 65px;
  }

  .links {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-basis: $basis;
    @media(max-width: 575px) {
      flex-basis: auto;
    }
  }

  .link {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: $black900;
    flex-shrink: 0;
  }

  .separator {
    color: $gray700;
  }

  .icons {
    display: flex;
    gap: 20px;
    flex-basis: $basis;
    @media(max-width: 680px) {
      gap: 10px;
      flex-basis: 128px;
    }
    @media(max-width: 575px) {
      flex-basis: auto;
    }
  }
}

.footerContainer.col {
  flex-direction: column;

  .logoContainer {
    flex-basis: 0px;
  }

  .links {
    display: flex;
    gap: 15px;
    align-items: center;
    flex-basis: 0px;
  }

  .icons {
    display: flex;
    gap: 20px;
    flex-basis: 0px;
  }
}

.darkMode {
  color: $white;
  filter: invert(1);
}