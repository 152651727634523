@import '../../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  .title {
    font-size: 20px;
    text-align: center;
    word-wrap: break-word;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.5;
    max-width: 400px;
    text-align: center;
    color: $gray700;
  }

  .illustration {
    width: 150px;
  }

  .marginBottom {
    margin-bottom: 15px;
  }

  .button {
    width: 180px;
    margin-top: 20px;
  }

  .name {
    display: inline-block;
    margin-left: 3px;
    color: $primary500;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    @include sm {
      .button {
        width: 120px;
        gap: 20px;
      }
    }

    .cancelButton {
      color: $gray700;
    }
  }
}

.primary {
  color: $primary500;
}