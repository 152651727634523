.ant-btn {
    border-radius: 30px;
    height: 60px;
    flex-shrink: 0;
    max-width: 335px;
    transition: 0.5s;
    font-family: 'Noto Sans TC';
    font-weight: 700;
}

.ant-btn-primary:disabled {
    border: none;
}

.ant-btn-primary {
    box-shadow: 0px 8px 16px rgba(65, 78, 225, 0.2);
}

.buttonWithImage {
    width: fit-content;
    .ant-btn {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0 40px;
    }
    .buttonImage {
        position: absolute;
        right: 12px;
        bottom: 0;
    }
  }