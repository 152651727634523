@import '../../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.myResourcesModal {
  @include xxs {
    margin-top: -10px;
  }

  .card {
    width: 100%;
    max-width: map-get($resourceCardForSwiperWidth, 'global');
    box-shadow: 0px 4px 30px rgba(182, 197, 205, 0.3);

    @include xxs {
      width: map-get($resourceCardForSwiperWidth, 'xxs');
    }
  }
  .card.selected {
    &:before {
      content: '';
      position: absolute;
      top: -1.5px;
      right: -1.5px;
      bottom: -1.5px;
      left: -1.5px;
      border: 1.5px solid $primary500;
      border-radius: 17.5px; // border—radius: 16px + offset: 1.5px
    }
  
    &:after {
      content: '';
      background-image: url("../../../assets/checkbox.svg");
      width: 25px;
      height: 25px;
      display: block;
      position: absolute;
      top: -10px;
      right: 0;
      z-index: 3;
    }
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    color: $black500;
    text-align: center;
    margin-bottom: 16px;

    @include xxs {
      margin-bottom: 8px;
    }
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: $gray700;
    text-align: center;
  }

  .illustration {
    width: 45px;
    height: 45px;
    margin: 5px auto;
    display: block;
  }

  .button {
    font-size: 14px;
    margin: 10px auto 0;
    display: block;
  }

  .button.space {
    margin: 0px auto 0;
  }
}

.resourcesContainer {
  margin: 20px 0 0;
  position: relative;
  // overscroll-behavior: none;

  @include xxs {
    margin-top: 10px;
  }
}
.resourcesContainer.loading {
  margin: 0;
  min-height: 300px;

  @include xxs {
    height: 275px;
  }
}
.resourcesContainer.service {
  height: 310px;

  @include xxs {
    height: 285px;
  }
}
.resourcesContainer.funds {
  height: 310px;

  @include xxs {
    height: 275px;
  }
}
.resourcesContainer.space {
  margin-top: 0px;
  height: 360px;
}

.addCard {
  background: #FFFFFF;
  border: 1px dashed $primary200;
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: $primary500;
  width: 100%;
  max-width: map-get($resourceCardForSwiperWidth, 'global');
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @include sm {
    width: map-get($resourceCardForSwiperWidth, 'sm');
  }
  @include xxs {
    width: map-get($resourceCardForSwiperWidth, 'xxs');
  }
}

.addCard.service {
  height: 250px;

  @include xxs {
    height: 240px;
  }
}
.addCard.funds {
  height: 250px;

  @include xxs {
    height: 250px;
  }
}
.addCard.space {
  height: 328px;
}

.center {
  display: grid;
  place-content: center;
}

.noApplicableResourcesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;

  .title {
    font-size: 24px;
    line-height: 130%;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.5;
    max-width: 400px;
  }

  .marginBottom {
    margin-bottom: 15px;
  }

  .button {
    width: 180px;
    margin-top: 20px;
  }
}