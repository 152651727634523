@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.demandDetailPage {
  position: relative;
  .mainContainer {
    @include mainCenterContainer3;
    margin-top: 0;
    background-color: $white;
    box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
    border-radius: 16px;
    margin-top: 60px;
    margin-bottom: 120px;
    padding-bottom: 20px;

    @include sm {
      width: 90%;
    }
  }

  .contentBox {
    padding: 0 20px 20px;
  }

  .background {
    position: absolute;
    width: 100%;
    height: 275px;
    background-color: $primary500;
    z-index: -1;
  }

  .demandIcon {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 80px;

    @include sm {
      width: 60px;
    }
  }

  .demandIcon.service {
    width: 100px;

    @include sm {
      width: 80px;
    }
  }

  .demandIcon.other {
    background-color: $primary50;
    height: 80px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include sm {
      height: 60px;
    }
  }

  .tag {
    margin: 50px auto 10px;
    border-radius: 40px;
    background-color: $primary500;
    width: fit-content;
    padding: 0 10px;
    color: $white;
    height: 28px;
    line-height: 28px;
    font-weight: 700;
    font-size: 12px;

    @include sm {
      margin: 40px auto 10px;
    }

    .mark {
      display: inline-block;
      margin-right: 3px;
    }
  }

  .title {
    @include font-title;
    font-size: 22px;
    color: $black500;
    text-align: center;
  }

  .desc {
    @include font-text;
    font-size: 16px;
    text-align: center;
    line-height: 160%;
  }

  .subtitle {
    @include font-normal;
    color: $gray700;
    margin-top: 20px;
  }

  .textNormal {
    @include font-normal;
  }

  .textBold {
    @include font-bold;
  }

  .purple {
    color: $primary500;
  }

  .textCenter {
    text-align: center;
  }

  .illustration {
    width: 45px;
    height: 45px;
    display: block;
    margin: 40px auto 0;
  }

  .slogan {
    margin: 15px 0 25px;
    font-family: 'Poppins';
    font-weight: 600;
    color: $primary500;
    text-align: center;
  }

  .awardContainer {
    border-radius: 24px;
    background-color: $primary50;
    padding: 20px;
  }

  .awards {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    column-gap: 50px;
    row-gap: 10px;
    flex-wrap: wrap;

    .award {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .decLine {
    width: 25px;
    height: 1px;
    background-color: $primary500;
    margin: 40px auto 10px;
    text-align: center;
  }

  .signupButton {
    min-width: 180px;
    z-index: 100;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    
    .signupBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      
    }
  }
  .signupButton.disabled {
    background-color: $gray700 !important;
    color: $white;
  }

  .dot {
    display: inline-block;
    margin: 0 5px;
  }

  .marginBottom10 {
    margin-bottom: 10px;
  }

}

.topDesc {
  margin-top: 5px;
  padding: 2px;
}
