@import 'src/utils/constants.scss';

.container {
    position: relative;
    height: min-content;
    width: 100%;
    margin-bottom: 30px;
  

    .fullWidth {
      width: 100%;
    }
  
    .error {
      font-size: 12px;
      color: $red500;
      position: absolute;
      margin: 2px 4px 4px;
      top: 100%;
    }

    .info {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      position: absolute;
      margin: 4px;
      top: 100%;
    }
  }
  