@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.formPage {
  .mainContainer {
    @include mainCenterContainer2;
    margin-top: 0;
  }

  .bottomContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;


    @include sm {
      margin-top: 0px;
    }

    .bottomText {
      margin: 20px 0;
      text-align: center;
      line-height: 1.5;
      font-size: 16px;

      @include sm {
        font-size: 14px;
      }

      h5 {
        font-size: 16px;

        @include sm {
          font-size: 14px;
        }
      }

      .text {
        color: $gray700;
      }

      .link {
        color: $gray700;
        font-weight: 500;
        text-decoration: underline;
      }

      .add {
        color: $primary500;
        font-weight: 500;

        cursor: pointer;
      }
    }
  }

  .nextButton {
    width: 100%;
    max-width: 335px;
    align-self: center;
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .flexSpaceBetween {
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }

  .flexCol {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .step {
    width: 100%;
    max-width: 580px;
    align-self: center;
    padding: 20px;

    .linkTitle {
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;

      text-align: center;
      color: $gray700;

      margin-top: 20px;
      padding-top: 50px;
    }

    .block {
      display: flex;
      flex-direction: column;
      margin: 54px 0px 30px 0px;

      .title {
        font-weight: 600;
        font-size: 24px;
        color: $black700;
        text-align: start;
      }

      .desc {
        font-weight: 400;
        font-size: 16px;
        color: $black700;
        line-height: 26px;
        text-align: start;
        margin-top: 10px;
      }
    }

    .input {
      padding-bottom: 20px;
    }

    .desc_input {
      min-height: 300px;
    }

    .imgContainer {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .mainImg {
      width: 280px;
      margin: 20px auto 0px;

      @include sm {
        width: 240px;
      }
    }
  }

  .tag {
    position: absolute;
    top: -10px;
    left: 20px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: $primary500;
  }

  .exp_info {
    font-size: 11px;
    line-height: 14px;
    color: #696B74;
  }

  .exp_length {
    font-size: 14px;
    line-height: 22px;
    color: #A6A6A6;
  }

  .alert {
    color: $red500;
  }

  .newBtn {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: $primary500;
    cursor: pointer;

    margin-top: 10px;
  }

  .serviceName {
    color: $primary500;
  }
}