@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.eventPage {
  @include page;
  align-items: center;

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    @media(max-width: 1280px) {
      width: 100%;
    }

    @include tn {
      align-items: flex-start;
    }

    margin-bottom: 20px;
  }

  .tabLabelSticky {
    position: sticky;
    top: 135px;
    z-index: 190;
    background-color: $white;
    width: 100%;
    justify-content: center;
    padding: 10px 0;
    transition: 0.3s;
    &::before {
      content: '';
      height: 10px;
      background-color: $white;
      width: calc(100vw - 20px);
      position: absolute;
      top: -5px;

      @include sm {
        left: 0;
      }
    }
    @media(max-width: 767px) {
      margin-left: 0px;
      width: 100%;
      top: 130px;
    }
  }

  .tabLabelStickyUp {
    top : 80px
  }  

  .eventTabs {
    @include listTabs;
  }

  .topArea {
    @include listPageTopArea;
    background-color: $primary500;
  }

  @include stickyFilterArea();

  .list {
    @include gridList(50px, 30px) {
      margin-bottom: 30px;

      @media(max-width: 1310px) {
        column-gap: 50px;
      }

      @include sm {
        column-gap: 30px;
      }

      @include tn {
        row-gap: 40px;
        column-gap: 10px;
      }
    };

  }
}

.green {
  color: $secondary;
}

.filterModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  @include sm {
    width: 100%;
  }

  .top {
    margin-bottom: 20px;
    justify-content: center;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: $black500;
    text-align: center;
    margin-bottom: 2px;
    margin-left: 10px;
  }
  .reset {
    color: $primary500;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @include sm {
      font-size: 14px;
    }
  }

  .demandFilter {
    gap: 10px;
    margin-bottom: 20px;

    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    color: $gray700;
    width: 70px;
  }

  .resourceTypes {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .resourceType {
    border-radius: 40px;
    background-color: $white;
    border: 1px solid $gray600;
    border-radius: 40px;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  .resourceType.selected {
    background-color: $primary500;
    border-color: $primary500;
    color: $white;
  }

  .buttons {
    margin-top: 30px;
    justify-content: space-between;

    .resetBtn {
      width: 150px;
      height: 60px;
      font-weight: 500;
      font-size: 18px;
      color: $gray700;

      @include sm {
        width: fit-content;
        padding: 0 15px;
      }
    }

    .applyBtn {
      width: 150px;
      height: 60px;

      @include sm {
        width: fit-content;
        padding: 0 20px;
      }
    }
  }
}

.flexRow {
  display: flex;
  align-items: center;
}

.center {
  margin: 0 auto;
}

.emptyContainer {
  height: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;

  @include sm {
    max-height: 60vh;
  }

  .emptyMessage {
    text-align: center;
  
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 10px;
  
      @include sm {
        font-size: 16px;
      }
    }
  
    .text {
      font-size: 18px;
      line-height: 160%;
  
      @include sm {
        font-size: 14px;
      }
    }
  }
}