@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


@mixin flex-column {
    display: flex;
    flex-direction: column;
}


.card {
    @include flex-column;
    position: relative;
    background-color: $white;
    box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
    border-radius: 16px;
    padding: 15px 20px;
    cursor: pointer;

    @include flex-column;
    width: 335px;
    min-height: 250px;
    @include tn {
        width: 280px;
    }

    h5 {
        font-size: 14px;
        color: $black700;

        @include sm {
            font-size: 12px;
        }
    }

    .resourceCard {
        @include flex-column;
        flex-grow: 1;
        position: relative;

    }

    .topArea {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 8px;
        min-height: 52px;

        .avatar {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-size: cover;
            background-position: center;
        }

        .bluetick {
            position: absolute;
            margin-left: 35px;
            margin-top: 25px;
        }

        .tag {
            border-radius: 40px;
            background-color: $primary500;
            width: fit-content;
            word-wrap: nowrap;
            white-space: nowrap;
          
            padding: 0px 10px;
            color: $white;
            height: 28px;
            line-height: 28px;
            font-weight: 700;
            font-size: 12px;
            margin-left: 10px;

            .mark {
                display: inline-block;
                margin-right: 3px;
            }
        }

        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 160%;
            color: $black500;

        }

    }

    .middleArea {
        @include flex-column; 
        flex-grow: 1;

        .title {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            font-weight: 700;
            font-size: 16px;
            line-height: 25px;
            color: $black700;
        }

        .subtitle {
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            color: $black700;
        }

        .desc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;

            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $gray700;
            margin: 10px 0px;
        }
    }

    .middleSpaceArea {
        flex-grow: 1;

        @include flex-column; 

        .desc {
            height: 44px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;

            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $gray700;
        }

        .photos {
            margin: 10px 0px;
            display: flex;
            align-self: center;
            width: 100%;
            height: 150px;
            border-radius: 16px;
            overflow: hidden;
        }
    }

    .infoContainer {
        // position: absolute;
        // bottom: 0px;

        .info {
            display: flex;
            align-items: center;
        }

        .title {
            white-space: nowrap;
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: $gray700;
            margin: 0;
            margin-right: 15px;
        }

        .content {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: $black700;
        }
    }

    .spaceInfo {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
        gap: 17px;
        padding: 0 15px;

        @include tn {
            gap: 12px;
            padding: 0 3px;
        }

        .info {
            display: flex;
            flex-grow: 1;
            flex-basis: calc(100% / 3);
            align-items: center;
            justify-content: space-between;
            gap: 8px;
        }

        .separator {
            width: 1px;
            height: 12px;
            background-color: #696B74;
        }

        .title {
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: $gray700;
            margin: 0;
            flex-shrink: 0;
        }

        .content {
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: $black700;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
        }

    }

    .editedBlock {
        @include flex-column;

        .separator {
            width: 100%;
            border: 1.5px dashed #696B74;
            margin: 20px 0px;

        }

        .block {
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .separator {
                width: 1px;
                height: 12px;
                background-color: #696B74;
            }

            .title {
                font-weight: 500;
                font-size: 14px;
                line-height: 160%;
                color: $gray700;
                margin: 0;
                margin-right: 15px;
            }

            .content {
                display: flex;
                flex-direction: row;
                align-items: center;

                font-weight: 500;
                font-size: 14px;
                line-height: 160%;
                color: $black700;
            }

            .btn {
                width: 40px;
                height: 24px;
                padding: 0px 8px;
                justify-content: center;
                align-items: center;
                border: 1px solid #414EE1;
                border-radius: 30px;

                color: #414EE1;
                text-align: center;
            }

        }

    }
}

.spaceCard {
    height: 328px;
}

.isEdited {
    height: 300px;
}

.isEditedSpace {
    height: 392px;
}