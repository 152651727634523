@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.demandCard {
  background-color: $white;
  box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
  border-radius: 16px;
  padding: 15px 20px;
  cursor: pointer;
  height: 296px;
  // display: grid;
  // flex-direction: column;

  .topArea {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .demandIcon.service {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 130%;
    border-radius: 100%;
    display: inline-block;
  }
  
  .demandIcon.other {
    background-color: $primary50;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .x {
    color: $gray700;
    font-weight: 700;
    font-size: 12px;
    display: inline-block;
    margin: 0 2px;
    transform: translateY(-3px);
  }

  .count {
    color: $primary500;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    transform: translateY(-3px);
  }

  .tag {
    border-radius: 40px;
    background-color: $primary500;
    width: fit-content;
    padding: 0 10px;
    color: $white;
    height: 28px;
    line-height: 28px;
    font-weight: 700;
    font-size: 12px;

    .mark {
      display: inline-block;
      margin-right: 3px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: $black700;
    margin-bottom: 10px;
    flex-shrink: 0;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: $gray700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .infoContainer {
    margin: 5px 0;
    .info {
      display: flex;
      align-items: center;
    }
    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: $gray700;
      margin: 0;
      margin-right: 15px;
    }
    .content {
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
      color: $black700;
    }
  }

  .joinBtn {
    font-weight: 500;
    font-size: 16px;
    color: $gray700;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: 5px;
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .check {
    width: 16px;
  }

  .numbers {
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: $gray700;
    margin-left: 3px;
  }

  .purple {
    color: $primary500;
  }

  .slash {
    display: inline-block;
    margin: 0 3px;
  }
  
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.clamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: $gray700;
  height: 48px;
}
