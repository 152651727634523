@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.flexRow {
  display: flex;
  flex-direction: row;
}

.bottomCaption {
  margin: 5px;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .desc {
    font-family: Noto Sans TC;
    font-size: 11px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: $gray700;
    margin-top: 3px;
  }

  .limitContainer {
    font-family: Noto Sans TC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: $gray650;
  }

  .error {
    color: $red500;
  }
}