@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.certItem {
  display: flex;
  align-items: center;
  padding: 10px;

  .thumbnail {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;

    flex-shrink: 0;

  }

  .thumbnailLoading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .certContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    word-break: break-all;

    .name {
      font-size: 14px;
      font-weight: 700;
      color: $black400;
    }

    .size {
      color: $black200;
      font-size: 12px;
      font-weight: 400;
      margin-top: 5px;
    }
  }

  .deleteIcon {
    background-size: contain !important;
    flex-shrink: 0;
  }
}

.iconUneditable {
  transform: translateX(9px);

  flex-shrink: 0;
}
