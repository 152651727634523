@import 'src/utils/constants.scss';
@import 'src/utils/common.scss';
@import 'src/utils/mediaQuery.scss';

.eventsContainer {
  width: 100%;
  margin-top: 30px;
  min-height: 142px;
  .banner {
    width: 133px;
    height: 70px;
    border-radius: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    flex-shrink: 0;

    @include sm {
      width: 50px;
      height: 50px;
    }
  }

  .card {
    display: flex;
    gap: 10px;
    background: $black700;
    box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
    border-radius: 16px;
    padding: 20px;
  }

  .title {
    flex-grow: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $white;
    font-weight: 700;
    font-size: 18px;
    line-height: 170%;

    @include sm {
      font-size: 16px;
      line-height: 160%;
    }
  }

  .blueTick {
    width: 24px;
    height: 24px;
    margin-top: 3px;
    margin-left: 10px;
  }

  .eventsSwiper {
    padding-bottom: 20px;
  }
}