@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.filterModal {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;

    .top {
        justify-content: center;
    }

    .title {
        font-weight: 500;
        font-size: 16px;
        color: $black500;
        text-align: center;
        margin-bottom: 2px;
        margin-left: 10px;
    }

    .reset {
        color: $primary500;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;

        @include sm {
            font-size: 14px;
        }
    }

    .inputs {
        margin: 30px 0px;
    }
}

.flexRow {
    display: flex;
    align-items: center;
}

.buttons {
    justify-content: space-between;

    .resetBtn {
        width: 150px;
        height: 60px;
        font-weight: 500;
        font-size: 18px;
        color: $gray700;

        @include sm {
            width: fit-content;
            padding: 0 15px;
        }
    }

    .applyBtn {
        width: 150px;
        height: 60px;

        @include sm {
            width: fit-content;
            padding: 0 20px;
        }
    }
}

.rangeIcon {
    width: 30px;
    height: 1px;
    background: #0C0E17;

    margin: 0px 12px;
}