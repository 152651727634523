@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.content {
  display: flex;
  padding: 60px 30px;
  gap: 50px;
  overflow: hidden;

  @include sm {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 30px;
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }  

  .title {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 39px;
    color: $black700;

    @include sm {
      font-size: 24px;
    }
  }

  .subtitle {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    text-align: left;

    @include sm {
      font-size: 16px;
    }
  }
}

.infos {
  display: flex;
  flex-direction: column;

  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;

  .label {
    font-weight: 700;
  }

}

.marginRight3 {
  display: inline-block;
  margin-right: 3px;
}

.primary {
  color: $primary500;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 50px;
  position: relative;

  @include sm {
    padding-top: 0px;
    align-self: center;
  }

  .img {
    width: 285px;

    @include sm {
      width: 240px;
    }
  }
  
  .button {
    width: 280px;

    @include sm {
      position: fixed;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
