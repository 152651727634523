@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.uploadedItemsContainer {
  border: 1px solid $primary500;
  width: 100%;
  border-radius: 22px;
  padding: 15px;
  position: relative;

  .title {
    font-size: 12px;
    color: $primary500;
    background-color: #fff;
    width: fit-content;
    padding: 2px;
    position: absolute;
    top: -8px;
    left: 12px;
  }
}

.uploadButton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 12px;
  cursor: pointer;
  transition: .3s;

  // width: 100%;
  // width: 335px;
  // max-width: 335px;
  height: 66px;

  border: 1px solid $gray600;
  border-radius: 20px;

  color: $gray700;
  font-size: 16px;

  &:hover {
    border-color: $primary500;
  }

  @include tn {
    width: 100%;
  }
}

.uploadOtherButton {
  border: none;

  &:hover {
    color: $primary500;
  }
}

.requiredMark {
  &::before {
    color: $red500;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    margin-right: 2px;
    content: '*';
    display: inline-block;
  }
}

.iconUneditable {
  transform: translateX(9px);

  flex-shrink: 0;
}


.errorBorder {
  border: 1px solid $red500;
}

.error {
  font-size: 12px;
  color: $red500;
  position: absolute;
  margin: 2px 4px 4px -8px;
  top: 100%;
}
