@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@mixin mq {
  @media(max-width: 1024px) {
    @content;
  }
}

.recommendedResourcesPage {
  align-items: center;
  background-color: $black700;

  .top {
    width: 100%;
    margin-bottom: 30px;
    background-color: $secondary;
    border-radius: 0 0 24px 24px;
  }

  .mainContainer {
    @include mainCenterContainer2;
    margin-top: 0;
    align-items: center;
    margin-bottom: 60px;
    margin-right: 0px;
    flex-grow: 1;
    width: auto;
    overflow: hidden;
    margin-left: 197px;
    width: calc(100% - 197px);
    @media(max-width: 1440px) {
      margin-left: calc((100% - 1100px) / 2);
      width: calc(100% - ((100% - 1100px) / 2));
    }
    @media(max-width: 1199px) {
      width: calc(100% - 100px);
      margin-left: 0;
    }
    @media(max-width: 767px) {
      width: calc(100% - 30px);
    }
  }


  .flexRow {
    display: flex;
    align-items: center;
  }

  .listContainer {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-bottom: 10px;
    min-height: 300px;

    .card {
      flex-shrink: 0;
    }

    @include sm {
      flex-direction: column;
      align-items: center;
      display: flex;
      row-gap: 30px;
      column-gap: 20px;
      width: 100%;
    }
  }

  .listContainerFlex {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
    padding-bottom: 10px;
    min-height: 300px;
    display: flex;
    row-gap: 30px;
    column-gap: 20px;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;

    .card {
      flex-shrink: 0;
    }

    @include sm {
      align-items: center;
    }
    @media(max-width: 480px) {
      flex-direction: column;
    }
    @media(max-width: 380px) {
      .card {
        width: 100%;
      }
    }
  }

  .pageTitle {
    margin-top: -10px;
    @include sm {
      margin-top: -20px;
    }
  }

  .titleGroup {
    font-weight: 700;
    font-size: 28px;
    line-height: 130%;
    color: $black700;
    margin-left: 20px;

    @include sm {
      font-size: 18px;
      margin-left: 10px;
    }
  }

  .painter {
    width: 110px;
    margin-bottom: -10px;
    margin-right: 10px;

    @include sm {
      width: 88px;
      margin-top: -15px;
    }
  }

  .subtitle {
    color: $white;
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    text-align: center;

    @include sm {
      font-size: 16px;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    width: 100%;

    .resourceTitle {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      margin-left: 50px;

      .icon {
        align-self: center;
      }

      @include sm {
        margin-left: 0px;
        align-self: center;
      }
    }
  }

}

.center {
  margin: 0 auto;
  justify-content: center;
}

.emptyMessageContainer {
  flex-grow: 1;
  color: $white;
  display: grid;
  place-content: center;
}