@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.belongCard {
  display: flex;
  box-shadow: 0px 4px 50px 0px #B6C5CD4D;
  background: #FFFFFF;
  padding: 10px;
  border-radius: 10px;
  width: 100%;

  .avatar {
    height: auto;
  }

  .cardContent {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .name {
    //styleName: Noto Sans TC/16px/Bold;
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: $black500;
  }

  .period {
    color: #B6B7B9;
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    
    span {
      display: inline-block;
      margin-right: 3px;
    }
  }
}

.clickable {
  cursor: pointer;
}