@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

@mixin textBasics {
  font-family: Noto Sans TC;
  letter-spacing: 0em;
  text-align: left;
  color: $white;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.draftCard {
  box-shadow: 0px 4px 50px 0px #B6C5CD4D;
  background: $black700;
  width: 100%;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  cursor: pointer;
  gap: 12px;
  max-height: 91px;
}

.pic {
  width: 50px;
  height: 50px;
  background-color: $white;
  border-radius: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // gap: 12px;
  // padding: 5px 0;

  .title {
    @include textBasics;
    font-size: 16px;
    font-weight: 700;
    line-height: 25.6px;
  }

  .text {
    @include textBasics;
    font-size: 12px;
    font-weight: 700;
    line-height: 19.2px;
    word-break: break-all;
  }
}

.clickable {
  cursor: pointer;
}