@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@mixin flexCenter {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderHistoryPage {
  @include page;
  // align-items: center;

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;
    // border: 1px solid black;
    max-width: 900px;

    @include tn {
      align-items: flex-start;
    }

    margin-bottom: 20px;
  }
}

.tabContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  padding: 0 20px;
}

.list {
  width: 100%;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.emptyContainer {
  flex-direction: column;
  @include flexCenter;
  gap: 10px;
  padding-bottom: 20px;

  .illustration {
    width: 200px;
    margin-top: 20px;
  }

  .msg {
    //styleName: Noto Sans TC/24px/Bold;
    font-family: Noto Sans TC;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: $black700;
  }
}

.row {
  display: flex;
  align-items: center;
}

.flexCenter {
  @include flexCenter;
}

.introModal {
  @include modal;
}

.filterIconContainer {
  border: 1px solid $gray600;
  border-radius: 100%;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.invertIcon {
  filter: grayscale(1) invert(1);
  opacity: 0.5;
}

.filterBar {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.filterCount {
  position: absolute;
  font-size: 12px;
  color: $primary100;
  font-weight: 700;
  bottom: 5px;
  right: 4px;
}

@include filterModal;
  
.fullWidth {
  width: 100%;
}

.marginTop20 {
  margin-top: 20px;
}

.spaceBtw {
  justify-content: space-between;
}

.topAreaContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .days {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 3px;
    margin-bottom: 30px;
  }

  .text {
    //styleName: Noto Sans TC/18px/Medium;
    font-family: Noto Sans TC;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: $black900;
  }

  .bold {
    //styleName: Noto Sans TC/24px/Bold;
    font-family: Noto Sans TC;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
  }

  .marginLeft20 {
    margin-left: 20px;
  }
}

.section {
  .date {
    color: $gray500;
    //styleName: Noto Sans TC/14px/Medium;
    font-family: Noto Sans TC;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }

  .cardList {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

.promoAndButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  padding: 0 20px;

  @include tn {
    flex-direction: column;
  }
}

.promoText {
  line-height: 1.4;
}

.buyPlanButton {
  width: 180px;
  height: 40px;

  @include sm {
    width: 130px;
  }

  @include tn {
    width: 180px;
  }
}

