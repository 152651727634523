@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.demandDetailPage {
  .mainContainer {
    margin-top: 0;
    flex-grow: 1;
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .illustration {
    width: 45px;
    display: block;
    margin: 20px auto 0px;
  }

  .sectionTitles {
    margin: 15px 0 5px;
    font-family: 'Poppins';
    font-weight: 600;
    color: $primary500;
    text-align: center;
  }

  .tabsContainer {
    margin-top: 15px;
  }

  .demandsContainer {
    width: 100%;
    padding: 25px 0px 80px;
    background-color: $primary500;

    @include tn {
      padding: 25px 0px;
    }
  }

  .listContainer {
    margin: 0 auto;
    max-width: 1100px;
    margin-top: 5px;
    padding: 25px 15px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    row-gap: 25px;
    align-items: center;
    justify-items: center;

    @media(max-width: 1090px) {
      grid-template-columns: repeat(2, 1fr);
      max-width: 750px;
    }

    @media(max-width: 720px) {
      grid-template-columns: 1fr;
    } 

    @include tn {
      padding: 25px 0px;
    }

    .demandCard {
      width: 335px;

      @include tn {
        width: 300px;
      }
    }
  }

}


