@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.iconContainer {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.iconContainer.active {
    background-color: $primary50;
}

.iconContainer.done {
    background-color: $gray550;
}

.iconContainer.pending {
    background-color: none;

    .icon {
        filter: grayscale(1);
        opacity: 0.7;
    }
}

.iconContainer.disable {

    .icon {
        filter: grayscale(1);
        opacity: 0.7;
    }
    
}

.iconContainer.greenDot {
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        background-color: $secondary;
        border-radius: 100%;
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
}