@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@mixin mq {
  @media(max-width: 1024px) {
    @content;
  }
}

.eventDemandsPage {
  align-items: center;
  position: relative;

  .mainContainer {
    margin-top: 0;
    overflow-x: hidden;
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .illustration {
    width: 45px;
    display: block;
    margin: 10px auto 0px;
  }

  .sectionTitles {
    margin: 15px 0 5px;
    font-family: 'Poppins';
    font-weight: 600;
    color: $primary500;
    text-align: center;
  }

  .tabsContainer {
    margin-top: 15px;
  }

  .tabs {
    background-color: $white;
    position: sticky;
    padding-bottom: 15px;
    padding-top: 5px;
    margin-bottom: 10px;
    z-index: 200;
    top: map-get($breadCrumbH, 'global');

    @include sm {
      top: map-get($breadCrumbH, 'sm');
    }
  }

  .background {
    background-color: $primary500;
    width: 100vw;
  }

  .demandsContainer {
    max-width: 1100px;
    padding: 25px 15px 80px;
    margin: 0 auto;

    @include tn {
      padding: 25px 0px;
    }
  }

  .listContainer {
    margin-top: 5px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    row-gap: 25px;
    align-items: center;
    justify-items: center;
    margin-bottom: 60px;
    margin: 0 auto;
    
    @include sm {
      margin-bottom: 80px;
    }

    @media(max-width: 1090px) {
      grid-template-columns: repeat(2, 1fr);
      max-width: 750px;
    }

    @media(max-width: 720px) {
      grid-template-columns: 1fr;
    }

    .demandCard {
      width: 335px;

      @include tn {
        width: 300px;
      }
    }
  }

  .tips {
    color: $white;
    line-height: 1.6;
    padding: 0 20px;
    margin-bottom: 25px;

    .title {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .tip {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .doubleCheck {
      margin-top: 3px;
      margin-right: 5px;
    }
  }

  .recommendedResourcesPopup {
    background-color: $secondary;
    padding: 0 20px;
    box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
    border-radius: 24px 24px 6px 0px;
    position: fixed;
    bottom: 0;
    cursor: pointer;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: $black700;
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 650px;
    width: 100%;

    @include sm {
      gap: 10px;
    }
    @include tn {
      gap: 0px;
      padding: 0 10px;
    }

    .count {
      display: inline-block;
      margin: 0 3px;
    }

    .illustration {
      margin-top: -70px;
      margin-right: 30px;
      width: 100px;

      @include sm {
        margin-top: -60px;
        margin-right: 10px;
        width: 88px;
      }

      @include tn {
        margin-top: -40px;
        margin-left: -10px;
        width: 75px;
      }
    }

    .close {
      position: absolute;
      top: -10px;
      right: 20px;
    }

    .grow {
      flex-grow: 1;
    }
  }
}

.green {
  color: $secondary;
}

.flexRow {
  display: flex;
  align-items: center;
}

.center {
  margin: 0 auto;
}

.eventCard {
  border: 1px solid red;
  background-color: $black700;
  color: $white;
  padding: 20px;
  width: 200px;
}
