@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.page {
  align-items: center;

  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 60%;
    flex-grow: 1;

    @include lg {
      width: 70%;
    }

    @include md {
      width: 90%;
    }

    @include tn {
      width: 100%;
    }
  }

  .wrapper {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
    justify-content: center;

    @include sm {
      flex-direction: column;
      align-items: center;
    }
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .flexCol {
    display: flex;
    flex-direction: column;
    width: 350px;

    @include sm {
      width: 335px;
    }

    @include tn {
      width: 100%;
    }
  }

  .flexCol1 {
    margin-right: 50px;

    @include sm {
      margin-right: 0px;
    }
  }

  .avatarContainer {
    position: relative;
    width: fit-content;
    margin-bottom: 20px;
  }

  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;

    @include sm {
      width: 90px;
      height: 90px;
    }
  }

  .avatarGuideline {
    line-height: 160%;
    margin-bottom: 36px;
    color: #B2B3B9;
    font-size: 12px;

    @include sm {
      margin-bottom: 20px;
    }
  }

  .uploadButton {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .iconCamera {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .iconNotice {
    margin-left: 3px;
    transition: .3s;
    flex-shrink: 0;
    background-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;

    @media(max-width: 500px) {
      background-size: 12px !important;
      width: 12px !important;
      height: 12px !important;
      font-size: 12px !important;
    }
  }

  .tab + .iconNotice {
    opacity: .3;
  }

  .profileSubtitle {
    font-weight: 700;
    line-height: 160%;
    color: $gray700;
    margin-bottom: 20px;
    text-align: center;
  }

  .marginBottom {
    margin-bottom: 25px;
  }

}

.noticeTooltip {
  border-radius: 20px;
}

.resetPwd {
  display: flex;
  flex-direction: column;
  align-items: center;

  .resetTitle {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 25px;
  
    @include sm {
      font-size: 24px;
    }
  }
  
  .marginBottom {
    margin-bottom: 20px;
  }
}

.errorMessage {
  padding-top: 1px;
  font-size: 12px;
  height: 30px;
  color: $red500;
  width: 100%;
  max-width: 335px;
}

.errorMessage.mottoError {
  position: absolute;
  height: 0px;
}

.saveDisable {
  cursor: not-allowed !important;
  opacity: .7;
}

.phoneArea {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  max-width: 350px;

  @include sm {
    max-width: 335px;
  }

  .countryCode {
    min-width: 122px;
    margin-right: 3px;
  }

  .phoneInput {
    flex-grow: 1;
    margin-left: 0px !important;
  }
}

.phoneArea.orgContactPhone {
  margin-top: -3px;
}

.phoneArea.personal {
  margin-top: 0px;
}

.sendAuthButton {
  min-width: 180px;
  width: 100%;
  max-width: 335px;

  @include sm {
    width: 180px;
  }
}

.error {
  color: $red500;
}

.saveArea {
  display: flex;
  align-items: center;
  gap: 3px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.marginTop30 {
  margin-top: 30px;
}

.tabsWrapper {
  margin-left: 0px;

  .privateTabText {
    @media(max-width: 322px) {
      min-width: 71px;
      margin: 0px 4px;
    }
  }
}

.tabsWrapper > div {
  @media(max-width: 500px) {
    flex: 1;
    margin: 0px;
    justify-content: center;
  }
}

.addBelongBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 100%;
  height: 106px;
  border: 1px dashed $primary100;
  border-radius: 16px ;
  
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: $primary500;

  margin-bottom: 30px;
}

.addBelongBtn:hover {
  opacity: 0.6;
}

.emptyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;

  .emptyMessage {
    text-align: center;
  
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 10px;
  
      @include sm {
        font-size: 16px;
      }
    }
  
    .text {
      font-size: 18px;
      line-height: 160%;
  
      @include sm {
        font-size: 14px;
      }
    }
  }
}

.belongModal {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include sm {
    padding: 0px;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    color: $black500;
    text-align: center;
    margin-bottom: 20px;

    @include sm {
      font-size: 16px;
    }
  }

  .addBelongBtnInModal {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
}

.optionAvatar {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.userOption {
  display: flex;
  align-items: center;
  gap: 10px;

  .label {
    font-family: Noto Sans TC;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: $black900;
  }
}

.userOption.forDisplay {
  border: 1px solid #CFCFCF;
  border-radius: 16px;
  padding: 14px;
  cursor: pointer;

  &:hover {
    border-color: $primary500;
  }
}

.selectedUserContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  margin-bottom: 30px;
}

.belongsList {
  // margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.belongItemContainer {
  display: flex;
  gap: 12px;
  align-items: center;
}

.orgBelongButtons {
  display: flex;
  align-self: flex-end;

  .button {
    font-size: 14px;
    color: $gray700;
    width: 100px;
    height: 40px;
    margin-left: 10px;
  
    @include sm {
      font-size: 12px;
      width: 70px;
      height: 35px;
      margin-right: 5px;
    }
  }

  .buttonPrimary {
    color: $white;
  }
}

.orgBatchDeleteButton {
  width: 100px;
  font-size: 14px;
  color: $gray700;
  height: 40px;
  margin-left: 10px;
  align-self: flex-end;

  @include sm {
    font-size: 12px;
    height: 35px;
  }
}

.belongListWrapper {
  margin-bottom: 30px;
}
