@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

$marginTop: 0px;
$marginBottom: 0px;
$bottomBar: 90px;
$bottomBarSm: 80px;

$width: 138px;
$widthMd: 108px;

$scrollBarWidth: 10px;
$paddingRight: 20px;

$progressBar: 10.5px;

.menuWrapper {

    .menuContainer {
        height: calc(100vh - map-get($breadCrumbH, 'global') - $marginTop - $marginBottom - $bottomBar);
        padding-top: 30px;
        padding-bottom: 30px;
        padding-right: 20px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        position: sticky;
        transition: .3s;
        top: calc(map-get($breadCrumbH, 'global') + $marginTop);
        overflow: auto;

        @include md {
            padding: 30px 5px;
        }

        @include sm {
            top: calc(map-get($breadCrumbH, 'sm') + $marginTop);
            height: calc(100vh - map-get($breadCrumbH, 'sm') - $marginTop - $marginBottom - $bottomBarSm);
        }
    }

    .menuContainer::-webkit-scrollbar {
        width: $scrollBarWidth;
    }

    @include sm {
        display: none;
    }
}

.menuWrapper.lower {

    .menuContainer {
        top: calc(map-get($headerH, 'global') + map-get($breadCrumbH, 'global') + $marginTop);
        height: calc(100vh - map-get($headerH, 'global') - map-get($breadCrumbH, 'global') - $marginTop - $marginBottom - $bottomBar);

        @include sm {
            top: calc(map-get($headerH, 'sm') + map-get($breadCrumbH, 'sm') + $marginTop);
            height: calc(100vh - map-get($headerH, 'sm') - map-get($breadCrumbH, 'sm') - $marginTop - $marginBottom - $bottomBarSm);
        }
    }
}

.progressContainer {
    flex-shrink: 0;
    height: 220px;
    // border: 1px solid;
    position: relative;
    margin-bottom: 10px;

    @include md {
        height: 160px;
    }

    .texts {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-family: Noto Sans TC;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: $black900;
        margin-bottom: 10px;

        @include md {
            gap: 1px;
            font-size: 12px;
            margin-bottom: 5px;
        }
    }

    .number {
        font-family: Roboto;
        font-size: 28px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: right;
        color: $primary500;
        flex-shrink: 0;

        @include md {
            font-size: 20px;
        }

        .mark {
            font-size: 14px;
            display: inline-block;
            margin-left: 3px;

            @include md {
                margin-left: 0px;
                font-size: 12px;
            }
        }
    }

    .progressBarContainer {
        position: relative;
    }

    .progressBarCircle {
        position: relative;
        width: $width;
        height: calc($width / 2);
        border-radius: $width $width 0 0;
        background-color: $gray550;
        transition: .8s;

        @include md {
            width: $widthMd;
            height: calc($widthMd / 2);
            border-radius: $widthMd $widthMd 0 0;
        }
    }

    .progressBar {
        position: absolute;
        top: 0;
        left: 0;
        width: $width;
        height: calc($width / 2);
        border-radius: $width $width 0 0;
        background-color: $primary500;

        transform-origin: center bottom; /* 旋轉中心為圓心 */
        transition: .8s;

        @include md {
            width: $widthMd;
            height: calc($widthMd / 2);
            border-radius: $widthMd $widthMd 0 0;
        }
    }

    // ///////////////////////////////////////////

    .corner {
        display: block;
        position: absolute;
        z-index: 10;
        // top: 0;
        bottom: calc(($progressBar / 2 * -1) + 1px);
        left: 0px;
        width: $progressBar;
        height: calc($progressBar / 2);
        border-radius: 0 0 $progressBar $progressBar;
        background-color: $primary500;
        transition: .8s;
    }

    .corner.right {
        left: auto;
        right: 0px;
        background-color: $gray550;
    }

    .corner.done {
        animation: fillGreen 1.5s forwards;
    }

    .corner.green {
        background-color: $secondary !important;
    }

    .corner.innerCorner {
        background-color: $primary500;
        left: auto;
        right: 0px;
        z-index: 20;
    }

    // ///////////////////////////////////////////

    .number.done {
        color: $secondary;
    }

    .number.green {
        color: $secondary;
    }

    // ///////////////////////////////////////////

    .progressBar.done {
        animation: fillGreen 1.5s forwards;
    }

    .progressBar.green {
        background-color: $secondary;
    }

    // ///////////////////////////////////////////

    .circleCenter {
        $centerWidth: $width - $progressBar * 2;
        $centerWidthMd: $widthMd - $progressBar * 2;
        position: absolute;
        width: calc($centerWidth);
        height: calc($centerWidth / 2);
        border-radius: $centerWidth $centerWidth 0 0;
        background-color: $white;
        left: $progressBar;
        top: $progressBar;

        @include md {
            width: $centerWidthMd;
            height: calc($centerWidthMd / 2);
            border-radius: $centerWidthMd $centerWidthMd 0 0;
        }
    }

    .rect {
        width: 100%;
        height: 80px;
        position: absolute;
        background-color: $white;
    }
}


.progressImg {
    flex-shrink: 0;
    height: 138px;
    transition: .8s;
    position: absolute;
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);

    @include md {
        height: 110px;
        bottom: -15px;
    }
}

.menuItems {
    position: relative;
    z-index: 50;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

@keyframes fillGreen {
    0% {
        background-color: $gray550;
        opacity: 0;
    }
    100% {
        background-color: $secondary;
        opacity: 1;
    }
  }