.scrollToTopButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 200;

    img {
        width: 44px;
    }
}