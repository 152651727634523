@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.page {
  align-items: center;

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    
    @include tn {
      align-items: flex-start;
    }
    
    margin-bottom: 20px;
    flex-grow: 1;

  }

  .topArea {
    @include listPageTopArea;
  }

  @include stickyFilterArea($black700);

  .resourceTabs {
    @include listTabs;
    width: 100%;

    @include xs {
      margin-bottom: 30px;
    }
  }

  .list {
    @include gridList(40px, 40px);
    margin-bottom: 30px;
  }
}

.green {
  color: $secondary;
}

.filterModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  .top {
    justify-content: center;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: $black500;
    text-align: center;
    margin-bottom: 2px;
    margin-left: 10px;
  }
  .reset {
    color: $primary500;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @include sm {
      font-size: 14px;
    }
  }
  .inputs {
    display: grid;
    gap: 20px;

    margin: 30px 0px;
  }
}

.flexRow {
  display: flex;
  align-items: center;
}

.buttons {
  justify-content: space-between;

  .resetBtn {
    width: 150px;
    height: 60px;
    font-weight: 500;
    font-size: 18px;
    color: $gray700;

    @include sm {
      width: fit-content;
      padding: 0 15px;
    }
  }

  .applyBtn {
    width: 150px;
    height: 60px;

    @include sm {
      width: fit-content;
      padding: 0 20px;
    }
  }
}

.rangeIcon {
  width: 30px;
  height: 1px;
  background: #0C0E17;

  margin: 0px 12px;
}

.center {
  margin: 0 auto;
}


.emptyContainer {
  height: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  flex-grow: 1;

  @include sm {
    max-height: 60vh;
  }

  .emptyMessage {
    text-align: center;
  
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 10px;
  
      @include sm {
        font-size: 16px;
      }
    }
  
    .text {
      font-size: 18px;
      line-height: 160%;
  
      @include sm {
        font-size: 14px;
      }
    }
  }
}