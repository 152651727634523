$white: #FFFFFF;

$primary50: #E4E7FF;
$primary100: #B7BCEE;
$primary200: #989FEE;
$primary300: #7B85F0;
$primary400: #6470F1;
$primary500: #414EE1;
$primary700: #303999;


$secondary: #52E90B;
$green: #1CBE8E;

$gray50: #EAEAED;
$gray100: #F9F9F9;
$gray500: #9496A5;
$gray550: #F5F5F5;
$gray600: #CFCFCF;
$gray650: #A6A6A6;
$gray700: #696B74;

$black: #000000;
$black200: #8B8D97;
$black300: #343952;
$black400: #1E233A;
$black500: #171B2E;
$black600: #0F121D;
$black700: #0C0E17;
$black900: #050509;

$red50: #FDDDDD;
$red500: #F65353;

$yellow: #F5B100;

$headerH: (
  global: 55px,
  sm: 50px
);

$breadCrumbH: (
  global: 60px,
  sm: 50px
);

$resourceCardForSwiperWidth: (
  global: 360px,
  sm: 335px,
  xxs: 270px
);
