@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.signUpPage {
  .mainContainer {
    @include mainCenterContainer2;
    margin-top: 0;
    max-width: 800px;
    padding: 0 20px;
    padding-bottom: 20px;
    margin-bottom: 80px;
  }

  .flexRow {
    display: flex;
    align-items: center;
  }

  .flexCol {
    display: flex;
    flex-direction: column;
  }

  .avatarContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-shrink: 0;
    border: 1.5px solid $white;
    box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);

    @include sm {
      width: 90px;
      height: 90px;
    }
  }
  .avatar2 {
    z-index: -1;
    margin-left: -20px;
  }

  .name {
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    color: $black500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }

  .blueTick {
    width: 24px;
    height: 24px;
  }

  .pageTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: $black700;
  }

  .title {
    @include font-normal;
    color: $gray500;
    margin-top: 20px;
  }

  .text {
    @include font-normal;
    font-weight: 700;
  }

  .textNormal {
    @include font-normal;
  }

  .sectionTitle {
    @include font-subtitle;
    color: $gray700;
    text-align: center;
    margin: 20px 0;
  }

  .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .more {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $primary500;
    text-decoration: underline;
    cursor: pointer;
    text-align: end;
    margin-right: 5px;
  }

}

.ctaButton {
  padding: 0 30px;
  position: fixed;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
  z-index: 100;
}

.socialLink {
  color: $primary500;
  text-decoration: underline;
}
  