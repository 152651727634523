@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.reviewTextModal {
  width: 80% !important;
  max-width: 800px;

  @include sm {
    max-width: none;
    width: 100% !important;
  }
}

.reviewTextModalContent {

  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: $black900;
  padding: 20px 0px;
  word-break: break-all;

  .title {
    color: $primary500;
    font-size: 22px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
  }

  .subtitle {
    color: $primary500;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    margin: 10px 0;
  }

  .stepSection {
    padding: 15px 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: .3s;
    margin: 10px 0px;

    &:hover {
      background-color: $gray550;
    }

    @include sm {
      padding: 15px 0;
    }
  }

  .basicSection {
    padding: 15px 0px 20px;
    border-radius: 20px;
    transition: .3s;
    margin: 10px 0px;
    
    @include sm {
      padding: 15px 0;
    }
    
    .subSection {
      transition: .3s;
      border-radius: 20px;
      cursor: pointer;
      padding: 2px 10px;

      &:hover {
        background-color: $gray550;
      }

      @include sm {
        padding: 2px 0px;
      }
    }
  }

  .sectionTitle {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    color: $black900;
  }

  .content {
    .label {
      font-weight: 700;
    }
  }

}

.highlight {
  color: $primary500;
  font-weight: 700;
}

.gap {
  height: 20px;
}

.item {
  margin: 20px 0;
}
.buttons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;

  .backButton {
    width: 240px;
    border-color: $primary500;

    @include sm {
      width: 120px;
    }
  }

  .button {
    width: 240px;

    @include sm {
      width: 120px;
    }
  }
}