@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.card {
    width: fit-content;
    position: relative;
    margin: 0 auto;

    .photoContainer {
        width: 288px;
        aspect-ratio: 16 / 9;
        position: absolute;
        top: -20px;
        z-index: 1;
        overflow: hidden;
        border-radius: 16px;

        @include xxs {
            width: 250px;
        }
    }

    .photo {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .content {
        position: relative;
        height: 100%;
        margin-top: 20px;
        margin-left: 20px;
        background-color: $black700;
        padding: 160px 20px 20px;
        box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
        border-radius: 24px;
        width: 300px;
        height: 490px;
        display: flex;
        flex-direction: column;

        @include xs {
            width: 288px;
            height: 410px;
            padding: 150px 20px 20px;
        }

        @media(max-width: 479px) {
            padding: 130px 20px 20px;
        }

        @include xxs {
            width: 250px;
            padding: 130px 20px 13px;
        }


        .top {
            display: flex;
            justify-content: space-between;
            align-items: flex-start
        }

        .option {
            color: $primary500;
            font-weight: 500;
            font-size: 14px;
            background-color: $white;
            width: fit-content;
            padding: 0 10px;
            line-height: 28px;
            height: 28px;
            border-radius: 40px;

            @include sm {
                font-size: 12px;
            }
        }

        .title {
            @include font-subtitle;
            color: $white;
            margin-top: 0;
            flex-grow: 1;
            margin-bottom: 5px;

            @include xxs {
                line-height: 19px;
            }
        }
    }

    .desc {
        @include font-text;
        color: $white;
        flex-grow: 1;
        margin-bottom: 10px;
        overflow: hidden;

        @include sm {
            margin-bottom: 0;
        }

        @include xxs {
            line-height: 22px;
        }
    }

    .flexEnd {
        align-self: flex-end;
    }

}

.oneCardWrapper {
    border: 1px solid transparent;
    margin: 70px auto 0;

    .photoContainer {
        width: 576px;
    }

    .content {
        margin-top: 150px;
        padding: 170px 30px 30px;
        width: 576px;
    }

    @include sm {
        width: 90%;

        .photoContainer {
            width: calc(100% - 20px);
        }

        .content {
            margin-top: 25%;
            padding: 29% 30px 30px;
            width: calc(100% - 20px);
        }
    }
}