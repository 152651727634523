@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

h2 {
  font-size: 30px;
  font-weight: bold;
  color: $black700;

  @include sm {
    font-size: 24px;
  }
}

h3 {
  color: $black500;
  font-size: 20px;

  @include sm {
    font-size: 16px;
  }
}

h4 {
  color: $primary500;
  font-weight: 700;
}

h5 {
  color: $gray700;
  font-size: 18px;

  @include sm {
    font-size: 14px;
  }
}

p {
  color: $gray700;
  font-size: 12px;
}

@mixin page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

@mixin mainCenterContainer {
  margin: 0 auto;
  width: 60%;
  margin-top: 25px;
  margin-bottom: 10px;
  padding: 0px 20px;

  @include lg {
    width: 70%;
  }

  @include md {
    width: 90%;
  }

  @include tn {
    margin-top: 15px;
    width: 100%;
  }
}

@mixin mainCenterContainer2 {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 1280px;
  margin-top: 30px;
  margin-bottom: 10px;

  @media(max-width: 1280px) {
    width: 90%;
  }

  @include sm {
    width: 100%;
    min-height: calc(100% - map-get($headerH, 'sm'));
  }
}

@mixin mainCenterContainer3 {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 728px;
  margin-top: 30px;
  margin-bottom: 10px;

  @include sm {
    width: 100%;
    min-height: calc(100% - map-get($headerH, 'sm'));
  }
}

@mixin listPageTopArea ($backgroundColor: $black700) {
  background-color: $backgroundColor;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .topContainer {
    width: 660px;
    margin: 50px 0 20px;
    display: flex;
    flex-direction: column;
    padding: 0 5px;

    @include sm {
      width: 80%;
      margin: 40px 0 10px;
    }
  }

  .title {
    color: $white;
    font-family: 'Poppins';
    font-weight: 600;
    line-height: 130%;
    font-size: 32px;

    @include sm {
      font-size: 22px;
    }
  }

  .filterArea {
    display: flex;
    align-items: center;
    margin-top: 40px;
    width: 100%;

    .icon {
      width: 24px;
      margin: 0 10px;
      flex-grow: 1;
    }

    .filterIconContainer {
      position: relative;
      width: 60px;
      margin-left: 5px;
      display: flex;
      justify-content: center;
    }

    .filterCount {
      position: absolute;
      font-size: 12px;
      color: $primary100;
      font-weight: 700;
      bottom: 0px;
      left: 30px;
    }

    @include sm {
      margin-top: 20px;
    }
  }

  .searchInput {
    background-color: #fff;
    border-radius: 30px;
    height: 49px;
    max-width: 482px;
    width: 100%;

    @include sm {
      height: 44px;
    }
  }

}

@mixin stickyFilterArea ($backgroundColor: $primary500, $withBreadcrumb: false) {
  .filterArea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    position: sticky;
    top: 0px;
    z-index: 200;
    background-color: $backgroundColor;
    transition: .3s;

    @if $withBreadcrumb == true {
      top: map-get($breadCrumbH, 'global');

      @include sm {
        top: map-get($breadCrumbH, 'sm');
      }

    } @else {
      top: 0px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 5px;
      position: absolute;
      background-color: $backgroundColor;
      top: -2px;
    }

    .container {
      width: 660px;
      display: flex;
      align-items: center;
      
      @include sm {
        width: 80%;
      }

      @include xxs {
        width: 90%;
      }
    }

    .icon {
      width: 24px;
      margin: 0 10px;
      flex-grow: 1;
    }

    .filterIconContainer {
      position: relative;
      width: 60px;
      margin-left: 5px;
      display: flex;
      justify-content: center;
    }

    .filterCount {
      position: absolute;
      font-size: 12px;
      color: $primary100;
      font-weight: 700;
      bottom: 0px;
      left: 30px;
    }

    .searchInput {
      background-color: #fff;
      border-radius: 30px;
      height: 49px;
      max-width: 482px;
      width: 100%;

      @include sm {
        height: 44px;
      }
    }
  }

  .fakeFilterBottomBar {
    width: 100%;
    height: 10px;
    background-color: $backgroundColor;
  }

  .filterArea.lower {
    @if $withBreadcrumb == true {
      top: calc(#{map-get($headerH, 'global')} + #{map-get($breadCrumbH, 'global')});

      @include sm {
        top: calc(#{map-get($headerH, 'sm')} + #{map-get($breadCrumbH, 'sm')});
      }

    } @else {
      top: map-get($headerH, 'global');

      @include sm {
        top: map-get($headerH, 'sm');
      }
    }
  }

  @content;
}

@mixin managePageTopArea($backgroundColor: $black700) {
  @include listPageTopArea($backgroundColor);

  .topContainer {
    width: 90%;
    max-width: 920px;
  }

  .title {
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
      flex-direction: row;

      div:first-of-type {
        &::after {
          content: '·';
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
  }
}


@mixin font-title {
  font-weight: 700;
  font-size: 22px;
  line-height: 160%;
  color: $primary500;

  @include sm {
    font-size: 18px;
  }
}

@mixin font-subtitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  $color: $black900;
}

@mixin font-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  $color: $black700;

  @include sm {
    font-size: 14px;
  }
}

@mixin font-normal {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  $color: $black700;

  @include sm {
    font-size: 14px;
  }
}

@mixin font-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: $gray700;

  @include sm {
    font-size: 14px;
  }
}

@mixin listTabs {
  @include sm {
    justify-content: flex-start;
    padding-left: 9%;
    margin-left: 0;
  }

  @include xxs {
    padding-left: 4%;
  }
}

@mixin modal {
  display: flex;
  justify-content: center;
  flex-direction: column;


  .title {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
    color: $black500;
    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.5;
    max-width: 400px;
    text-align: center;
    color: $gray700;
  }

  .text {
    //styleName: Noto Sans TC/16px/Regular;
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: $gray700;
  }

  .textBold {
    font-family: Noto Sans TC;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: $gray700;
  }

  .illustration {
    width: 150px;
  }

  .button {
    width: 250px;
    margin-top: 20px;
    align-self: center;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    @include sm {
      .button {
        width: 120px;
        gap: 20px;
      }
    }

    .cancelButton {
      color: $gray700;
    }
  }
}

@mixin filterModal {
  .filterModal {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  
    @include sm {
      width: 100%;
    }
  
    .top {
      margin-bottom: 20px;
      justify-content: center;
    }
  
    .title {
      font-weight: 500;
      font-size: 16px;
      color: $black500;
      text-align: center;
      margin-bottom: 2px;
      margin-left: 10px;
    }
  
    .reset {
      color: $primary500;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
  
      @include sm {
        font-size: 14px;
      }
    }
  
    .subtitle {
      font-weight: 500;
      font-size: 14px;
      color: $gray700;
      width: 70px;
    }
  
    .options {
      display: flex;
      gap: 10px;
      width: 100%;
    }
  
    .option {
      border-radius: 40px;
      background-color: $white;
      border: 1px solid $gray600;
      border-radius: 40px;
      padding: 8px 12px;
      text-align: center;
      cursor: pointer;
      transition: .3s;
      color: $gray700;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
    }
  
    .option.selected {
      background-color: $primary500;
      border-color: $primary500;
      color: $white;
    }

    .rangeDash {
      width: 30px;
      height: 1px;
      background: #0C0E17;
  
      margin: 0px 12px;
    }

    .filterRow {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
  
    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .resetBtn {
        width: 150px;
        height: 60px;
        font-weight: 500;
        font-size: 18px;
        color: $gray700;
  
        @include sm {
          width: fit-content;
          padding: 0 15px;
        }
      }
  
      .applyBtn {
        width: 150px;
        height: 60px;
  
        @include sm {
          width: fit-content;
          padding: 0 20px;
        }
      }
    }
  }
}

@mixin gridList ($rowGap: 40px, $columnGap: 40px) {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-self: center;
  // min-height: 50vh;
  row-gap: $rowGap;
  column-gap: $columnGap;

  @media(max-width: 1310px) {
    grid-template-columns: repeat(2, 1fr);
  }


  @media(max-width: 900px) {
    grid-template-columns: 1fr;
  }

  @include sm {
    margin-top: 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media(max-width: 735px) {
    grid-template-columns: 1fr;
  }

  @content;
}

ul li.dottedBulletItem {
  display: flex;
  @include font-normal;

  &::before {
    content: '•';
    margin: 0 5px;
  }
}