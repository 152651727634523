@import 'src/utils/mediaQuery.scss';
@import 'src/utils/constants.scss';

.checkbox {
    margin-right: -10px;
    display: flex;
    align-items: center;

    @include sm {
        margin-right: -16px;
    }
}
