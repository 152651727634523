@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.myResourcePage {
  align-items: center;

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;
    max-width: 920px;

    @include tn {
      align-items: flex-start;
    }

    margin-bottom: 20px;
  }

  .topArea {
    @include managePageTopArea($black700);
    position: relative;
    padding-bottom: 50px;
  }

  .newBtn {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $white;
    cursor: pointer;
    margin-top: -4px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    max-width: 335px;
    margin: 0 auto;
    padding: 0px 20px;
    flex-grow: 1;
    justify-content: center;

    .placeholder1 {
      // text-align: center;
      color: #171B2E;
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 10px;
  
      @include sm {
        font-size: 16px;
      }
    }

    .placeholder2 {
      // text-align: center;
      color: #0C0E17;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 160%;
      @include sm {
        font-size: 14px;
      }
    }

    .addBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      width: 100%;
      height: 106px;
      border: 1px dashed $primary100;
      border-radius: 16px ;
      
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      color: $primary500;
    }

    .addBtn:hover {
      opacity: 0.6;
    }
  }

  .list {
    margin-bottom: 30px;

    @include gridList(40px, 40px);
  }
}

.green {
  color: $secondary;
}

.filterModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  @include sm {
    width: 100%;
  }

  .top {
    margin-bottom: 20px;
    justify-content: center;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    color: $black500;
    text-align: center;
    margin-bottom: 2px;
    margin-left: 10px;
  }

  .reset {
    color: $primary500;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @include sm {
      font-size: 14px;
    }
  }

  .demandFilter {
    gap: 10px;

    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    color: $gray700;
    width: 70px;
  }

  .resourceTypes {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .resourceType {
    border-radius: 40px;
    background-color: $white;
    border: 1px solid $gray600;
    border-radius: 40px;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  .resourceType.selected {
    background-color: $primary500;
    border-color: $primary500;
    color: $white;
  }

  .buttons {
    justify-content: space-between;

    .resetBtn {
      width: 150px;
      height: 60px;
      font-weight: 500;
      font-size: 18px;
      color: $gray700;

      @include sm {
        width: fit-content;
        padding: 0 15px;
      }
    }

    .applyBtn {
      width: 150px;
      height: 60px;

      @include sm {
        width: fit-content;
        padding: 0 20px;
      }
    }
  }
}






.container {

  .title {
    font-weight: 600;
    font-size: 24px;
    color: $black500;
    text-align: center;
    margin-bottom: 16px;
  }

  .desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: $gray700;
    text-align: center;
  }

  .illustration {
    width: 45px;
    height: 45px;
    margin: 5px auto;
    display: block;
  }

  .button {
    font-size: 14px;
    margin: 20px auto 0;
    padding: 20px 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .identityContainer {
    display: flex;
    justify-content: space-evenly;
    margin: 30px 0px;
  
  
    .identityCard {
      background-color: $white;
      box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
      border-radius: 16px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 88px;
      width: 92px;
    }
  
    .identityCardSelected {
      outline: 1.5px solid $primary500;
      position: relative;
      &:after {
        content: '';
        background-image: url("../../../assets/checkbox.svg");
        width: 25px;
        height: 25px;
        display: block;
        position: absolute;
        top: -10px;
        right: 0;
        z-index: 3;
      }
    }
  
    .identityImg {
      z-index: 2;  
    }
  
    .identityText {
      color: $black700;
      font-size: 16px;
      font-weight: 500;
    }
  }
  

}

.flexRow {
  display: flex;
  align-items: center;
}

.center {
  margin: 0 auto;
}

.resourceTabs {
  @include listTabs;

  @include sm {
    padding-left: 5%;
  }

  @include tn {
    padding-left: 4%;
  }
}


.emptyContainer {
  height: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;

  @include sm {
    max-height: 60vh;
  }

  .emptyMessage {
    text-align: center;
  
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 10px;
  
      @include sm {
        font-size: 16px;
      }
    }
  
    .text {
      font-size: 18px;
      line-height: 160%;
  
      @include sm {
        font-size: 14px;
      }
    }
  }

  .addBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    width: 100%;
    height: 106px;
    border: 1px dashed $primary100;
    border-radius: 16px ;
    
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: $primary500;

    margin-top: 30px;
  }

  .addBtn:hover {
    opacity: 0.6;
  }


  
}