@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.deleteItemButton {
    margin-left: 100%;
    transform: translateX(-100%);
  
    .flexRowCenter {
        display: flex;
        align-items: center;
        gap: 5px;
      }
  
    .borderlessBtnText {
      color: $red500;
      font-weight: 400;
    }
  }