@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.flexRow {
  display: flex;
  flex-direction: row;
}

.block {
  width: 100%;
  border: 1px solid #CFCFCF;
  border-radius: 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #696B74;
  font-family: 'Noto Sans TC';
  transition: .1s;

  .tag {
    position: absolute;
    color: rgba(0, 0, 0, 0.6);
    background-color: #fff;
    padding: 0 7px;
    top: -9px;
    left: 7px;
    z-index: 3;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: $primary500;
  }

  .searchText {
    width: 100%;
    border: none;
    line-height: 1.5;
    padding: 5px;
  }

  .btnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;


    .btn1 {
      margin: 15px 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;

      display: flex;
      align-items: center;
      color: #696B74;
      cursor: pointer;
    }

    .btn2 {
      margin: 15px 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;

      display: flex;
      align-items: center;
      color: $primary500;
      cursor: pointer;
    }

    .separator {
      width: 1px;
      height: 14px;
      background-color: #696B74;
    }

  }
}

.open {
  border: 1px solid $primary500;
}

.errorBorder {
  border: 1px solid $red500;
}

.requiredMark {
  &::before {
    color: $red500;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    margin-right: 2px;
    content: '*';
    display: inline-block;
  }
}

.labelRequiredMark {
  &::before {
    color: $red500;
    font-weight: 700;
    font-size: 12px;
    line-height: 160%;
    margin-right: 2px;
    content: '*';
    display: inline-block;
  }
}

.parentTag {
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  color: $black500;
  display: flex;
}

.childLabel {
  color: $black500;
}