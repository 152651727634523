@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.titlePurple {
  @include font-title;
}

.subtitle {
  @include font-subtitle;
}

.boldText {
  @include font-bold;
}

.normalText {
  @include font-normal;
}

.text {
  @include font-text;
  flex-shrink: 0;
  overflow-wrap: break-word;
}

.marginTop5 {
  margin-top: 5px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom40 {
  margin-bottom: 40px;
}

.marginRight5 {
  margin-right: 5px;
}

.eventDetailPage {
  .flexRow {
    display: flex;
    align-items: center
  }

  .contentBox {
    padding: 20px;
  }

  .contentBox1 {
    padding: 0 20px;
  }

  .mainContainer {
    @include mainCenterContainer3;
    margin-top: 0;
    margin-bottom: 80px;
    width: 100%;
  }

  .wrapper728 {
    width: 728px;
    margin-left: auto;
    margin-right: auto;

    @include sm {
      width: 100%;
    }
  }

  .banner {
    width: 100%;
    aspect-ratio: 16 / 9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0px 0px 0px 24px;
    margin-top: -1px;
  }

  .titleAndBlueTick {
    display: flex;
    margin-top: 10px;
  }

  .title {
    font-weight: 700;
    font-size: 29px;
    line-height: 130%;
    color: $black700;

    @include sm {
      font-size: 22px;
    }
  }

  .blueTick {
    width: 24px;
    height: 24px;
    margin-top: 3px;
    margin-left: 10px;
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-right: 10px;
  }

  .demandsContainer {
    margin-top: 25px;
    min-height: 250px;
    // position: relative;

    .background {
      height: 210px;
    }

    .demands {
      padding: 25px 20px;
    }

    .demandCard {
      width: 320px;
      @include sm {
        width: 288px;
      }
    }
  }

  .video {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    aspect-ratio: 16 / 9;
    border-radius: 16px;
    overflow: hidden;
  }

  .sectionTitles {
    margin: 15px 0;
    font-family: 'Poppins';
    font-weight: 600;
    color: $black700;

    span:first-of-type {
      color: $primary500;
    }
  }

  .collapse {
    margin-bottom: 10px;

    .panelHeader {
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: $black700;
      padding: 5px 0;
    }

    .expandIcons {
      margin-right: 10px;
      margin-top: 5px;
    }
    .expandIcon1 {
      background-color: $black700;
      width: 15px;
      height: 1.5px;
      border-radius: 10px;
    }
    .expandIcon2 {
      height: 15px;
      width: 1.5px;
      border-radius: 10px;
      transition: .3s;
      transform: rotate(90deg);
      margin-left: -8px;
      background-color: $primary500;
      z-index: 2;
    }
    .expandIcon2.active {
      transform: rotate(0deg);
      background-color: $black700;
    }

    .ans {
      @include font-text;
      margin-bottom: 20px;
    }
  }

  .hashtag {
    @include font-bold;
    color: $secondary;
    display: inline-block;
    margin-right: 3px;
  }

  .keywordWrapper {
    margin-right: 8px;
    cursor: pointer;
    flex-shrink: 0;
    word-break: break-all;
  }

  .sloganTitle {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: $black500;
    margin-top: 20px;

    @include sm {
      font-size: 12px;
    }
  }

  .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .clamp6 {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .more {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $primary500;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
  }

  .seeAll {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $primary500;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
    width: fit-content;
    margin: 0 20px 20px auto;
    position: relative;
    z-index: 10;
    transform: translateY(-30px);
  }

  .userArea {
    margin-top: 30px;
    width: fit-content;
    cursor: pointer;

    .blueTick {
      position: absolute;
      width: 15px;
      height: 15px;
      right: -4px;
      bottom: 0;
    }
  }

  .tabs {
    padding: 0 20px;
  }

  .illustration {
    width: 45px;
    display: block;
    margin: 0 auto;
  }

  .sepline {
    @include font-text;
    display: inline-block;
    margin: 0 10px;
  }

  .dot {
    @include font-text;
    display: inline-block;
    margin: 0 5px;
  }

  .x {
    color: $secondary;
    font-weight: 700;
    font-size: 14px;
    width: 20px;
    display: inline-block;
    text-align: center;

    @include sm {
      font-size: 12px;
    }
  }

  .partner {
    @include font-normal;
    color: $gray700;
    cursor: default;
  }

  .partner.clickable {
    color: $primary500;
    cursor: pointer;
  }

  .demandsTab {
    font-size: 14px;
  }

  .section {
    // position: relative;
  }

  .background {
    position: absolute;
    background-color: $primary500;
    width: 100%;
    height: 425px;
    left: 0;
    z-index: -1;

    @include sm {
      height: 300px;
    }
  }

  .sdgsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  .sdg {
    // width: 103px;
    height: 54px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    color: $white;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    padding: 15px 10px;

    @include sm {
      font-size: 14px;
    }
  }
  .sdg1 {
    background-color: $black600;
  }
  .sdg2 {
    background-color: $secondary;
    color: $black700;
  }
  .sdg3 {
    background-color: $primary500;
  }
  .sdgIcon2 {
    filter: invert(1);
  }

  .keyItemsCarouselWrapper {
    background-color: $primary500;
  }

  .featuresContainer {
    background-color: $primary500;
    padding: 20px;
    color: $white;
    margin-bottom: 40px;

    .sectionTitles {
      margin: 15px 0 5px;
      font-family: 'Poppins';
      font-weight: 600;
      color: $primary50;

      span:first-of-type {
        color: $white;
      }
    }
    .featureContainer {
      display: flex;
      margin: 15px 0px;
    }
    .title {
      @include font-subtitle;
      color: $white;
      margin-top: 0;
      margin-left: 10px;
    }
    .subtitle {
      @include font-normal;
      color: $white;
      margin-left: 10px;
    }
    .check {
      height: 26px;
    }
  }

  .ctaButton {
    width: 300px;
    border-color: $primary500;
    box-shadow: 0px 4px 16px 0px #00000014;

    @include sm {
      width: auto;
      min-width: 180px;
    }

    @include xs {
      min-width: none;
    }
  }

  .buttonText {
    word-wrap: break-word;
  }

  .ctaButton.disabled {
    background-color: $gray700 !important;
    color: $white;
  }

  .bottomButtons {
    margin: 0 auto;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    z-index: 100;
    display: flex;
    gap: 20px;
    justify-content: center;

    @include xs  {
      flex-direction: column;
    }
  }

}

.oneKeyItemsWrapper {
  padding-bottom: 60px;
  background-color: $primary500;

  .oneCardWrapper {
    margin: 0 auto;
    transform: translateY(50px);
  }
}

.carouselWrapper {
  padding-top: 30px;
}

.flexRow.alignTop {
  align-items: flex-start;
}

.paddingTop {
  padding-top: 45px;
}

.similarEvents {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;

  @include tn {
    padding: 10px;
  }

  .titleLine {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    color: $gray700;

    @include sm {
      font-size: 16px;
    }
  }

  .seeAllSimilar {
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: $primary500;
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  
    @include sm {
      font-size: 14px;
    }
  }

  .similarList {
    margin: 30px 10px 60px;
    padding-bottom: 20px;
    display: flex;
    gap: 20px;
    .similarEventCard {
      flex-shrink: 0;
    }

    @include sm {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .similarEventCard {
    cursor: pointer;
  }

}

.topBackground {
  background-color: $primary500;
  width: 100%;
  height: 200px;
  position: absolute;
  left: 0;
  z-index: -1;

  @include xxs {
    height: 100px;
  }
}

.section {
  min-height: 300px;
}

.regionHashTag {
  width: fit-content;
  cursor: pointer;
}

.flexWrap {
  flex-wrap: wrap;
}