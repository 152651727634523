@import 'src/utils/mediaQuery.scss';
@import 'src/utils/constants.scss';

.avatarContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    position: relative;

    .avatar {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: $white;
    }
    
    .avatar.notRound {
        border-radius: 0%;
    }
    
    .bluetick {
        position: absolute;
        right: -5px;
        bottom: 0;
    }
}

.clickable {
    cursor: pointer;
}