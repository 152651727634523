@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@include stickyFilterArea;
.filterArea {
  position: static;
  background-color: transparent;
  padding: 0px;
  width: 320px;

  &::before {
    display: none;
  }

  .container {
    width: 100%;
  }

  .filterIconContainer {
  }
  
  .invertIcon {
    filter: grayscale(1) invert(1);
    opacity: 0.5;
  }

  .sortIcon {
    margin-left: 20px;

    @include xs {
      margin-left: 10px;
    }
  }
}

.filterModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  @include sm {
    width: 100%;
  }

  .top {
    margin-bottom: 20px;
    justify-content: center;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: $black500;
    text-align: center;
    margin-bottom: 2px;
    margin-left: 10px;
  }
  .reset {
    color: $primary500;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @include sm {
      font-size: 14px;
    }
  }

  .demandFilter {
    gap: 10px;
    margin-bottom: 20px;

    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    color: $gray700;
    width: 70px;
  }

  .resourceTypes {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .resourceType {
    border-radius: 40px;
    background-color: $white;
    border: 1px solid $gray600;
    border-radius: 40px;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  .resourceType.selected {
    background-color: $primary500;
    border-color: $primary500;
    color: $white;
  }

  .buttons {
    margin-top: 30px;
    justify-content: space-between;

    .resetBtn {
      width: 150px;
      height: 60px;
      font-weight: 500;
      font-size: 18px;
      color: $gray700;

      @include sm {
        width: fit-content;
        padding: 0 15px;
      }
    }

    .applyBtn {
      width: 150px;
      height: 60px;

      @include sm {
        width: fit-content;
        padding: 0 20px;
      }
    }
  }
}

.flexRow {
  display: flex;
  align-items: center;
}