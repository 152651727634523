@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.proposalPage {
  @include page;
  // position: relative;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  overscroll-behavior-y: none;

  .mainContainer {
    // @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;

    height: calc(100vh - map-get($headerH, 'global') - map-get($breadCrumbH, 'global'));

    @include sm {
      height: calc(100vh - map-get($headerH, 'sm') - map-get($breadCrumbH, 'sm'));
    }
  }

  // .mainContainer.lower {
  //   height: calc(100vh - map-get($headerH, 'global') - map-get($breadCrumbH, 'global'));

  //   @include sm {
  //     height: calc(100vh - map-get($headerH, 'sm') - map-get($breadCrumbH, 'sm'));
  //   }
  // }

}

