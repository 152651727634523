@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.stepMainContent {
    flex: 1;
    position: relative;
    @include sm {
      width: 100%;
    }
}

.sticky {
    position: sticky;
    transition: .3s;
  
    @include sm {
      position: static;
    }
  }
  
  .withSticky {
    display: flex;
    flex-direction: column;
    position: relative;
    transition: .8s;
    min-height: 60px;
  
    @include sm {
      min-height: unset !important;
    }
  }