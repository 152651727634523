@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@mixin mq {
  @media(max-width: 1024px) {
    @content;
  }
}

.eventHostedPage {
  align-items: center;

  .addButton {
    font-weight: 700;
    font-size: 16px;
    color: $white;
  }

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;
    max-width: 920px;

    @include tn {
      align-items: flex-start;
    }

    margin-bottom: 30px;
  }

  .topArea {
    @include managePageTopArea($primary500);
  }

  @include stickyFilterArea($primary500, true) {
    .filterArea {
      .container {
        width: 90%;
        max-width: 920px;
      }
    }
  };

  .list {
    @include gridList(50px, 0px) {
      margin-top: 45px;

      @include lg {
        column-gap: 50px;
      }

      @include sm {
        margin-top: 30px;
        margin-bottom: 30px;
        column-gap: 30px;
      }

      @include tn {
        row-gap: 40px;
        column-gap: 10px;
      }

      @media(min-width: 1024px) {
        width: 100%;
        grid-template-columns: 1fr;
      }
    }
  }
}

.green {
  color: $secondary;
}

.filterModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  @include sm {
    width: 100%;
  }

  .top {
    margin-bottom: 20px;
    justify-content: center;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: $black500;
    text-align: center;
    margin-bottom: 2px;
    margin-left: 10px;
  }
  .reset {
    color: $primary500;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    @include sm {
      font-size: 14px;
    }
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    color: $gray700;
    flex-basis: 90px;
    flex-shrink: 0;

    @include sm {
      flex-basis: 80px;
    }
  }

  .filterOptions {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .filterOpt {
    border-radius: 40px;
    background-color: $white;
    border: 1px solid $gray600;
    border-radius: 40px;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    @include sm {
      padding: 10px 12px;
    }
  }

  .filterOpt.selected {
    background-color: $primary500;
    border-color: $primary500;
    color: $white;
  }

  .buttons {
    justify-content: space-between;

    .resetBtn {
      width: 150px;
      height: 60px;
      font-weight: 500;
      font-size: 18px;
      color: $gray700;

      @include sm {
        width: fit-content;
        padding: 0 15px;
      }
    }

    .applyBtn {
      width: 150px;
      height: 60px;

      @include sm {
        width: fit-content;
        padding: 0 20px;
      }
    }
  }

  .filterRow {
    @include sm {
      flex-direction: column;
      align-items: flex-start;
      
      .subtitle {
        flex-basis: 30px;
      }
    }
  }
}

.flexRow {
  display: flex;
  align-items: center;
}

.center {
  margin: 0 auto;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.emptyContainer {
  height: 100%;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;

  @include sm {
    max-height: 60vh;
  }

  .emptyMessage {
    text-align: center;
  
    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 160%;
      margin-bottom: 10px;
  
      @include sm {
        font-size: 16px;
      }
    }
  
    .text {
      font-size: 18px;
      line-height: 160%;
  
      @include sm {
        font-size: 14px;
      }
    }
  }
}

.eventTabs {
  @include listTabs;
  margin-bottom: 0px;
}

.clickable {
  cursor: pointer;
}

.secondRow {
  display: flex;
  align-items: center;

  .subtitle {
    @include sm {
      flex-basis: 30px;
    }
  }

  @include sm {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .filterOpt {
    flex-shrink: 0;
  }
}

.menuModal {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;

  @include sm {
    width: 100%;
  }

  .title {
    color: $black500;
    font-family: Noto Sans TC;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    margin: 10px 0;
  }

  .subtitle {
    font-weight: 500;
    font-size: 14px;
    color: $gray700;
    flex-basis: 90px;
    flex-shrink: 0;

    @include sm {
      flex-basis: 80px;
    }
  }

  .filterOptions {
    display: flex;
    gap: 10px;
    width: 100%;
  }
  .filterOpt {
    border-radius: 40px;
    background-color: $white;
    border: 1px solid $gray600;
    border-radius: 40px;
    padding: 8px 12px;
    text-align: center;
    cursor: pointer;
    transition: .3s;
    color: $gray700;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    @include sm {
      padding: 10px 12px;
    }
  }

  .filterOpt.selected {
    background-color: $primary500;
    border-color: $primary500;
    color: $white;
  }

  .viewAccessOptions {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .buttons {
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 10px;

    .noButton {
      width: 150px;
      height: 60px;
      font-weight: 500;
      font-size: 18px;
      color: $gray700;

      @include sm {
        width: 120px;
        padding: 0 15px;
      }

      @include tn {
        width: 80px;
      }
    }

    .confirmButton {
      width: 150px;
      height: 60px;

      @include sm {
        width: 150px;
        padding: 0 20px;
      }

      @include tn {
        width: 120px;
      }
    }

    .deactivateButton {
      @include tn {
        width: 150px;
      }
    }
  }
}

