@import '../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.breadcrumbContainer {
  width: 100%;
  height: map-get($breadCrumbH, 'global');
  background: $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0637839);
  backdrop-filter: blur(13.5914px);
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 400;
  transition: .3s;

  @include sm {
    height: map-get($breadCrumbH, 'sm');
  }

  .breadcrumb {
    width: 1100px;
    display: flex;
    align-items: center;
    color: $black500;

    @include lg {
      width: 100%;
      margin: 0 50px;
    }

    @include sm {
      margin: 0 15px;
    }

    .iconBack {
      cursor: pointer;
      padding: 3px;
      margin-left: -3px;
      margin-right: -3px;
    }

    .link {
      margin-left: 10px;
      color: $gray700;
      cursor: pointer;
    }

    .current {
      color: $black500;
      margin-left: 5px;
    }

    .slash {
      margin-left: 5px;
    }

    .routes {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    .right {
      display: flex;
      align-items: center;
      color: $gray700;
    }
  }
}

.breadcrumbContainer.purple {
  background: $primary500;
  .link {
    color: $primary100;
  }
  .slash {
    color: $primary100;
  }
  .current {
    color: $white;
  }
  .right {
    color: $primary100;
  }
}

.breadcrumbContainer.black {
  background: $black700;
  .link {
    color: $primary100;
  }
  .slash {
    color: $primary100;
  }
  .current {
    color: $white;
  }
  .right {
    color: $primary100;
  }
}

.breadcrumbContainer.green {
  background: $secondary;
  box-shadow: none;
  .link {
    color: $white;
  }
  .slash {
    color: $white;
  }
  .current {
    color: $white;
  }
  .right {
    color: $white;
  }
}

.breadcrumbContainer.lower {
  top: map-get($headerH, 'global');

  @include sm {
    top: map-get($headerH, 'sm');
  }
}