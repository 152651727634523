@import '../../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


.forgetPwdModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;


  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.5;
    max-width: 400px;
  }

  .marginBottom {
    margin-bottom: 15px;
  }

  .illustration {
    width: 150px;
  }

  .button {
    margin-top: 25px;
  }

  .inputContainer {
    width: 100%;
    max-width: 335px;
  }

  .errorMessage {
    padding-top: 2px;
    height: 30px;
    color: $red500;
    margin-bottom: 15px;
  }

  .timer {
    display: inline-block;
    width: 30px;
    color: $gray700;
    margin-left: 3px;
  }
}

.drawerContent {
  border-radius: 24px 24px 0 0;
}