@import '../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.customSelect.ant-select {
  width: inherit;
  
  .ant-select-selector {
    padding-left: 14px !important;
    border: 1px solid #CFCFCF;
    border-radius: 16px;

    .ant-select-selection-search {
      margin-left: 7px;
    }

    .ant-select-selection-placeholder {
      color: #727272;
    }
  }

  .ant-select-selection-search-input {
    margin-left: -4px !important;
  }
}

.selectLabel {
  font-size: 16px;
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  background-color: #fff;
  padding: 0 7px;
  top: -9px;
  left: -5px;
  z-index: 3;
  transform: scale(0.75);
}

.selectLabel.custom-select-required {
  transform: scale(0.75) translateX(-6px);
  // &::before {
  //   content: '*';
  //   display: inline-block;
  //   width: 8px;
  //   height: 5px;
  //   color: $red500;
  // }
}

.selectWrapper {
  position: relative;
  width: 100%;
}

.labelPurple {
  color: $primary500;
}

.customSelect.ant-select-multiple {
  .ant-select-selection-overflow-item {
    height: 28px;
    margin-top: 5px;
  }

  input {
    opacity: 1 !important;
    transform: translateY(-10px);
  }
}

.customSelect.sdgs.ant-select-multiple {

  .ant-select-selection-overflow-item {
    height: 28px;
    margin-top: 5px;
  }

  input {
    opacity: 0 !important;
    transform: translateY(-10px);
  }
}

.selectWrapper .multipleSelectTag.addComma {
  &::after {
    content: ',';
    font-family: Noto Sans TC;
    margin-left: 1px;
  }
}

.sdgsSelectMask {
  // background-color: $white;
  position: absolute;
  z-index: 2;
  pointer-events: none;
  width: 100%;
  padding: 8px 18px;
  padding-right: 11px;
  border-radius: 25px;
  // border: 1px solid #c4c4c4;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sdgsContainer {
    margin: 5px 0px;
  }

  .sdgsSelectType {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.6;
    color: $black500;
  }

  .multipleSelectTag {
    line-height: 1.6;
  }
}

.sdgsSelectMask.maskPurple {
  border-color: $primary500;
}

.sdgsSelect .ant-select-selector {
  height: 100%;
}

.requireMark {
  color: $red500;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  margin-right: 2px;
}

.custom-select-required-input .ant-select-selection-placeholder {
  &::before {
    color: $red500;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    margin-right: 2px;
    content: '*';
    display: inline-block;
  }
}