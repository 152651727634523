@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@mixin marginLeftBefore1100 {
  margin-left: calc((100% - 1100px) / 2);

  @media(max-width: 1100px) {
    margin-left: 0;
  }
}

.eventCarousel {
  position: relative;
  margin-top: 45px;

  @include sm {
    margin-top: 20px;
  }

  .dividerImgContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .titleArea {
    color: $black700;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 20px;

    img {
      width: 50px;
      margin-right: 5px;
    }

    .titlePurple {
      color: $primary500;
    }
  }

  .eventItem {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 590px;
    color: $white;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.38);

    @include sm {
      height: 450px;
    }
    

    .contentWrapper {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0 auto;
      max-width: 1100px;
      padding: 70px 20px 90px;

      @include sm {
        padding-top: 50px;
      }
    }

    .leftCol {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;

      @include sm {
        display: none;
      }

      .timeLabel {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 38px;
        color: $white;
        font-size: 18px;
        font-weight: 700;
        line-height: 120%; /* 21.6px */

        .countdown {
          justify-content: center;
          text-align: center;
          width: max-content;
        }
      
        .number {
          justify-content: flex-end;
          font-weight: 700;
          text-align: center;
          margin: 5px 0px;
          font-size: 22px;
          @include sm {
            font-size: 20px;
          }
        }
      
        .day {
          justify-content: flex-end;
          text-align: right;
        }
      
        .eventEnd {
          line-height: 1.5;
          margin-top: -3px;
          min-width: 24px;
        }
      }

      .demandsTypes {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .demandIcon {
        width: 27px;
        filter: drop-shadow( 1px 2px 3px rgba(0, 0, 0, .7));
      }

    }

    .rightCol {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      width: 500px;

      @include sm {
        align-items: center;
        flex-grow: 1;
      }

      .title {
        color: $white;
        font-family: Poppins;
        font-size: 48px;
        font-weight: 900;
        line-height: 140%; /* 67.2px */
        display: flex;
        align-items: center;

        @include sm {
          font-size: 36px;
          text-align: center;
          flex-grow: 1;
        }

        @media (max-width: 355px) {
          font-size: 32px;
        }
      }

      .hashtags {
        position: relative;
        min-height: 18px;
        overflow: hidden;
        font-weight: 500;
        color: $white;
        font-size: 16px;
        line-height: 120%;
      
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      
        .hashtag {
          margin-right: 10px;
        }

        @include sm {
          text-align: center;
          flex-grow: 1;
          max-height: 43px;
        }
      }

      .learnMoreBtn {
        width: 124px;
      }

      .buttonContainer {
        flex-grow: 1;
        max-height: 140px;

        @include sm {
          max-height: 110px;
        }
      }
    }

    .carouselIndex {
      color: $gray100;
      font-family: Poppins;
      font-size: 72px;
      font-weight: 900;
      line-height: 53px; /* 73.611% */
    }
  }

  .indexContainer {
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    max-width: 1100px;
    position: absolute;
    z-index: 90;
    height: 70px;
    transform: translateY(-70px);
    display: flex;
    padding-bottom: 0;
    align-items: flex-end;
    color: $gray100;
    font-family: Poppins;
    @include marginLeftBefore1100();

    .current {
      font-size: 72px;
      font-weight: 900;
      margin-right: 10px;
      flex-basis: 96px;
      margin-bottom: -12px;

      @include sm {
        font-size: 36px;
        margin-bottom: -6px;
        margin-right: 0px;
        flex-basis: 52px;
      }
    }

    .total {
      font-size: 24px;
      font-weight: 500;
      align-self: flex-start;

      @include sm {
        font-size: 16px;
        align-self: flex-end;
        margin-bottom: 10px;
      }
    }
  }

  .controls {
    background-color: $white;
    position: absolute;
    z-index: 100;
    bottom: 0px;
    right: 0;
    width: 60%;
    display: flex;
    align-items: center;
    padding: 20px;
    height: 70px;

    .icon {
      flex-shrink: 0;
    }

    .separatorContainer {
      display: flex;
      justify-content: center;
      width: 71px;
      flex-shrink: 0;

      @include tn {
        flex-shrink: 1;
      }
    }

    .nextBtn {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-shrink: 0;
    }

    .next {
      color: $black500;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 25.6px */
      margin-right: 10px;
    }

    .eventName {
      color: $black900;
      font-family: Noto Sans TC;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-left: 20px;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
      flex-shrink: 1;

      @include sm {
        display: none;
      }
    }
  }
}

.eventDetail {
  $intro-column-width: 350px;
  $column-gap: 28px;

  display: flex;
  gap: 28px;
  margin-top: 70px;

  @include sm {
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
  }

  .introCol {
    padding: 0 20px;
    width: $intro-column-width;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include marginLeftBefore1100();

    @include sm {
      width: 100%;
    }

    .title {
      color: $black500;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      line-height: 130%; /* 23.4px */
      margin-bottom: 20px;
      
  
      @include sm {
        display: none;
      }
    }
  
    .slogan {
      color: $primary500;
      font-size: 22px;
      font-weight: 700;
      line-height: 130%; /* 28.6px */
    }
  
    .desc {
      margin-top: 10px;
      min-height: 105px;

      @include sm {
        min-height: unset;
      }
    }

    .space {
      flex-grow: 1;

      @include sm {
        display: none;
      }
    }

    .bottomSpace {
      flex-grow: 1;
      max-height: 86px;
      flex-basis: 86px;

      @include sm {
        display: none;
      }
    }
  
    .user {
      display: flex;
      align-items: center;
      align-self: flex-start;
      margin-top: 10px;
      gap: 12px;
      cursor: pointer;

      .name {
        color: $black700;
        font-size: 16px;
        font-weight: 500;
        line-height: 160%; /* 25.6px */  
      }

      &:hover {
        .name {
          transition: 0.3s;
          opacity: 0.8;
        }
      }
    }
  }

  .demandsContainer {
    width: calc(100% - $intro-column-width - (100% - 1100px) / 2 - $column-gap);
    position: relative;

    .background {
      width: 100%;
      height: 233px;
      position: absolute;
      background-color: $primary500;
      top: 0;
      left: 0;
      border-radius: 20px 0px 0px 0px;
    }

    .demands {
      padding: 25px 20px;
    }

    .demandCard {
      width: 320px;
    }

    @media(max-width: 1100px) {
      width: calc(100% - $intro-column-width - $column-gap);
    }

    @include sm {
      width: 100%;

      .background {
        border-radius: 0px;
      }

      .demandCard {
        width: 288px;
      }
    }
  }
}

.separator {
  display: block;
  width: 1px;
  height: 36px;
  background-color: $gray500;
}

.more {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: $primary500;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
}

.link {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: $primary500;
  text-decoration: underline;
  cursor: pointer;
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text {
  color: $gray700;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}

.hide {
  display: none;
}

.textShadow {
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.38);
}