@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.successContainer {
  width: fit-content;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  padding-top: 60px;

  @include xxs {
    padding: 10px;
    padding-top: 25px;
  }

  .wrapper {
    width: 100%;
    min-height: 70vh;
    flex-grow: 1;
    max-width: 1100px;
    display: flex;
    flex-direction: column;

    @include sm {
      width: 100%;
    }
  }

  .titleGroup {
    width: fit-content;
    align-self: flex-start;
  }

  .title {
    margin-bottom: 15px;
    line-height: 1.7;
  }

  .subtitle {
    margin-bottom: 30px;
    line-height: 1.7;
    font-size: 18px;

    @include sm {
      font-size: 16px;
    }
  }

  .imgContainer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mainImg {
    width: 280px;
    margin: 50px auto;

    @include sm {
      width: 250px;
    }
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    max-width: 370px;

    @include sm {
      align-self: center;
      margin-top: 0px;

      .nextButton {
        width: 100%;
        max-width: 335px;
        align-self: center;
      }
    }
  }

  .bottomText {
    margin: 20px 0 0;
    text-align: center;
    line-height: 1.5;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include sm {
      font-size: 14px;
    }
  }
  
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: $gray700;
  }

  .link {
    font-weight: 700;
    line-height: 160%;
    color: $primary500;
  }

  

  .purple {
    color: $primary500;
  }
}
  