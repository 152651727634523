@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.titlePurple {
  @include font-title;
  overflow-wrap: break-word;

}

.subtitle {
  @include font-subtitle;
  overflow-wrap: break-word;

}

.boldText {
  @include font-bold;
  color: #0C0E17;
}

.normalText {
  @include font-normal;
  overflow-wrap: break-word;
  color: $black700;
}

.text {
  @include font-text;
  font-weight: 500;
  color: $gray500;
  overflow-wrap: break-word;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom40 {
  margin-bottom: 40px;
}

.separator {
  width: 1px;
  height: 12px;
  background-color: #696B74;
  margin: 0px 12px;
  align-self: center;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #696B74;
  margin-top: 97px;
  margin-bottom: 50px;
}


.detailPage {
  .flexRow {
    display: flex;
    align-items: center
  }

  .contentBox {
    padding: 20px;
  }

  .contentBox1 {
    padding: 0 20px;
  }

  .mainContainer {
    @include mainCenterContainer3;
    margin-top: 0;
    margin-bottom: 80px;
  }


  .banner {
    position: relative;
    width: 100% ;
    aspect-ratio: 16 / 9;

    // height: 345px;
    // background-repeat: no-repeat;
    // background-size: cover;
    border-radius: 0px 0px 0px 24px;
    margin-top: -1px;
    overflow: hidden;
    z-index: 0;

    .carouselWrapper {
      height: 100%;
      overflow: hidden;
    }

    .tag {
      position: absolute;
      right: 26px;
      bottom: 20px;

      border-radius: 40px;
      background-color: $primary500;
      width: fit-content;
      padding: 0 10px;
      color: $white;
      height: 28px;
      line-height: 28px;
      font-weight: 700;
      font-size: 12px;
      z-index: 10;

      .mark {
        display: inline-block;
        margin-right: 3px;
      }
    }

    .iconBack {
      position: absolute;
      top: 13px;
      left: 13px;
      z-index: 10;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    }

  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .titleAndBlueTick {
    display: flex;
    margin-top: 10px;
  }

  .title {
    font-weight: 700;
    font-size: 29px;
    line-height: 130%;
    color: $black700;

    @include sm {
      font-size: 22px;
    }
  }

  .blueTick {
    width: 24px;
    height: 24px;
    margin-top: 3px;
    margin-left: 10px;
  }

  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    margin-right: 10px;
  }

  .userArea {
    margin-top: 30px;
    width: fit-content;
    cursor: pointer;

    .blueTick {
      position: absolute;
      width: 15px;
      height: 15px;
      right: -4px;
      bottom: 0;
    }
  }


  .infoContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin: 15px 0;

    .info {
      display: flex;
    }

    .title {
      white-space: nowrap;
      margin: 0;
      margin-right: 15px;

      @include font-text;
      font-weight: 500;
      color: $gray700;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      @include font-text;
      font-weight: 500;
      color: $black700;
    }
  
    .flexWrap {
      flex-wrap: wrap;
    }
  }

  .subInfo {
    max-width: 100%;
    align-items: center;

    .content {
      width: auto;
      min-width: 50px;
      word-break: break-all;
    }
  }

  .metaContainer {
    border-top: 1px solid #C9CAD2;
    margin-top: 50px;

    .map {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-image: url('./../../../assets/map.png');
      width: 100%;
      height: 140px;
      border-radius: 16px;
      cursor: pointer;

    }

    .address {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 12px;
      background-color: #171B2E;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      border-radius: 40px;

      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: #FFFFFF;

      .addressIcon {
        width: 18px;
        height: 18px;
        margin-right: 4px;
      }
    }
  }

  .interestContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px dashed #414EE1;
    margin-top: 50px;


    .interestBox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 25px 0px;
    }

    .interest {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;

      color: #696B74;
    }

    .seeAll {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: $primary500;
      cursor: pointer;
      text-align: right;
      width: fit-content;
    }

  }

  .sloganTitle {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: $black500;
    margin-top: 20px;

    @include sm {
      font-size: 12px;
    }
  }

  .clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .more {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: $primary500;
    text-decoration: underline;
    float: right;
    cursor: pointer;
  }

  .x {
    color: $secondary;
    font-weight: 700;
    font-size: 12px;
    width: 20px;
    display: inline-block;
    text-align: center;
  }

  .y {
    font-weight: 700;
    font-size: 12px;
    width: 20px;
    display: inline-block;
    text-align: center;
  }


  .link {
    @include font-text;
    text-decoration: underline;
    color: $primary500;
    font-weight: 700;
  }

  // .ctaButtonBlock {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 100%;
  //   height: 80px;
  //   background-color: $white;
  //   position: fixed;
  //   bottom: 0px;
  // }


  .ctaButton {
    padding: 0 30px;
    z-index: 100;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);

    .marginRight3 {
      display: inline-block;
      margin-right: 3px;
    }
  }

  .topBackground {
    background-color: $black700;
    width: 100%;
    height: 200px;
    position: absolute;
    left: 0;
    z-index: -1;

    @include sm {
      height: 0px;
    }
  }
    
  .imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background-color: $gray50;
  }

  .hashTag {
    width: fit-content;
    cursor: pointer;
  }

  .defaultImg {
    height: 100%;
  }
}