@import '../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.headerContainer {
  width: 100%;
  height: map-get($headerH, 'global');
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(13.5914px);
  z-index: 900;
  position: sticky;
  top: 0;
  transition: .3s;

  @include sm {
    height: map-get($headerH, 'sm');
  }

  .header {
    width: 1100px;
    display: flex;
    align-items: center;

    .logo {
      // margin: 0 20px;
      margin: 0;
      height: 25px;
      margin-bottom: -5px;
    }

    @include lg {
      width: 100%;
      margin: 0 50px;
    }

    @include sm {
      margin: 0 20px;
    }
  }

  .icon1 {
    filter: invert(1);
  }

  .icon2 {
    opacity: .6;
  }

  .icon3 {
    filter: invert(1);
  }

  .login {
    margin-right: 12px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    border: 1px solid #D0D0D0;
    border-radius: 30px;
    padding: 3px 8px;
  }

  .white {
    color: $white;
  }

  .gray {
    color: $gray700;
  }
}

.headerContainer.hideHeader {
  top: -50px;
}

.headerContainer1 {
  background-color: $black700;
}

.headerContainer2 {
  background-color: $white;
}

.headerContainer3 {
  background-color: $primary500;
}

.headerContainer4 {
  background-color: $red500;
}


.menu {
  margin-bottom: 30px;

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: $black500;
  }

  .menuItem {
    cursor: pointer;
    margin: 10px 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    color: $gray700;
    transition: 0.5s;

    &:hover {
      background-color: $primary50;
      color: $black700;

      .icon {
        filter: none;
      }
    }
  }

  .icon {
    margin-right: 10px;
    filter: grayscale(1);
    opacity: 0.7;
  }
}

.link {
  color: $primary100;
  display: block;
  margin-left: 20px;
}

.avatarMenu {
  height: fit-content;
  max-height: 80vh;
  border-radius: 0px 0px 0px 24px;
  top: map-get($headerH, 'global');
  overscroll-behavior: none;

  @include sm {
    top: map-get($headerH, 'sm');
  }
}

.root {
  z-index: 850;
}

.headerTab {
  margin: 20px 0 5px;
}

.tab {
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: $black700;
}

.badge {
  font-size: 12px;
  background-color: $black500;
  color: $white;
  transform: scale(0.7);
  padding: 2px;
  border-radius: 30px;
  display: inline-block;
}

.rootMobile {
  z-index: 1000;
}

.mainMenuMobile {

  .logo {
    margin: 0 20px 20px;
    width: 65px;
  }
  .menu {
    flex-grow: 1;
  }

  .menuItem {
    border-radius: 8px;
  }

  .footer {
    margin-bottom: 10px;
  }
}

.avatarMenuMobile {

  .top {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    margin-bottom: 20px;

    .avatarContainer {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      cursor: pointer;
    }
  
    .avatar {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: $white;
    }

    .black700 {
      color: $black700;
    }

    .hey {
      font-weight: 500;
      font-size: 14px;
      line-height: 160%;
    }

    .name {
      font-weight: 700;
      font-size: 20px;
      line-height: 110%;
    }

    .closeIcon {
      justify-self: flex-end;
    }
  }

  .menu {
    flex-grow: 1;
  }
  .menuItem {
    border-radius: 8px;
  }

  .logout {
    font-family: 'Noto Sans TC';
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: $gray700;
  }

  .count {
    margin-left: 5px;
    width: 18px;
    height: 18px;
    background: $secondary;
    border-radius: 20px;
    font-weight: 700;
    font-size: 12px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.notificationContainer {
  padding-left: 20px;
  padding-right: 5px;
  margin-bottom: 30px;
  position: relative;
  max-height: 80vh;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.notificationWrapper {
  margin-top: 30px;
}

.flexRow {
  display: flex;
}

.notification {
  height: 100vh;
}

.headerContainer.hide {
  top: -(map-get($headerH, 'global'));

  @include sm {
    top: -(map-get($headerH, 'sm'));
  }
}


.drawerMask {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 800;
  top: 0;
}

.withGreenMark {
  position: relative;
  height: auto;

  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    position: absolute;
    bottom: 0px;
    right: -2px;
    background-color: $secondary;
  }
}