@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@mixin flexCenter {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productPage {
  @include page;
  position: relative;

  .mainContainer {
    @include mainCenterContainer2;
    align-items: center;
    flex-grow: 1;

    @include tn {
      align-items: flex-start;
      margin-top: 15px;
    }

    margin-bottom: 20px;
  }
}

.topArea {
  background-color: $primary500;
  height: 120px;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: center;
  gap: 30px;

  .titles {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-family: Noto Sans TC;
      font-size: 18px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
      color: $white;
    }
  }

  .painter {
    width: 110px;
    margin-bottom: -6px;
    // margin-right: 10px;

    @include sm {
      width: 88px;
      // margin-top: -15px;
    }
  }
}

.list {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  row-gap: 30px;
  align-items: center;
  justify-items: center;

  @include md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include xs {
    grid-template-columns: 1fr;
  }
}

.emptyContainer {
  flex-direction: column;
  @include flexCenter;
  gap: 10px;
  padding-bottom: 20px;

  .illustration {
    width: 200px;
  }

  .msg {
    //styleName: Noto Sans TC/24px/Bold;
    font-family: Noto Sans TC;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    color: $black700;
  }
}

.row {
  display: flex;
  align-items: center;
}

.flexCenter {
  @include flexCenter;
}

.marginTop20 {
  margin-top: 20px;
}

.spaceBtw {
  justify-content: space-between;
}

.button {
  width: 180px;
}

.promoText {
  font-family: Noto Sans TC;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: $black900;
  margin-bottom: 15px;
  padding: 0 20px;
}