@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.barContainer {
    box-shadow: 0px -2px 4px 0px #00000014;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $white;
    z-index: 100;
    height: 90px;
    display: flex;
    align-items: center;
    overflow: hidden;

    @include sm {
        height: 80px;
    }

    .content {
        @include mainCenterContainer2;
        width: 1110px;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        @media(max-width: 1280px) {
            width: 90%;
        }

        @include md {
            width: 100%;
            padding: 0 10px;
        }

        @include sm {
            justify-content: center;
        }
    }

    .buttons {
        display: flex;
        gap: 20px;

        @include tn {
            gap: 10px;
        }
    }

    .button {
        width: 150px;
        height: 50px;
    }

    .button.disabled {
        background-color: #D9D9D9;
        color: $white;
    }

    .submitButton {
        border-color: $primary500;
    }

    .leftArea {
        display: flex;
        align-items: center;

        margin-left: 175px;

        @include md {
            margin-left: 0px;
        }

        @include sm {
            display: none;
        }

        .avatar {
            margin-right: 2px;
        }

        .sloganContainer {
            //styleName: Noto Sans TC/20px/Bold;
            font-family: Noto Sans TC;
            font-size: 20px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $primary500;
            position: relative;
            display: flex;
            align-items: flex-start;

            .quotationMark {
                width: 24px;
                margin-right: 5px;
            }
        }
    }
}