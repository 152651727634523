@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

.tipWrapper {
    position: relative;
    transition: .8s;

    @include sm {
        width: 100%;
        margin-top: 30px;
    }
}

.tipWrapper.folded {
    @include sm {
        margin-top: 0px;
    }
}

.tipWrapper.isHidden {
    @include sm {
        margin-top: 0px;
    }
}

.tipContainer {
    width: 320px;
    padding-bottom: 0px;
    border-radius: 20px;
    gap: 10px;
    background-color: $gray550;
    border: 10px solid $gray550;
    position: relative;
    overflow: hidden;

    @include sm {
        width: 100%;
    }
}

.contentContainer {
    padding: 10px;
    padding-top: 36px;
    transition: .8s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 1500px;

    .title {
        //styleName: Noto Sans TC/18px/Bold;
        font-family: Noto Sans TC;
        font-size: 18px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: $black900;

        @include sm {
            font-size: 16px;
        }
    }

    .text {
        //styleName: Noto Sans TC/14px/Regular;
        font-family: Noto Sans TC;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: $black;
    }

    .sectionTitle {
        //styleName: Noto Sans TC/14px/Bold;
        font-family: Noto Sans TC;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: $black900;
    }

    .bulletPoint {
        display: flex;
        gap: 5px;
        margin: 5px 0;
    }

    .example {
        margin: 2px 0;
    }
}

.isHidden {
    visibility: hidden;
    border: none;
}

.rotate {
    transform: rotate(180deg) translateY(5px);
}

.bottomBar {
    background-image: linear-gradient(180deg, rgba(245,245,245,0) 0%, rgba(245,245,245,0.7161458333333333) 30%, rgba(245,245,245,1) 85%);
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 50px;
    padding-bottom: 5px;
}


.titleWrapper {
    position: relative;
    display: flex;
    gap: 10px;
    overflow: visible;

    .title {
        margin-bottom: 10px;
    }
}

.contentContainer.folded {
    max-height: 95px;
    padding-top: 10px;
}

.outerflower {
    position: absolute;
    width: 56px;
    right: 20px;
    top: -30px;
    transition: .8s;
}

.outerflower.folded {
    width: 50px;
    top: 32px;
}

.flowerPlaceholder {
    width: 60px;
    flex-shrink: 0;
}