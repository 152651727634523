@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';

.card {
  display: flex;
  gap: 10px;
  background: $black700;
  box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
  border-radius: 16px;
  padding: 20px;
  max-height: 91px;
  width: 100%;
}

.banner {
  flex-shrink: 0;

  width: 50px;
  height: 50px;
  background-color: $white;
  border-radius: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  font-family: Noto Sans TC;
  letter-spacing: 0em;
  text-align: left;
  color: $white;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  font-weight: 700;
  flex-grow: 1;
  flex-shrink: 1;
  word-break: break-all;
  line-height: 25.6px;
}

.blueTick {
  width: 24px;
  height: 24px;
  margin-top: 3px;
  margin-left: 10px;
}

.clickable {
  cursor: pointer;
}