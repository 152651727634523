@import '../../utils/constants.scss';
@import 'src/utils/mediaQuery.scss';


.customPicker {
  position: relative;

  .ant-picker {
    height: 66px;
    border: 1px solid #c4c4c4;
  }

  .ant-picker-status-error.ant-picker {
    border-color: $red500;
  }
  
  .ant-picker-input>input {
    font-size: 16px !important;
  }

  .pickerLabel {
    font-size: 16px;
    position: absolute;

    color: rgba(0, 0, 0, 0.6);
    background-color: #fff;
    padding: 0 7px;
    top: -7px;
    left: -1px;
    z-index: 3;
    transform: scale(0.75);
  }

  .pickerLabel.custom-picker-required {
    transform: scale(0.75) translateX(-6px);
  }

  .labelPurple {
    color: $primary500;
  }
}

.customPicker.isEmpty.isRequired {
  .ant-picker-input {
    &::before {
      content: '*';
      display: inline-block;
      color: $red500;
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
      margin-right: 2px;
    }
  }
}

.customPicker.error {
  .ant-picker {
    border-color: $red500;
  }
}

.datePicker_errorMessage {
  color: $red500;
  font-size: 12px;
  margin-top: 2px 4px 4px;
  position: absolute;
}