@import 'src/components/OrderCard/styles.module.scss';

.days {
    display: flex;
    gap: 3px;
    color: $primary500;
}

.tradeRemark {
    margin-bottom: 10px;
}