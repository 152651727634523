@import 'src/utils/constants.scss';
@import 'src/utils/mediaQuery.scss';
@import 'src/utils/common.scss';

@mixin marginLeftBefore1100 {
  margin-left: calc((100% - 1100px) / 2);

  @media(max-width: 1100px) {
    margin-left: 0;
  }
}

.topicsPage {
  @include page;
  position: relative;
  font-family: Noto Sans TC;
  background-color: $gray100;

  @include sm {
    background-color: $white;
  }

  .contentWrapper {
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    max-width: 1100px;

    @include sm {
      max-width: none;
      min-height: calc(100% - map-get($headerH, 'sm'));
    }
  }
}

.topBar {
  width: 100%;
  background-color: $primary500;
  border-radius: 0px 0px 0px 24px;
  max-height: 330px;

  @include sm {
    max-height: none;
  }

  .contentWrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
    position: relative;

    @include sm {
      flex-direction: column;
    }
  }

  .illusWrapper {
    display: flex;
    gap: 0px;
    margin: 0 auto;
    align-items: flex-end;

    @include sm {
      margin-top: 40px;
    }
  }

  .illus {
    width: 134px;
    margin-bottom: -50px;

    @include sm {
      width: 100px;
      margin-bottom: 0px;
    }

    @include tn {
      width: 35%;
    }
  }

  .illusSlogan {
    max-width: 345px;

    @include md {
      max-width: 290px;
    }

    @include sm {
      margin-bottom: 20px;
      max-width: 241px;
    }

    @include tn {
      max-width: 65%;
    }
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .texts {
    margin-top: auto;
    margin-bottom: 30px;

    @include md {
      margin-bottom: 12px;
    }

    @include sm {
      margin-bottom: 20px;
    }
  }

  .titles {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .title {
      color: $white;
      font-size: 28px;
      font-weight: 700;
      line-height: 120%; /* 33.6px */
    }

    @include sm {
      flex-direction: column;
      gap: 5px;
      justify-content: center;
      text-align: center;

      .title {
        font-size: 24px;
      }
    }
  }

  .desc {
    color: $white;
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 25.6px */
  }

  .sponsorLogosContainer {
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .sponsorLogo {
    max-width: 88px;
  }

}

.intro {
  width: 100%;
  margin-top: 30px;

  @include sm {
    margin-top: 20px;
  }

  .contentWrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    // overflow: hidden;

    @include sm {
      flex-direction: column;
      padding-right: 20px;
      gap: 30px;
    }
  }
  

  .leftCol {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 445px;
    padding: 0 20px;
    flex-shrink: 0;

    @include lg {
      width: 400px;
    }

    @include sm {
      width: 100%;
      padding: 0px;
    }
  }

  .stats {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @include tn {
      gap: 12px;
    }

    .statBlock {
      flex-basis: 50px;
      flex-grow: 1;

      .titleLine {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: $primary500;
        font-size: 28px;
        font-weight: 700;
        line-height: 160%; /* 38.4px */

        @include sm {
          font-size: 24px;
        }
      }

      .subtitle {
        margin-top: 5px;
        text-align: center;
        color: $gray700;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
      }
    }
  }

  .desc {
    color: $black900;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%; /* 25.6px */

    @include sm {
      text-align: center;
    }
  }

  .highlightBlock {
    position: relative;
    background-color: $black700;
    border-radius: 16px;
    padding: 20px 24px;
    color: $white;
    font-size: 18px;
    font-weight: 700;
    line-height: 160%; /* 28.8px */
    cursor: pointer;

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      line-height: 160%; /* 25.6px */
    }
  }

  .illusContainer {
    width: 570px;
    flex-shrink: 0;
    align-self: flex-start;
    padding-right: 20px;

    @include sm {
      width: 570px;
    }

    @include xxs {
      width: 414px;
    }

    img {
      width: 100%;
    }
  }

  .introIcon {
    width: 28px;
  }
}

.specialThanks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 820px;
  margin: 70px auto 0;
  padding: 0 20px;

  .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    mix-blend-mode: multiply;
    gap: 30px;
    flex-wrap: wrap;

    img {
      max-height: 100px;
    }
  }

  .logos.columns {
    flex-direction: column;
    gap: 20px;

    img {
      max-height: 100px;
    }
  }

  .specialThanksItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .hiddenLink {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .desc {
    font-family: 'Noto Sans TC', sans-serif;
    color: #696B74;
    font-size: 16px;
    margin-top: 15px;
    span {
      line-height: 1.4;
    }
  }
}

.logosSection {
  width: 100%;
  max-width: 820px;
  margin: 55px auto 0px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  .logos {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    mix-blend-mode: multiply;
  
    .logoLink {
      display: flex;
      align-items: center;
    }
  }
}

.separator {
  display: block;
  width: 1px;
  height: 36px;
  background-color: $gray500;
}

.more {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: $primary500;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
}

.link {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: $primary500;
  text-decoration: underline;
  cursor: pointer;
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text {
  color: $gray700;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}

.sectionTitle {
  color: $black700;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 130%; /* 23.4px */
}

.footerWrapper {
  background-color: $white;
  margin-top: 60px;

  @include sm {
    margin-top: 40px;
  }
}

.hide {
  display: none;
}

.overflowHidden {
  overflow: hidden;
}

.textShadow {
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.38);
}

.passwordView {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .passwordContainer {
    width: 395px;
    border-radius: 16px;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0px 4px 50px rgba(182, 197, 205, 0.3);
    margin-bottom: 60px;

    @include xs {
      width: 90%;
    }
  }

  .title {
    font-family: 'Poppins', sans-serif;
    color: #0C0E17;
    font-weight: 700;
    font-size: 28px;
  }

  .desc {
    font-family: 'Noto Sans TC', sans-serif;
    font-size: 16px;
    color: #171B2E;
    line-height: 160%;
  }

  .errorMessage {
    padding-top: 2px;
    color: $red500;
    font-size: 12px;
  }
}

.clickable {
  cursor: pointer;
}